import { Button, Row } from 'antd'
import styled, { css } from 'styled-components'

export const LeftPanelActions = styled.div`
  position: absolute;  
  display: ${props => (props?.showToolbar ? 'unset' : 'none')};
  top: 20px;
  .ant-btn {
    margin-right: 8px;
    cursor: pointer;
    background: #fff !important;
    border: none; 
  }
  .ant-btn-icon-only{  
    padding: 0;
  }
  .ant-btn-loading::before {
    position: absolute;
    top: 0px;
  }
`

export const TreeNodeTitle = styled.span`
  ${props => props.hide && css`
    color: rgb(151, 160, 175);
  `}
`
export const TreeDataFolder = styled.div`
  div.treeData-panel div.ant-tree-treenode.empty-file {
    display: none;
    position: fixed;
    z-index: -1;
    top: -10%;
  }
 
  .drag-over .ant-tree-node-content-wrapper, .drag-over.ant-tree-treenode-switcher-close, .drag-over.ant-tree-treenode-switcher-open {
    color:#fff;
    background-color:#f26524;
    opacity:.8
  }
`
export const CustomDrawerWraper = styled.div`
 max-width : 300px;
 width : 100%;
 margin : auto;
.slick-slide {
  padding: 0 5px !important;
}
.slick-prev, .slick-next {
  top: 32%;
}
.slick-next:before, .slick-prev:before {
    color: #000;
}
`
export const ViewPointImageContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const ViewPointImage = styled.img`
  margin : 0 auto;
  object-fit: cover;  
  width: 100%;
  height: 100%;
  position: absolute;
`
export const CardViewWrapper = styled.div`
  padding : 0 5px;
  .ant-card {
    background-color: transparent;
    border: none;
    .ant-card-body {
      padding: 10px;
    }
    .ant-card-cover {
      position: relative;
      .anticon {
        position: absolute;
        top: 10px;
        right: 10px;
        width: auto;
        color: white;
        font-size: 16px;
        opacity: 0;
        transition: ease .3s;
      }
      &:hover {
        cursor: pointer;
        .anticon {
          transition: ease .3s;
          opacity: 1;
        }
      }
    }
    .ant-card-meta-title {
      color: white;
      font-size: 12px;
      text-align: center;
    }
  }
`
export const TreeDataWrapper = styled.div`
    width: ${props => (props?.width ? props?.width : '500px')};
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 380px;
  div.treeData-panel div.ant-tree-treenode.empty-file {
    display: none;
    position: fixed;
    z-index: -1;
    top: -10%;
  }
 
  .drag-over .ant-tree-node-content-wrapper, .drag-over.ant-tree-treenode-switcher-close, .drag-over.ant-tree-treenode-switcher-open {
    color:#fff;
    background-color:#f26524;
    opacity:.8
  }
`
export const DrawmoreButton = styled.span`
margin-bottom: 10px;
float: right;
.ant-switch{
  width : 70px;
  height : 30px;
}
.ant-switch-handle{
  top : 5px;
}
`
export const LocationPanelHeader = styled.div`
  font-size: 16px;
  font-weight: bold;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`

export const DrawerTilesetWrapper = styled.div`
    .ant-drawer-body {
      overflow: hidden;
    }
    .splitpanel-left {
        top: 0;
        right: 0;
        width: 10px;
        bottom: 0;
        cursor: ew-resize;
        padding: 4px 0 0;
        z-index: 100;
        position: absolute;
        border-top: 1px solid #ddd;
        background-color: #f2cfc4;
    }
  
    .splitpanel-mobile-left {
        top: 0;
        right: 0; 
        bottom: 0;
        cursor: ew-resize;
        padding: 4px 0 0;
        z-index: 100;
        position: absolute;
        .btnSplitPanel {
        position: absolute;
        top: 50%;
        margin-left: -15px;
        padding: 0 !important;
        }
    }
`

export const AccessControlPanelActions = styled(Row)`
  .ant-btn {
    margin-right: 8px;
    cursor: pointer;
    background: #fff !important;
    border: none; 
  }
  .ant-btn-icon-only{  
    padding: 0;
  }
`

export const AccessControlWrapper = styled.div`
 .group-wrapper{
    padding : 5px 0;
  }
  .ant-form-item{
    margin-bottom : 0;
  }
  .break-word{
    word-break: break-word;
  }
`

export const SketchPanelWrapper = styled.div`
  .splitpanel-left {
    top: 0;
    right: 0;
    width: 10px;
    bottom: 0;
    cursor: ew-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
    border-top: 1px solid #ddd;
    background-color: #f2cfc4;
  }

  .splitpanel-mobile-left {
    top: 0;
    right: 0;
    bottom: 0;
    cursor: ew-resize;
    padding: 4px 0 0;
    z-index: 100;
    position: absolute;
    .btnSplitPanel {
      position: absolute;
      top: 50%;
      margin-left: -15px;
      padding: 0 !important;
    }
  }
`