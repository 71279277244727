import { Cesium3DTileStyle, Color, PolylineDashMaterialProperty } from 'cesium'
import moment from 'moment'
import TreeUtils from '@/tree-utils'
import projectStore from '@/stores/projectStore'
import projectGanttStore from '@/stores/projectGanttStore'

const gantt = window.gantt

export const DATE_ZOOMINGS = [
  'Minutes',
  'Hours',
  'Days',
  'Weeks',
  'Months',
  'Quarters',
  'Years',
]

export const ACTUAL_DATE_ZOOMINGS = {
  Minutes: 'minute',
  Hours: 'hour',
  Days: 'day',
  Weeks: 'week',
  Months: 'month',
  Quarters: 'month', //'quarter',
  Years: 'year',
}

/**
 * Returns the week number for this date.  dowOffset is the day of week the week
 * "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday),
 * the week returned is the ISO 8601 week number.
 * @param int dowOffset
 * @return int
 */
Date.prototype.getWeek = function (dowOffset) {
  /*getWeek() was developed by Nick Baicoianu at MeanFreePath: http://www.meanfreepath.com */

  dowOffset = typeof dowOffset == 'number' ? dowOffset : 0 //default dowOffset to zero
  var newYear = new Date(this.getFullYear(), 0, 1)
  var day = newYear.getDay() - dowOffset //the day of week the year begins on
  day = day >= 0 ? day : day + 7
  var daynum =
    Math.floor(
      (this.getTime() -
        newYear.getTime() -
        (this.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) /
        86400000
    ) + 1
  var weeknum
  //if the year starts before the middle of a week
  if (day < 4) {
    weeknum = Math.floor((daynum + day - 1) / 7) + 1
    if (weeknum > 52) {
      let nYear = new Date(this.getFullYear() + 1, 0, 1)
      let nday = nYear.getDay() - dowOffset
      nday = nday >= 0 ? nday : nday + 7
      /*if the next year starts before the middle of
                the week, it is week #1 of that year*/
      weeknum = nday < 4 ? 1 : 53
    }
  } else {
    weeknum = Math.floor((daynum + day - 1) / 7)
  }
  return weeknum
}

export class DateShow {
  constructor() {
    const currentDate = new Date()
    this.markerDate = currentDate
    this.id = currentDate.toString()
  }

  resetToCurrentDate() {
    this.markerDate = new Date()
  }

  setDateShow(date) {
    if (!(date instanceof Date)) return false
    this.markerDate = date
  }

  updateDateShow = (value, filter) => {
    switch (filter) {
      case 'Minutes':
        this.markerDate = gantt.date.add(this.markerDate, value, 'minute')
        break
      case 'Hours':
        this.markerDate = gantt.date.add(this.markerDate, value, 'minute')
        break
      case 'Days':
        this.markerDate = gantt.date.add(this.markerDate, value, 'hour')
        break
      case 'Weeks':
        this.markerDate = gantt.date.add(this.markerDate, value, 'day')
        break
      case 'Months':
        this.markerDate = gantt.date.add(this.markerDate, value, 'week')
        break
      case 'Quarters':
        this.markerDate = gantt.date.add(this.markerDate, value, 'month')
        break
      case 'Years':
        this.markerDate = gantt.date.add_quarter(this.markerDate, value)
        break
      default:
        this.markerDate = gantt.date.add(this.markerDate, value, 'day')
        break
    }
  }

  getNowTime() {
    return this.markerDate
  }

  getPreviousTime = (inputDate, filter) => {
    if (!(inputDate instanceof Date)) {
      throw new Error('Invalid input. Please provide a valid Date object.')
    }
    const resultDate = new Date(inputDate)
    switch (filter) {
      case 'Minutes':
        return gantt.date.add(resultDate, -1, 'minute')
      case 'Hours':
        return gantt.date.add(resultDate, -1, 'minute')
      case 'Days':
        return gantt.date.add(resultDate, -1, 'hour')
      case 'Months':
        return gantt.date.add(resultDate, -1, 'week')
      case 'Quarters':
        return gantt.date.add(resultDate, -1, 'month')
      case 'Years':
        return gantt.date.add_quarter(resultDate, -1)
      default:
        return gantt.date.add(resultDate, -1, 'day')
    }
  }

  getNextTime = (inputDate, filter) => {
    if (!(inputDate instanceof Date)) {
      throw new Error('Invalid input. Please provide a valid Date object.')
    }

    const resultDate = new Date(inputDate)
    switch (filter) {
      case 'Minutes':
        return gantt.date.add(resultDate, 1, 'minute')
      case 'Hours':
        return gantt.date.add(resultDate, 1, 'minute')
      case 'Days':
        return gantt.date.add(resultDate, 1, 'hour')
      case 'Months':
        return gantt.date.add(resultDate, 1, 'week')
      case 'Quarters':
        return gantt.date.add(resultDate, 1, 'month')
      case 'Years':
        return gantt.date.add_quarter(resultDate, 1)
      default:
        return gantt.date.add(resultDate, 1, 'day')
    }
  }
}

export const handleAddDateTime = (originDate, value, filter) => {
  switch (filter) {
    case 'Minutes':
      return gantt.date.add(originDate, value, 'minute')
    case 'Hours':
      return gantt.date.add(originDate, value, 'minute')
    case 'Days':
      return gantt.date.add(originDate, value, 'hour')
    case 'Weeks':
      return gantt.date.add(originDate, value, 'day')    
    case 'Months':
      return gantt.date.add(originDate, value, 'week')
    case 'Quarters':
      return gantt.date.add(originDate, value, 'month')
    case 'Years':
      return gantt.date.add_quarter(originDate, value)
    default:
      return gantt.date.add(originDate, value, 'day')
  }
}

export function removeDuplicateText(input) {
  if (input === 'Weeks') return input;
  // Use Unicode regex to match duplicate text
  return input.replace(/(\p{L}+)\1/gu, '$1');
}

let gantt3DObjtects = []
let listAlignmentfeature = []

const findTile = (tileId, tileViews) => {
  let fkey = tileId + '-tile'
  let tile = tileViews.find(t => t.key == fkey)
  if (!tile) return false
  if (!tile.ref) return false
  if (!tile.ref.current) return false
  return tile.ref
}

const setColorEntitiesValue = (viewer, features, isReset) => {
  if (!isReset) {
    features.map(feature => {
      const tileView = feature?.entities?.values
      const color = feature?.color
      if (tileView) {
        tileView.map(feature => {
          if (feature?.polygon?.material) {
            feature.polygon.material = color
          } else if (feature?.polyline?.material) {
            feature.polyline.material = color
          } else if (feature?.point?.color) {
            feature.point.color = color
          } else if (feature?.billboard?.color) {
            feature.billboard.color = color
          }
        })
      }
    })
  } else {
    features.map(feature => {
      const tileView = feature?.entities?.values
      if (tileView && feature?.isColorEntitiesValue) {
        tileView.map(feature => {
          if (feature?.polygon?.material) {
            feature.polygon.material = Color.clone(Color.WHITE, feature.color)
          } else if (feature?.polyline?.material) {
            feature.polyline.material = Color.clone(Color.WHITE, feature.color)
          } else if (feature?.point?.color) {
            feature.point.color = Color.clone(Color.WHITE, feature.color)
          } else if (feature?.billboard?.color) {
            feature.billboard.color = Color.clone(Color.WHITE, feature.color)
          }
        })
      }
    })
  }
  if (viewer.scene.requestRenderMode) {
    viewer.scene.requestRender()
  }
}

const saveOriginalAlignmentColor = (_alignment) =>{
  if (_alignment) {
    const originalColor = _alignment.polyline.material
      .getValue()
      .color.toCssHexString()
    if (!_alignment.originalColor) {
      _alignment.originalColor = originalColor
    }
    listAlignmentfeature.push(_alignment)
  }
}

const getColorAlignment = (feature, style, isReset) => {
  let color = null
  if (!isReset) {
    const { color: colorQuery, alpha } = style
    color = Color.fromCssColorString(colorQuery || '#FFFFFF').withAlpha(alpha)
  } else {
    color = Color.fromCssColorString(feature?.originalColor || '#ffffff')
  }
  const AlignmentHorCurve = new PolylineDashMaterialProperty({
    color: Color.clone(color, feature.color),
  })
  return AlignmentHorCurve
}

const unHighlightXMLAlignment = features => {
  if (!features || !features.length) {
    return
  }
  features.map(feature => {
    if (feature.polyline) {
      feature.polyline.material = getColorAlignment(feature, false, true)
    }
  })
  listAlignmentfeature = []
}

const highlighXMLAlignment = (viewer, features, style) =>{
  if (!features || !features.length) {
    return
  }
  features.map(feature => {
    if (feature.polyline) {
      feature.polyline.material = getColorAlignment(feature, style, style?.color === '#ffffff')
    }
  })

  if (viewer.scene.requestRenderMode) {
    viewer.scene.requestRender()
  }
}

const highlightAlignment = (viewer, modelId, style) =>{
  if(!viewer || !modelId || !style) return

  const alignments = projectStore.alignmentFeatures.filter(
    feature => feature?.modelId === modelId
  )
  if(alignments?.length > 0){
    alignments.forEach(alm => saveOriginalAlignmentColor(alm))
    highlighXMLAlignment(viewer, listAlignmentfeature, style)
  }

}

const selectFeatures = (features) => {
  gantt3DObjtects = [...gantt3DObjtects, ...features]
}

export const applyColorChanges = (
  modelId,
  tileViews,
  color = '#ff0000',
  alpha = 0.5,
  viewer
) => {
  const model = findTile(modelId, tileViews);
  if (!model) return;

  Object.assign(model, {
    modelId,
    isColorEntitiesValue: false,
    sourceType: 'file'
  });

  const entities = model.current?.cesiumElement?.entities?.values;
  if (entities) {
    Object.assign(model, {
      isColorEntitiesValue: true,
      entities: { values: entities },
      color: new Color.fromCssColorString(color).withAlpha(color === '#ffffff' ? 1 : Number(alpha))
    });
    gantt3DObjtects.push(model);
    setColorEntitiesValue(viewer, [model]);
  } else {
    selectFeatures([model]);
  }

  highlightAlignment(viewer, modelId, { color, alpha: Number(alpha) });
}

const resetTilesetColor = (viewer, tilesets) => {
  if (tilesets?.length > 0) {
    tilesets.map(tileset => {
      if (tileset?.sourceType === 'file' && !tileset?.isColorEntitiesValue) {
        tileset.style = new Cesium3DTileStyle()
      }
    })
    if (viewer.scene.requestRenderMode) {
      viewer.scene.requestRender()
    }
  }
}

const unselectFeatures = (viewer, features) => {
  if (!features || !features.length) {
    return
  }
  resetTilesetColor(viewer, features)
  setColorEntitiesValue(viewer, features, true)
  if (features === gantt3DObjtects) {
    gantt3DObjtects = []
  }
}

export const onComponentDidMount = viewer => {
  unHighlightXMLAlignment(listAlignmentfeature)
  unselectFeatures(viewer, gantt3DObjtects)
  gantt3DObjtects = []
}

/**
 * Single datetime range
 * @param {*} currentTime
 * @param {*} startDate
 * @param {*} endDate
 * @returns 'before' | 'after' |'between' |'same'|'none'
 */
const checkDateInSingleRange = (currentTime, startDate, endDate) => {
  // Kiểm tra xem currentTime có nằm trước startDate hay không
  const isBeforeStartDate = currentTime.isBefore(startDate)

  // Kiểm tra xem currentTime có nằm sau endDate hay không
  const isAfterEndDate = currentTime.isAfter(endDate)

  // Kiểm tra xem currentTime có nằm trong khoảng giữa startDate và endDate hay không
  const isInRange = currentTime.isBetween(startDate, endDate)

  const isSameStartOrEnd =
    currentTime.isSame(startDate) ||
    currentTime.isSame(endDate) ||
    currentTime.toDate().toString() === startDate.toDate().toString() ||
    currentTime.toDate().toString() === endDate.toDate().toString()

  if (isBeforeStartDate) {
    return 'before'
  } else if (isSameStartOrEnd) {
    return 'same'
  } else if (isAfterEndDate) {
    return 'after'
  } else if (isInRange) {
    return 'between'
  } else {
    return 'none'
  }
}

const convertStringToDate = dateString => {
  //const dateString = 'Wed Dec 13 2023 00:00:00 GMT+0700 (Indochina Time)';
  return moment(dateString, 'ddd MMM DD YYYY HH:mm:ss ZZ')
}

/**
 * Two datetime range
 * @param {*} targetDate
 * @param {*} start1
 * @param {*} end1
 * @param {*} start2
 * @param {*} end2
 * @returns 'middle' | 'left' | 'right' | 'nothing'
 */
export function checkDateInRanges(targetDateRaw, start1, end1, start2, end2) {
  const targetDate = convertStringToDate(targetDateRaw.toDate().toString())
  const isInRange1 = targetDate.isBetween(start1, end1, null, '[]') // [] đại diện cho inclusive
  const isInRange2 = targetDate.isBetween(start2, end2, null, '[]')

  if (isInRange1 && isInRange2) {
    return 'middle'
  } else if (isInRange1) {
    return 'left'
  } else if (isInRange2) {
    return 'right'
  } else {
    return 'nothing'
  }
}

/**
 * Check and take the priority task
 * @param {*} array
 * @param {*} currentTime
 * @returns Array
 */
const doubleCheckFunction = (array, currentTime) => {
  if (!array?.length) return [];

  const seen = new Map();
  return array.filter(item => {
    const duplicates = seen.get(item.id);
    if (!duplicates) {
      seen.set(item.id, [item]);
      return true;
    }

    duplicates.push(item);
    if (duplicates.length === 2) {
      const [task1, task2] = duplicates;
      const position = checkDateInRanges(
        currentTime,
        task1.momentStart,
        task1.momentEnd,
        task2.momentStart,
        task2.momentEnd
      );
      
      const excludeTask = position === 'right' ? task1 : task2;
      return item.taskId !== excludeTask.taskId || item.id !== excludeTask.id;
    }
    return true;
  });
}

const getUniqueData = (task, type) => {
  const dataMap = {
    ['dataTree']: task?.dataTree,
    ['savedQuery']: task?.savedQuery,
    ['objectGuid']: task?.objectGuid,
  };
  return [...new Set(dataMap[type] || [])];
};

const createTaskObject = (task, isHighlight, momentStart, momentEnd) => ({
  isHighlight,
  highlightColor: task.highlightColor ?? '#ffffff',
  highlightAlpha: Number(task.highlightAlpha ?? 0.5),
  momentStart,
  momentEnd,
  taskId: task.id
});

/**
 * Pre-processes task data for Gantt visualization
 * @param {Object} task - Task object containing dates and type
 * @param {Date} currentTime - Current reference time
 * @param {Array} [listKeyModelShow=[]] - Initial list of models to show
 * @param {Array} [listAllModelLinkedToGantt=[]] - Initial list of linked models
 * @param {string} [type='dataTree'] - Processing type
 * @returns {[Array, Array]} Tuple of [shownModels, allLinkedModels]
 */
export const preProcessingData = (
  task,
  currentTime,
  listKeyModelShow = [],
  listAllModelLinkedToGantt = [],
  type = 'dataTree'
) => {
  // Early return với input validation
  if (!task || typeof task !== 'object') {
    return [listKeyModelShow, listAllModelLinkedToGantt];
  }

  // Cache moment objects
  const momentStart = task.start_date ? moment(task.start_date) : null;
  const momentEnd = task.end_date ? moment(task.end_date) : null;

  // Lấy uniqueData và kiểm tra sớm
  const uniqueData = getUniqueData(task, type);
  if (!Array.isArray(uniqueData) || !uniqueData.length) {
    return [listKeyModelShow, listAllModelLinkedToGantt];
  }

  // Sử dụng Set để đảm bảo không trùng lặp
  const updatedAllModelLinked = new Set([...listAllModelLinkedToGantt, ...uniqueData]);

  // Hàm xử lý dữ liệu tái sử dụng
  const processData = (isHighlight) => {
    const mappedData = uniqueData.map(item => {
      const baseObj = createTaskObject(task, isHighlight, momentStart, momentEnd);
      return typeof item === 'string'
        ? { id: item, ...baseObj }
        : { id: item.model3d, ...item, ...baseObj };
    });
    return doubleCheckFunction([...listKeyModelShow, ...mappedData], currentTime);
  };

  // Xử lý logic chính
  if (momentStart || momentEnd) {
    const position = checkDateInSingleRange(currentTime, momentStart, momentEnd);
    const taskType = task.taskType;

    // Xử lý trường hợp đặc biệt cho objectGuid
    if (type === 'objectGuid' && ['before', 'after'].includes(position)) {
      projectGanttStore.setHighlightObjectGuidStyles([]);
      return [listKeyModelShow, Array.from(updatedAllModelLinked)];
    }

    const isBetween = position === 'between';
    const isHighlight = task.highlightEnable !== false && (
      (isBetween && ['demolish', 'new', 'temporary'].includes(taskType)) ||
      isBetween ||
      position === 'same'
    );
    const isShow = (position === 'before' && taskType === 'demolish') ||
      (position === 'after' && taskType === 'new') ||
      isHighlight ||
      (position === 'after' && !taskType);

    if (isShow) {
      return [processData(isHighlight), Array.from(updatedAllModelLinked)];
    }
  } else {
    // Trường hợp không có ngày
    return [processData(false), Array.from(updatedAllModelLinked)];
  }

  return [listKeyModelShow, Array.from(updatedAllModelLinked)];
};
export const convertModelToKey = (treeData, linkedModels) =>{
  let result = []
  if(!linkedModels?.length) return result

  linkedModels.forEach(lm =>{
    const model = TreeUtils.searchTreeNode(treeData, 'modelId', lm)
    const sketch = TreeUtils.searchTreeNode(treeData, 'sketchId', lm)
    if(model){
      result.push(model?.key)
      return
    }
    if(sketch){
      result.push(sketch?.key)
      return
    }
  })

  return result
}

export const convertKeyToModel = (treeData, keys) =>{
  let result = []
  if(!treeData?.length || !keys?.length) return result
  
  keys.forEach(lm =>{
    const model = TreeUtils.searchTreeNode(treeData, 'key', lm)
    if(model?.modelId){
      result.push(model.modelId)
      return
    }
    if(model?.sketchId){
      result.push(model.sketchId)
      return
    }
  })
  return result
}

export const convertKeyToFileid = (treeData, keys) => {
  const fileIds = [];

  if (!Array.isArray(treeData) || !treeData.length || !Array.isArray(keys) || !keys.length) {
    return fileIds;
  }

  keys.forEach(key => {
    const node = TreeUtils.searchTreeNode(treeData, 'key', key);
    if (node?.hash) {
      const fileId = node.hash.split('/')[1]; // Lấy phần thứ hai của hash (fileid)
      if (fileId) {
        fileIds.push(fileId);
      }
    }
  });

  return fileIds;
}

export const COLUMNS = [
  'wbs',
  'text',
  'start_date',
  'end_date',
  'duration',
  'priority',
  'taskType',
  'owner',
  'progress',
  'buttons',  
]

const allowedTypes = {
  ms_project: [
    'mpp',
    'xml',
    'text/xml',
    'application/xml',
    'application/vnd.ms-project',
    'application/msproj',
    'application/msproject',
    'application/x-msproject',
    'application/x-ms-project',
    'application/x-dos_ms_project',
    'application/mpp',
    'zz-application/zz-winassoc-mpp',
  ],
  primavera: ['xer', 'xml', 'text/xml', 'application/xml', 'application/xer'],
}

export const isFileTypeAllowed = (file, projectType) => {
  // Lấy phần mở rộng của tệp
  const extension = file.name.split('.').pop().toLowerCase()
  // Lấy kiểu MIME của tệp
  const mimeType = file.type.toLowerCase()

  // Kiểm tra xem phần mở rộng hoặc kiểu MIME có tồn tại trong danh sách cho phép không
  return (
    allowedTypes[projectType].includes(extension) ||
    allowedTypes[projectType].includes(mimeType)
  )
}

export const simplifiedGanttData = (ganttData) => {
  return ganttData?.data?.map(task => ({
    ...task,
    savedQuery: task?.savedQuery?.map(sq => sq.id) || []
  })) || [];
}

export const checkIsValidId = (inputString) => {
  // Biểu thức chính quy để kiểm tra
  const regexPattern = /^[0-9a-fA-F]{24}$/

  
  // Kiểm tra xem chuỗi nhập vào có khớp với biểu thức chính quy không
  const isMatch = regexPattern.test(inputString);
  
  // Trả về kết quả
  return isMatch;
}
