import { Empty, Pagination, Skeleton, Tag, Timeline } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TimelineWrapper } from './style'

const httpStatusCodes = {
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  409: 'Conflict',
  422: 'Unprocessable Entity',
  500: 'Internal Server Error',
}

const MethodTag = ({ method }) => {
  const methodColor = {
    GET: '#61affe',
    POST: '#49cc90',
    PUT: '#fca130',
    PATCH: '##50e3c2',
    DELETE: '#f93e3e',
    HEAD: '#9011fe',
    OPTIONS: '#0d5aa7',
  }

  return <Tag color={methodColor[method]}>{method}</Tag>
}

function convertLokiTimestamp(nanoTimestamp = '') {
  // Convert nanoseconds to milliseconds
  const milliTimestamp = Number(BigInt(nanoTimestamp) / 1_000_000n)

  // Convert to JavaScript Date
  const date = new Date(milliTimestamp)

  // Format for the client's timezone
  return date.toLocaleString() // Returns time in user's local timezone
}

const LogTimeline = ({ timelineData, loading }) => {
  const { t } = useTranslation()
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10) // Dynamically update pageSize

  useEffect(() => {
    setCurrentPage(1)
  }, [timelineData])

  function convertStringToObj(str = '') {
    try {
      return JSON.parse(str)
    } catch (error) {
      console.log('Error parsing string message to object', error)
      return
    }
  }

  const handlePageChange = (page, newPageSize) => {
    setCurrentPage(page)
    if (newPageSize !== pageSize) {
      setPageSize(newPageSize)
    }
  }

  const paginatedData = timelineData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  )

  return (
    <>
      {loading ? (
        <Skeleton avatar paragraph={{ rows: 6 }} active={loading} />
      ) : (
        <Timeline>
          {!timelineData?.length ? (
            <Empty description={t('no-data')} />
          ) : (
            <>
              <TimelineWrapper>
                <div className="datawrapper">
                  {paginatedData.map(({ values }, index) => {
                    if (!values?.length) return
                    const [_, OriginLogContent] = values?.[0]
                    const logContent = convertStringToObj(OriginLogContent)
                    if (!logContent) return
                    const { labels, message } = logContent
                    const status = labels?.status
                    const color = httpStatusCodes[status] ? 'red' : '#280dde'
                    return (
                      values?.length > 0 && (
                        <Timeline.Item
                          //label={convertLokiTimestamp(values?.[0])}
                          color={color}
                          key={index}>
                          {labels?.method && (
                            <MethodTag method={labels?.method} />
                          )}
                          {message || ''}
                        </Timeline.Item>
                      )
                    )
                  })}
                </div>
                <div className="pagination">
                  <Pagination
                    size="small"
                    showQuickJumper
                    current={currentPage}
                    total={timelineData?.length || 0}
                    pageSize={pageSize}
                    onChange={handlePageChange}
                  />
                </div>
              </TimelineWrapper>
            </>
          )}
        </Timeline>
      )}
    </>
  )
}

export default LogTimeline
