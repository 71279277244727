import axios from 'axios'
import commonStore from './stores/commonStore'
import { apiUrl } from './config'

const requests = {
  get: (url, header = false, urlstatic = true) => {
    if (header && commonStore.token) {
      return axios({
        method: `get`,
        url: `${apiUrl}${url}`,
        headers: {
          Authorization: `Bearer ${commonStore.token}`,
        },
      })
    }
    if (urlstatic) {
      return axios({
        method: `get`,
        url: `${apiUrl}${url}`,
      })
    }
    return axios({
      method: `get`,
      url: `${url}`,
    })
  },
  post: (url, body, header = false, onUploadProgress, cancelToken) => {
    if (header) {
      if (onUploadProgress) {
        return axios({
          method: `post`,
          url: `${apiUrl}${url}`,
          headers: {
            Authorization: `Bearer ${commonStore.token}`,
          },
          data: body,
          onUploadProgress
        })
      }
      if (cancelToken) {
        return axios({
          method: `post`,
          url: `${apiUrl}${url}`,
          headers: {
            Authorization: `Bearer ${commonStore.token}`,
          },
          data: body,
          cancelToken
        })
      }
      return axios({
        method: `post`,
        url: `${apiUrl}${url}`,
        headers: {
          Authorization: `Bearer ${commonStore.token}`,
        },
        data: body,
      })
    }
    return axios({
      method: `post`,
      url: `${apiUrl}${url}`,
      data: body,
    })
  },
  delete: (url, body = false) => {
    if (body) {
      return axios({
        method: `delete`,
        url: `${apiUrl}${url}`,
        headers: {
          Authorization: `Bearer ${commonStore.token}`,
        },
        data: body,
      })
    }
    return axios({
      method: `delete`,
      url: `${apiUrl}${url}`,
      headers: {
        Authorization: `Bearer ${commonStore.token}`,
      },
    })
  },
  put: (url, body, header = false, usingPut = false) => {
    if (header) {
      return axios({
        method: `put`,
        url: `${apiUrl}${url}`,
        headers: {
          Authorization: `Bearer ${commonStore.token}`,
        },
        data: body,
      })
    }
    return axios({
      method: usingPut ? 'put' : 'post',
      url: `${apiUrl}${url}`,
      data: body,
    })
  },
}

const AuthRequest = {
  login: (username, password) =>
    requests.post(`/xdLogin`, {
      identifier: username,
      password: password,
    }),
  exLogin: (username, token) =>
    requests.post(`/xdLogin`, {
      identifier: username,
      apiToken: token,
      isExternal: true
    }),
  register: info => requests.post(`/auth/local/register`, info),
  updateProjectInvitation: body => requests.post(`/projectuserroles/updateinvite`, body, true),
  changePasswordUser: body => requests.post(`/auth/custom-change-password`, body, true),
}

const FileRequest = {
  delete: (fileId) => {
    requests.delete(`/upload/files/${fileId}`, true)
  },
  upload: (body) => requests.post(`/upload`, body, true)
}

const UserRequest = {
  getAllUsers: () => requests.get(`/users`, true),
  getUserById: (id) => requests.get(`/users/${id}`, true),
  updateUser: (id, body) => requests.put(`/users/${id}`, body, true),
  getCurrent: () => requests.get(`/users/me`, true),
  updateProfileMe: (body) => requests.put(`/users/me`, body, true),
  delete: id => requests.delete(`/users/custom/${id}`, true),
  createUser: body => requests.post(`/users`, body, true),
  customFindUser: (type, body) => requests.post(`/users/custom/find-user?type=${type}`, body, true),
  getSystemUserRole: () => requests.get(`/users/custom/system-user-role`, true),
  paginate: (_limit, _page, search) => requests.get(`/user/custom/paginate?_limit=${_limit}&_page=${_page}${search && search !== '' ? '&search=' + search : ''}`, true),
  deleteMultiUser: (body) => requests.post(`/users/custom/deleteMultiUser`, body, true),
  getUserInfo: (userId) => requests.get(`/users/getUserInfo/${userId}`, true),
  updateUserInfo: (userId, body) => requests.put(`/users/updateUserInfo/${userId}`, body, true),
  sendEmailNotification: (body) => requests.post(`/sendEmailNotification`, body, true),
  sendEmailNotificationOver: (body) => requests.post(`/sendEmailNotificationOver`, body, true),
  sendGenericEmailNotification: (body) => requests.post(`/sendGenericEmailNotification`, body, true),
  resetAcceptPolicyTermConditions: (body) => requests.post(`/resetAcceptPolicyTermConditions`, body, true),
  verifyAccount: (id) => requests.get(`/users/verify/${id}`, false, true),
  confirmchangeemail: (token) => requests.get(`/confirmchangeemail/${token}`, true)
}

const ProjectLinkRequest = {
  getListModelProject: id => requests.get(`/getListModelProject/${id}`, true),
  deleteProjectLink: (id) => requests.delete(`/projectlinks/${id}`, true),
  updateProjectLink: (id, body) => requests.put(`/projectlinks/${id}`, body, true),
  getListProjectLink: id => requests.get(`/projectlinks?project=${id}`, true),
  createProjectLink: body => requests.post(`/projectlinks`, body, true)
}

const ProjectRequest = {
  downloadIonUploadToS3: (body) => requests.post(`/model3ds/downloadIonUploadToS3`, body, true),
  getTotalProjects: () => requests.get(`/projects`, true),
  getAllProjects: () => requests.get(`/projectuserroles`, true),
  getDetail: id => {
    return requests.get(`/projects/${id}`, true)
  },
  getProjectSetting: id => {
    return requests.get(`/getProjectSetting/${id}`, true)
  },
  uploadModel: (formData, onUploadProgress) => {
    return requests.post(`/upload/`, formData, true, onUploadProgress)
  },
  createModel: data => {
    return requests.post(`/model3ds`, data, true)
  },
  updateModel: (id, body) => requests.put(`/model3ds/${id}`, body, true),
  updateModelsMatrix: (body) => requests.post(`/model3ds/updateModelsMatrix/`, body, true),
  updateModelStatus: (id, body) => requests.put(`/model3DS/updateModelStatus/${id}`, body, true),
  createProject: data => {
    return requests.post(`/projects`, data, true)
  },
  updateProject: (projectId, body) => requests.put(`/projects/${projectId}`, body, true),
  updateProjectForGuest: (projectId, body) => requests.put(`/projects/guest/${projectId}`, body, true),
  // getAllModels: (projectId) => {
  //   return requests.get(`/modelsByProject/${projectId}`, true)
  // },
  deleteProject: projectId =>
    requests.delete(`/projects/${projectId}`, true),
  getProjectRoles: () => requests.get(`/projectroles`, true),
  updateProjectRole: (id, body) => requests.put(`/projectroles/${id}`, body, true),
  sendProjectInvite: body => requests.post(`/projectuserroles`, body, true),
  createViewPoint: body => requests.post(`/viewpoints`, body, true),
  getProjectViewPoints: id => requests.get(`/viewpoints?project=${id}`, true),
  deleteViewPoint: id => requests.delete(`/viewpoints/${id}`, true),
  getModel3DSById: id => requests.get(`/model3ds/${id}`, true),
  findLocation: (address) => requests.get(`/lookaddress?address=${address}`, true),
  acceptProjectInvitation: (projectId, body) =>
    requests.put(`/projectuserroles/${projectId}`, body, true),
  deleteTileset: (modelId, treeNodeKey = undefined) =>
    requests.delete(`/model3ds/${modelId}/${treeNodeKey}`, true),
  updateCaptureName: (captureId, value) =>
    requests.put(`/viewpoints/${captureId}`, value, true),
  searchProjection: params =>requests.get(`https://api.maptiler.com/coordinates/search/${params}.json?exports=true&key=${process.env.REACT_APP_MAPTILER_API_KEY}`, false, false),
  sendARCaptureInfo: body => requests.post(`/artests`, body, true),
  getAngleFromArTest: id => requests.get(`/artests/${id}`, true),
  getHeightWGS84ToLocal: (reflat, reflng, refheight, lat, lng, height, epsgCode, heightSystem) => requests.get(`/getMatrixHeightWGS84ToLocal?refLat=${reflat}&refLng=${reflng}&refHeight=${refheight}&lat=${lat}&lng=${lng}&height=${height}&epsgCode=${epsgCode}&heightSystem=${heightSystem}`, true),
  getHeightLocalToWGS84: (reflat, reflng, refheight, lat, lng, height, epsgCode, heightSystem) => requests.get(`/getheightLocalToWGS84?refLat=${reflat}&refLng=${reflng}&refHeight=${refheight}&lat=${lat}&lng=${lng}&height=${height}&epsgCode=${epsgCode}&heightSystem=${heightSystem}`, true),
  getHeightTwoPointsWGS84ToLocal: (refPoint1Lat, refPoint1Lng, refPoint1Height, refPoint2Lat, refPoint2Lng, refPoint2Height, Point1lat, Point1lng, Point1height, Point2lat, Point2lng, Point2height, epsgCode, heightSystem) =>
    requests.get(`/getheighttwopoint?refPoint1Lat=${refPoint1Lat}&refPoint1Lng=${refPoint1Lng}&refPoint1Height=${refPoint1Height}&refPoint2Lat=${refPoint2Lat}&refPoint2Lng=${refPoint2Lng}&refPoint2Height=${refPoint2Height}&Point1lat=${Point1lat}&Point1lng=${Point1lng}&Point1height=${Point1height}&Point2lat=${Point2lat}&Point2lng=${Point2lng}&Point2height=${Point2height}&epsgCode=${epsgCode}&heightSystem=${heightSystem}`, true),
  convertProjectPlaneToWGS84Coordinate: (reflat, reflng, refheight, x, y, z, epsgCode, heightSystem) => requests.get(`/convertProjectPlaneToWGS84Coordinate?refLat=${reflat}&refLng=${reflng}&refHeight=${refheight}&x=${x}&y=${y}&z=${z}&epsgCode=${epsgCode}&heightSystem=${heightSystem}`, true),
  convertWGS84ToProjectPlaneCoordinate: (reflat, reflng, refheight, lat, lng, height, epsgCode, heightSystem) => requests.get(`/convertWGS84ToProjectPlaneCoordinate?refLat=${reflat}&refLng=${reflng}&refHeight=${refheight}&lat=${lat}&lng=${lng}&height=${height}&epsgCode=${epsgCode}&heightSystem=${heightSystem}`, true),
  convertProjectPlaneToWGS84: (x, y, z, epsgCode, heightSystem) => requests.get(`/convertProjectPlaneToWGS84?x=${x}&y=${y}&z=${z}&epsgCode=${epsgCode}&heightSystem=${heightSystem}`, true),
  convertProjectPlaneToWGS84XYZ: (lat, lng, height, epsgCode, heightSystem) => requests.get(`/convertProjectPlaneToWGS84XYZ?lat=${lat}&lng=${lng}&height=${height}&epsgCode=${epsgCode}&heightSystem=${heightSystem}`, true),
  calculateHeighCenterModelFlowGeoid: (cenlat, cenlng, cenheight, geoid) => requests.get(`/calculateHeighCenterModelFlowGeoid?cenlat=${cenlat}&cenlng=${cenlng}&cenheight=${cenheight}&geoid=${geoid}`, true),
  getS3ModelTilesetSize: body => requests.post(`/getS3ModelTilesetSize`, body, true),
  getMeshModelInfo: body => requests.post(`/getMeshModelInfo`, body, true),
  getProjectRefPointOnProjectionPlane: body => requests.post(`/getProjectRefPointOnProjectionPlane`, body, true),
  getMeshModelInfoOnWGS84: body => requests.post(`/getMeshModelInfoOnWGS84`, body, true),

  getPublicJson: (body, token) => requests.post(`/projectpermissions/publicjson`, body, token ? true : false),
  updateVersion: (id, body) => requests.put(`/model3ds/updateversion/${id}`, body, true),
  updateIFCEllipsoid: (id, body) => requests.put(`/model3ds/updateIFCEllipsoid/${id}`, body, true),
  getDetailProjectInOrganizationById: (organizationId, projectId) => requests.get(`/projects/getDetailProjectInOrganizationById/${organizationId}/${projectId}`, true),
  // getAllProjectTeamsInOrganization : (organizationId, projectId) => requests.get(`/projectuserroles/getAllPrjTeamsInOrg/${organizationId}/${projectId}`, true),
  getAllProjectTeamsInOrganization: (organizationId, projectId) => requests.get(`/projectuserroles/getAllProjectTeamsInOrganization/${organizationId}/${projectId}`, true),
  deleteUserFromProject: projectUserRoleId => requests.delete(`/projectuserroles/${projectUserRoleId}`, true),
  sendInviteUserToProjectTeamsInOrganization: (projectId, body) => requests.post(`/projectuserroles/inviteUserToProjectTeamsInOrganization/${projectId}`, body, true),
  updateProjectUserRole: (id, body) => requests.put(`/projectuserroles/${id}`, body, true),
  deleteProjectInOrg: (projectId, organizationId) => requests.delete(`/projects/deleteProjectInOrg/${projectId}/${organizationId}`, true),
  searchProjects: params => requests.get(`/projects?name=${params}`, true),
  findProjectUserRole: (projectId, userId) => requests.get(`/projectuserroles?project=${projectId}&user=${userId}`, true),
  rebuildProjectModel: body => requests.post(`/rebuildProjectModel`, body, true),
  customGetProjects: (_limit, _page, search) => requests.get(`/project/paginate?_limit=${_limit}&_page=${_page}${search && search !== '' ? '&search=' + search : ''}`, true),
  migrateProjectOrganization: body => requests.post(`/migrateProjectOrganization`, body, true),
  getGMLObjectInfo: (id, city) => requests.get(`/3dgml/getFeature/${id}/${city}`, true),
  getMaintenanceInfo: body => requests.get(`/maintenance/getMaintenanceInfo`, true),
  updateMaintenanceInfo: body => requests.post(`/maintenance/updateMaintenanceInfo`, body, true),
  createProjectTemplate: (projectId, data) => requests.post(`/createProjectTemplate/${projectId}`, data, true),
  getFeedbackReport: (projectId) => requests.get(`/getFeedbackReport/${projectId}`, true),
  generatePreSignedPutUrl: body => requests.post(`/generatePreSignedPutUrl`, body, true),
  updateProjectStorage: (id, body) => requests.put(`/updateProjectStorage/${id}`, body, true),
  duplicateProject: (projectId, data) => requests.post(`/project/duplicateProject/${projectId}`, data, true),
  updateProjectThumnail: (projectId, body) => requests.put(`/project/updateProjectThumnail/${projectId}`, body, true),
  updateProjectTilesetData: (projectId, body) => requests.put(`/project/updateProjectTilesetData/${projectId}`, body, true),
  getListModelsByProjectId: (projectId, params) => requests.get(`/model3ds?projectId=${projectId}${params ? params : ''}`, true),
  deleteModel: (body) => requests.post(`/deleteModel`, body, true),
  deleteMultiModels: (body) => requests.post(`/deleteMultiModels`, body, true),
  checkJSONLink: (body) => requests.post(`/checkJSONLink`, body, false),
  calculateReportingTool: (projectId, body) => requests.post(`/project/attributereport/${projectId}`, body, true),
  checkProjectIsDeleted: id => requests.get(`/checkProjectIsDeleted/${id}`, true),
  getFeaturesHelpListInfo: key => requests.get(`/helpPages/getFeaturesHelpListInfo/${key}`, true),
  updateFeaturesHelpListInfo: body => requests.post(`/helpPages/updateFeaturesHelpListInfo`, body, true),
  waitUntilDataTreeReady: (modelId) => requests.get(`/waitUntilDataTreeReady/${modelId}`, true),
  getProjectUserRole: (projectId) => requests.get(`/getProjectUserRole/${projectId}`, true),
  updateProjectTreeData: (projectId, body) => requests.put(`/updateProjectTreeData/${projectId}`, body, true),
  checkServiceChangeLogo : id => requests.get(`/checkServiceChangeLogo/${id}`, true),
  updateProjectMetadata: (projectId, body) => requests.put(`/updateProjectMetadata/${projectId}`, body, true),
  getCalculationSettings : id => requests.get(`/getCalculationSettings/${id}`, true),
  updateProjectSetting: (projectId, body) => requests.put(`/updateProjectSetting/${projectId}`, body, true),
  checkXDEnginePointCloudReady: (projectId, body) => requests.post(`/checkXDEnginePointCloudReady/${projectId}`, body, true),
  checkReadyRebuildProject: (body) => requests.post(`/checkReadyRebuildProject`, body, true),
  getLog: (body) => requests.post(`/getLog`, body, true),
}

const ProjectTeamsRequest = {
  getAllProjectTeams: projectId =>
    requests.get(`/projectuserroles?project=${projectId}`, true),
}

const SketchRequest = {
  createSketch: data => requests.post(`/sketches`, data, true),
  deleteSketch: sketchId => requests.delete(`/sketches/${sketchId}`, true),
  updateSketch: (sketchId, body) => requests.put(`/sketches/${sketchId}`, body, true),
  getSketchByProjectId: (projectId) => requests.get(`/sketches/getSketchByProjectId/${projectId}`, true),
  deleteSketchObject: (body) => requests.post(`/sketches/deleteSketchObject`, body, true),
}

const TokenRequest = {
  getValidToken: (token) => requests.get(`/tokens/${token}`, true),
  sendMailConfirmDelete: body => requests.post(`/tokens/confirmdelete`, body, true),
  sendForgotPasswordEmail: body => requests.post(`/tokens/sendForgotPasswordEmail`, body),
  createToken: body => requests.post(`/tokens`, body, true),
  updateToken: (id, body) => requests.put(`/tokens/${id}`, body, true),
  deleteToken: id => requests.delete(`/tokens/${id}`, true),
  getPublicValidToken: (token) => requests.get(`/tokens/${token}`),
  confirmChangePassword: body => requests.post(`/tokens/confirmChangePassword`, body)
}

const WorkflowRequest = {
  create: body => requests.post('/workflows', body, true),
  getWorkflowByProject: projectId => requests.get(`/workflows?project=${projectId}`, true),
  getWorkflowById: id => requests.get(`/workflows/${id}`, true),
  updateWorkflow: (id, body) => requests.put(`/workflows/${id}`, body, true),
  deleteWorkflow: id => requests.delete(`/workflows/${id}`, true),
}

const HistoryTopicRequest = {
  getDataTopicTimeLineChart: body => requests.post(`/historytopics/getDataTopicTimeLineChart`, body, true),
  getWorkflowDataChart: body => requests.post(`/historytopics/getWorkflowDataChart`, body, true)
}

const TopicRequest = {
  getTopicList: (projectId, params) => requests.get(`/topics?projectId=${projectId}${params ? params : ''}`, true),
  getTopicTags: (projectId) => requests.get(`/topictags?projectId=${projectId}`, true),
  findTopicTag: (tagName) => requests.get(`/topictags/${tagName}`, true),
  create: body => requests.post('/topics', body, true),
  getTopicById: topicId => requests.get(`/topics/${topicId}`, true),
  updateTopic: (id, body) => requests.put(`/topics/${id}`, body, true),
  CompleteTopicPhase: (body) => requests.post(`/topicphases/completephase`, body, true),
  createComment: body => requests.post('/topicdiscussions', body, true),
  updateComment: (id, body) => requests.put(`/topicdiscussions/${id}`, body, true),
  deleteComment: id => requests.delete(`/topicdiscussions/${id}`, true),
  create3DView: body => requests.post(`/topic3Dviews`, body, true),
  updateTopic3Dviews: (id, body) => requests.put(`/topic3Dviews/${id}`, body, true),
  deleteTopic3Dviews: id => requests.delete(`/topic3Dviews/${id}`, true),
  updateTopic3Dlocation: (id, body) => requests.put(`/topics/updateTopic3Dlocation/${id}`, body, true),
  deleteTopic: id => requests.delete(`/topics/${id}`, true),
  searchProjectTeam: (projectId, params) => requests.get(`/topics/searchProjectTeam?project=${projectId}&p=${params}`, true),
  deleteMultiTopic: (body) => requests.post(`/topics/deleteMultiTopic`, body, true),
  createTopic3DObjects: body => requests.post(`/topic3Dobjects`, body, true),
  getTopic3DObjectsName: body => requests.post(`/topic3Dobjects/map`, body, true),
  deleteTopic3DObject: id => requests.delete(`/topic3Dobjects/${id}`, true),
  getProjectTopics: (projectId) => requests.get(`/getProjectTopics/${projectId}`, true)
}

const OrganizationRequest = {
  createOrganization: (body) => requests.post(`/customCreate`, body, true),
  create: (body) => requests.post(`/organizations`, body, true),
  customUpdateOrganization: (id, body) => requests.put(`/customUpdateOrganization/${id}`, body, true),
  getOrganization: () => requests.get(`/organizations`, true),
  deleteOrganization: id => requests.delete(`/organizations/${id}`, true),
  getOrganizationById: id => requests.get(`/organizations/${id}`, true),
  updateOrganization: (id, body) => requests.put(`/organizations/${id}`, body, true),
  getOrganizationBelongUser: () => requests.get(`/organizationuserroles/getOrganizationBelongUser`, true),
  deleteUserFromProject: projectUserRoleId => requests.delete(`/projectuserroles/${projectUserRoleId}`, true),
  getOrganizationRoles: () => requests.get(`/organizationroles`, true),
  getOrganizationRolesById: id => requests.get(`/organizationroles/${id}`, true),
  deleteOrganizationRole: id => requests.delete(`/organizationroles/${id}`, true),
  updateOrganizationRole: (id, body) => requests.put(`/organizationroles/${id}`, body, true),
  createOrganizationRole: (body) => requests.post(`/organizationroles`, body, true),
  getOrganizationUserBelong: () => requests.get(`/organizationuserroles/getOrganizationUserBelong`, true),
  getOrganizationUserBelongCreateNewProject: () => requests.get(`/organizationuserroles/getOrganizationUserBelongCreateNewProject`, true),
  getRoleCurrentUserInOrganization: organizationId => requests.get(`/organizationuserroles/getRoleCurrentUserInOrganization/${organizationId}`, true),
  sendWarningQuotaLicense: (body) => requests.post(`/sendWarningQuotaLicense`, body, true),
  sendUserInviteOrganization: body => requests.post(`/organizationuserroles`, body, true),
  deleteUserFromOrganization: organizationroleId => requests.delete(`/organizationuserroles/${organizationroleId}`, true),
  acceptInviteToOrganization: (organizationuserrolesId, body) => requests.put(`/organizationuserroles/${organizationuserrolesId}`, body, true),
  getOrganizationUserRole: () => requests.get(`/organizationuserroles`, true),
  deleteOrganizationUserRole: id => requests.delete(`/organizationuserroles/${id}`, true),
  updateOrganizationUserRole: (id, body) => requests.put(`/organizationuserroles/${id}`, body, true),
  createOrganizationUserRole: (body) => requests.post(`/organizationuserroles`, body, true),
  createAtRegisterUser: (body) => requests.post(`/createAtRegisterUser`, body, true),
  checkInvitationOrganization: id => requests.get(`/organizationuserroles/checkInvitationOrganization/${id}`, true),
  getOrganizationTemplates: (body) => requests.post(`/getOrganizationTemplates`, body, true),
  customGetOrganizations: (_limit, _page, search) => requests.get(`/organizations/paginate?_limit=${_limit}&_page=${_page}${search && search !== '' ? '&search=' + search : ''}`, true),
  getOrgLicenses: (id) => requests.get(`/licenses/getOrgLicenses/${id}`, true),
  getAllUserInOraganization: id => requests.get(`/getAllUserInOraganization/${id}`, true)
}

const LicenseRequest = {
  createLicense: (body) => requests.post(`/licenses`, body, true),
  getLicense: () => requests.get(`/licenses`, true),
  getLicenseById: id => requests.get(`/licenses/${id}`, true),
  deleteLicense: id => requests.delete(`/licenses/${id}`, true),
  updateLicense: (id, body) => requests.put(`/licenses/${id}`, body, true),
  findLicenseByUser: userID => requests.get(`/licenses?user=${userID}`, true),
  getAvailableLicenses: (body) => requests.post(`/licenses/getAvailableLicenses`, body, true),
  createLicenseType: (body) => requests.post(`/licensetypes`, body, true),
  getLicenseType: () => requests.get(`/licensetypes`, true),
  getLicenseTypeById: id => requests.get(`/licensetypes/${id}`, true),
  deleteLicenseType: id => requests.delete(`/licensetypes/${id}`, true),
  updateLicenseType: (id, body) => requests.put(`/licensetypes/${id}`, body, true),
  getQuotaLicenseOrganization: (organizationID) => requests.get(`/getQuotaLicenseOrganization/${organizationID}`, true),
  getExpirationLicense: (projectId) => requests.get(`/getExpirationLicense/${projectId}`, true),
  renewLicense: (body) => requests.post(`/licenses/renewLicense`, body, true),
  customGetLicenses: (_limit, _page, search) => requests.get(`/licensesPaginate?_limit=${_limit}&_page=${_page}${search && search !== '' ? '&search=' + search : ''}`, true),
  customGetLicenseTypes: (_limit, _page, search) => requests.get(`/licensetypePaginate?_limit=${_limit}&_page=${_page}${search && search !== '' ? '&search=' + search : ''}`, true),

}

const IFCEngineRequest = {
  create: (body) => requests.post(`/ifcengineusages`, body, true),
  getIFCEngineById: id => requests.get(`/ifcengineusages/${id}`, true),
  delete: id => requests.delete(`/ifcengineusages/${id}`, true),
  updateIFCEngine: (id, body) => requests.put(`/ifcengineusages/${id}`, body, true),
  paginate: (_limit, _page, search) => requests.get(`/ifcengineusage/paginate?_limit=${_limit}&_page=${_page}${search && search !== '' ? '&search=' + search : ''}`, true),
  getIFCEngines: (startDate, endDate, type) => requests.post(`/ifcengineusage/customfilter?${startDate ? 'startDate=' + startDate : ''}${startDate && endDate ? '&' : ''}${endDate ? 'endDate=' + endDate : ''}`, type, true)
}


const ModelAttributeRequest = {
  getAttributByGUID: (GUID, model3D) => requests.get(`/modelattributebyGUID/${model3D}/${GUID}`, true),
  getAttributeByPkey: (pKey, model3D) => requests.get(`/modelattributebyPkey/${model3D}/${pKey}`, true),
}

const ModelTreeDataRequest = {
  getTreeDataByModel: (model3D) => requests.get(`/modeltreedatabymodel3D/${model3D}`, true),
}

const FeedbackRequest = {
  autoGeneralFeedbackGeneric: (body) => requests.post(`/feedbackforms/autoGeneralFeedbackGeneric`, body, true),
  createFeedbackForm: body => requests.post('/feedbackforms', body, true),
  deleteFeedbackEditor: id => requests.delete(`/feedbackforms/${id}`, true),
  updateFeedbackEditor: (id, body) => requests.put(`/feedbackforms/${id}`, body, true),
  getGenericFeedbackByProjectId: (projectId) => requests.get(`/feedbackforms/getGenericFeedback/${projectId}`, true),
  createFeedbackAnswer: body => requests.post('/feedbackanswers', body, false),
  updateFeedbackAnswer: (id, body) => requests.put(`/feedbackanswers/${id}`, body, false, true),
  getFeedbackAnswerList: (projectId, type) => requests.get(`/feedbackanwerlist/${projectId}/${type}`, true), //type: (generic, normal, all)
  getFeedbackAnswerDetail: id => requests.get(`/feedbackanswers/${id}`, true),
  deleteFeedbackAnswers: id => requests.delete(`/feedbackanswers/${id}`, true),
  getFeedbackformsList: (projectId, type) => requests.get(`/feedbackformslist/${projectId}/${type}`, true), //type: (generic, normal, all)
  checkExistGenericFeedbackForm: (projectId) => requests.get(`/feedbackforms/checkExistGenericFeedbackForm/${projectId}`, true), //ispublic: (1, 0)
  decidimConnector: body => requests.post('/feedbackanswers/decidim-connector', body, false),
  decidimAddComment: body => requests.post('/feedbackanswers/decidim-add-comment', body, false),
}

const FeatureandroleRequest = {
  createFeatureandrole: (body) => requests.post(`/featureandroles`, body, true),
  getFeatureandrole: () => requests.get(`/featureandroles`, true),
  getFeatureandroleById: id => requests.get(`/featureandroles/${id}`, true),
  deleteFeatureandrole: id => requests.delete(`/featureandroles/${id}`, true),
  updateFeatureandrole: (id, body) => requests.put(`/featureandroles/${id}`, body, true),
}

const FeatureRequest = {
  createFeature: (body) => requests.post(`/features`, body, true),
  getFeature: () => requests.get(`/features`, true),
  getFeatureById: id => requests.get(`/features/${id}`, true),
  deleteFeature: id => requests.delete(`/features/${id}`, true),
  updateFeature: (id, body) => requests.put(`/features/${id}`, body, true),
  updateFeatureSetting: (id, body) => requests.put(`/featuresettings/${id}`, body, true),
  createFeatureSetting: (body) => requests.post(`/featuresettings`, body, true),
  getFeatureSetting: (type, projectId) => requests.get(`/featuresettings/${projectId}/${type}`, true), //type: (system, project,all)
}

const PreDefinedAccessRequest = {
  createPreDefinedAccess: (body) => requests.post(`/predefinedaccesses`, body, true),
  getPreDefinedAccesss: () => requests.get(`/predefinedaccesses`, true),
  getPreDefinedAccessById: id => requests.get(`/predefinedaccesses/${id}`, true),
  deletePreDefinedAccess: id => requests.delete(`/predefinedaccesses/${id}`, true),
  updatePreDefinedAccess: (id, body) => requests.put(`/predefinedaccesses/${id}`, body, true),
}

const HistoryPolicyRequest = {
  create: (body) => requests.post(`/historypolicyandterms`, body, true),
  getAll: () => requests.get(`/historypolicyandterms`, true),
  getDetail: id => requests.get(`/historypolicyandterms/${id}`, true),
  delete: id => requests.delete(`/historypolicyandterms/${id}`, true),
  update: (id, body) => requests.put(`/historypolicyandterms/${id}`, body, true),
}

const SketchElementRequest = {
  create: (body) => requests.post(`/sketchelements`, body, true),
  get: (type, prj, org) => requests.get(`/sketchelements${type ? "?type=" + type : ''}${prj ? '&project=' + prj : ''}${org ? '&organization=' + org : ''}`, true),
  getById: id => requests.get(`/sketchelements/${id}`, true),
  delete: id => requests.delete(`/sketchelements/${id}`, true),
  update: (id, body) => requests.put(`/sketchelements/${id}`, body, true),
  mergeSketchLibraryElement: (projectId) => requests.get(`/sketchelements/mergeSketchLibraryElement/${projectId}`, true),
}

const LanguageRequest = {
  updateLanguageEditor: (body) => requests.post(`/features/updateLanguageJSON`, body, true)
}

const GEOIDRequest = {
  getListGEOID: () => requests.get(`/geoidsystems`, true),
  getGEOIDById: id => requests.get(`/geoidsystems/${id}`, true),
  createGEOID: (body) => requests.post(`/geoidsystems`, body, true),
  updateGEOID: (id, body) => requests.put(`/geoidsystems/${id}`, body, true),
  deleteGEOID: id => requests.delete(`/geoidsystems/${id}`, true),
  generateFileGeoIni: () => requests.get(`/geoidsystems/generate-geo-ini`, true),
  getAllGEOID: () => requests.get(`/geoidsystems/get-geoid`, true),
}

const PointsceneRequest = {
  pointsceneAuthentication: () => requests.get(`/pointsceneAuthentication`, true),
  createWorkflow: (body) => requests.post(`/pointsceneCreateWorkflow`, body, true),
  createWorkflowZipResource: (body) => requests.post(`/pointsceneCreateWorkflowZipResource`, body, true),
  uploadFromUrlToS3: (body) => requests.post(`/uploadFromUrlToS3`, body, true),
}

const ProjectCustomAttributesRequest = {
  create: (body) => requests.post(`/projectcustomattributes`, body, true),
  getAll: () => requests.get(`/projectcustomattributes`, true),
  getProjectAttribute: (projectId) => requests.get(`/projectcustomattributes?project=${projectId}`, true),
  getById: id => requests.get(`/projectcustomattributes/${id}`, true),
  delete: id => requests.delete(`/projectcustomattributes/${id}`, true),
  update: (id, body) => requests.put(`/projectcustomattributes/${id}`, body, true),
  updateProjectAttributes: (prjCustomAttributeId, body) => requests.put(`/updateProjectAttributes/${prjCustomAttributeId}`, body, true),
  getProjectCustomAttributes: id => requests.get(`/getProjectCustomAttributes/${id}`, true)
}

const ModelCustomAttributesRequest = {
  create: (body) => requests.post(`/modelcustomattributes`, body, true),
  getAll: () => requests.get(`/modelcustomattributes`, true),
  getById: id => requests.get(`/modelcustomattributes/${id}`, true),
  delete: id => requests.delete(`/modelcustomattributes/${id}`, true),
  update: (id, body) => requests.put(`/modelcustomattributes/${id}`, body, true),
  getModelAttributes: (objectId, body) => requests.put(`/getModelAttributes/${objectId}`, body, true),
  updateModelAttributes: (body) => requests.put(`/updateModelAttributes`, body, true)
}

const GenericTemplateRequest = {
  create: (body) => requests.post(`/genericreporttemplates`, body, true),
  getAll: () => requests.get(`/genericreporttemplates`, true),
  getById: id => requests.get(`/genericreporttemplates/${id}`, true),
  getProjectTemplate: (projectId) => requests.get(`/genericreporttemplates?project=${projectId}`, true),
  delete: id => requests.delete(`/genericreporttemplates/${id}`, true),
  update: (id, body) => requests.put(`/genericreporttemplates/${id}`, body, true)
}

const TriggerconvertlandxmlifcsRequest = {
  getDataByHash: (body) => requests.post(`/triggerconvertlandxmlifcs/getDataByHash`, body, true),
}


const ObjectInforRequest = {
  getTreeObjectInfo: (body) => requests.post(`/getTreeObjectInfo`, body, true),
  getTreeGuid:  (body) => requests.post(`/getTreeGuid`, body, false),
  searchObjectInfor: (body) => requests.post(`/searchObjectInfor`, body, true),
  calculateObjectInfo: (projectId, body, cancelToken) => requests.post(`/calculateObjectInfo/${projectId}`, body, true, false, cancelToken),
  getListGuidHighlight: (projectId, body) => requests.post(`/getListGuidHighlight/${projectId}`, body, true),
  getTotalObjectModelForProject: (projectId) => requests.get(`/getTotalObjectModelForProject/${projectId}`, true),
  checkObjectInforsReady: (projectId, body) => requests.post(`/objectinfors/check-object-infors-ready/${projectId}`, body, true),
}

const ProjectUserGroupRequest = {
  create: (body) => requests.post(`/projectusergroups`, body, true),
  getAll: (projectId) => requests.get(`/projectusergroups?project=${projectId}`, true),
  getById: id => requests.get(`/projectusergroups/${id}`, true),
  delete: id => requests.delete(`/projectusergroups/${id}`, true),
  update: (id, body) => requests.put(`/projectusergroups/${id}`, body, true),
  createGroupDefault: (projectId) => requests.get(`/createGroupDefault/${projectId}`, true),
}

const ProjectUserGroupMemberRequest = {
  create: (body) => requests.post(`/projectusergroupmembers`, body, true),
  getAllMemberInGroup: (groupId) => requests.get(`/projectusergroupmembers?group=${groupId}`, true),
  getAllGroupByUserId: (userId) => requests.get(`/projectusergroupmembers?user=${userId}`, true),
  getAll: () => requests.get(`/projectusergroupmembers`, true),
  getById: id => requests.get(`/projectusergroupmembers/${id}`, true),
  delete: id => requests.delete(`/projectusergroupmembers/${id}`, true),
  update: (id, body) => requests.put(`/projectusergroupmembers/${id}`, body, true),
  getListUserNotGroup: (projectId) => requests.get(`/getListUserNotGroup/${projectId}`, true),
}

const ObjectsQueriesRequest = {
  create: (body) => requests.post(`/objectsqueries`, body, true),
  getByProjectId: (projectID) => requests.get(`/objectsqueries?project=${projectID}`, true),
  getAllObjectsQueries: () => requests.get(`/objectsqueries`, true),
  getById: id => requests.get(`/objectsqueries/${id}`, true),
  delete: id => requests.delete(`/objectsqueries/${id}`, true),
  update: (id, body) => requests.put(`/objectsqueries/${id}`, body, true)
}

const QueueLamdaLas = {
  getAllLasTrigger: (projectId) => requests.get(`/queuetriggerlamdas?project=${projectId}&status=done`, true),
  delete: id => requests.delete(`/queuetriggerlamdas/${id}`, true),
}

const Gannt4dRequest = {
  // Project gantt (wrapper)
  getByProjectId: async (projectId) => requests.get(`/gantts?projectId=${projectId}`, true),
  create: (body) => requests.post(`/gantts`, body, true),
  getById: id => requests.get(`/gantts/${id}`, true),
  delete: id => requests.delete(`/gantts/${id}`, true),
  update: (id, body) => requests.put(`/gantts/${id}`, body, true),
  importProject: (body) => requests.post(`/gantts/importProject`, body, true),

  // gantt task
  createGanttTask: (body) => requests.post(`/gantttasks`, body, true),
  getGantttaskById: id => requests.get(`/gantttasks/${id}`, true),
  deleteGantttask: id => requests.delete(`/gantttasks/${id}`, true),
  updateGantttask: (id, body) => requests.put(`/gantttasks/${id}`, body, true),
  baselineGenerator: (body) => requests.post(`/gantttasks/baselineGenerator`, body, true),
  transformObjectInfoToTask: (body) => requests.post(`/gantttasks/transform-objectinfo-to-task`, body, true),

  //gantt links
  createGanttLink: (body) => requests.post(`/ganttlinks`, body, true),
  getGanttLinkById: id => requests.get(`/ganttlinks/${id}`, true),
  deleteGanttLink: id => requests.delete(`/ganttlinks/${id}`, true),
  updateGanttLink: (id, body) => requests.put(`/ganttlinks/${id}`, body, true),

  //gantt task 3dobject
  creatGanttTask3DObjects: body => requests.post(`/ganttTask3DObject`, body, true),
  getGantt3DObjectsName: body => requests.post(`/ganttTask3DObject/map`, body, true),
}

export {
  requests,
  AuthRequest,
  FileRequest,
  UserRequest,
  ProjectRequest,
  ProjectTeamsRequest,
  SketchRequest,
  TokenRequest,
  WorkflowRequest,
  TopicRequest,
  OrganizationRequest,
  ModelAttributeRequest,
  LicenseRequest,
  ModelTreeDataRequest,
  FeedbackRequest,
  FeatureandroleRequest,
  FeatureRequest,
  HistoryTopicRequest,
  PreDefinedAccessRequest,
  IFCEngineRequest,
  HistoryPolicyRequest,
  SketchElementRequest,
  LanguageRequest,
  GEOIDRequest,
  PointsceneRequest,
  ProjectCustomAttributesRequest,
  ModelCustomAttributesRequest,
  GenericTemplateRequest,
  TriggerconvertlandxmlifcsRequest,
  ProjectLinkRequest,
  ObjectInforRequest,
  ProjectUserGroupRequest,
  ProjectUserGroupMemberRequest,
  ObjectsQueriesRequest,
  QueueLamdaLas,
  Gannt4dRequest
}
