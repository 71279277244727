import styled from "styled-components";

export const TimelineWrapper = styled.div`
  .datawrapper{
    height: 400px;
    overflow-y: auto;
    overflow-x: auto;
    padding-top: 10px;
  }

  .pagination{
    display: flex;
    justify-content: end;
    margin-top: 20px;
  }
`;