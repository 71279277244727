import { observable, action, decorate, toJS } from 'mobx'
import { message } from 'antd'
// Request
import { ProjectRequest, GEOIDRequest, LicenseRequest, ProjectCustomAttributesRequest, ModelCustomAttributesRequest, GenericTemplateRequest, ProjectLinkRequest, QueueLamdaLas, ObjectInforRequest } from '@/requests'
// StoresProjectDetailProjectDetail
import capturesStore from './capturesStore'
import projectSettingStore from './projectSettingStore'
import usersStore from './usersStore'
import organizationStore from './organizationStore'
import moment from 'moment'

import modelStore from './modelStore'
import { cesiumToken, assetUrl, corsproxy, siteMode, } from '@/config'
import axios from 'axios'
import KalmanFilter from 'kalmanjs';
import { reloadUrl, getModelUrBySrc, extractHostname, goViewpoint, setxDCamera, isIFCEllipsoid, isCloudPointXDEngineEllipsoid, isCloudPointCesiumION, isE57XDEngineEllipsoid } from '@/helper'
import { Math as CesiumMath, Cartesian3, Cartographic, EllipsoidGeodesic } from 'cesium'
import { t } from 'i18next';
import settings from '../siteConfig'
import { isBuffer } from 'lodash'
import { checkAvaiableLicenses, processPublicLink } from '@/lib/projectLib'

//#region 
var MA = function (range, bufferMaxSize, reserveRange) {
  this.buffer = []; // FIFO queue
  this.bufferMaxSize = bufferMaxSize || 20;
  this.avgRange = range || 10
  this.keepBuffer = []
  this.keepBufferSize = reserveRange || this.bufferMaxSize * 2;
  this.sumSin = 0;
  this.sumCos = 0;
  this.avg = 0;
}
MA.prototype = {
  /**
   * Add new value to buffer (FIFO queue)
   *
   * @param {integer|float} value
   * @returns {integer|float}
   * @access private
   */
  __push: function (value) {
    this.sumSin += Math.sin(value);

    this.sumCos += Math.cos(value);
    if (this.buffer.length >= this.avgRange) {
      const old = this.buffer[this.buffer.length - this.avgRange];
      this.sumSin -= Math.sin(old);
      this.sumCos -= Math.cos(old);
    }
    var removed = (this.buffer.length === this.bufferMaxSize)
      ? this.buffer.shift()
      : 0;
    // if (this.buffer[this.buffer.length - 1] && (Math.sign(this.buffer[this.buffer.length - 1]) * Math.sign(value) < 0)) {
    //   this.buffer = [];
    // }

    var removed2 = (this.keepBuffer.length === this.keepBufferSize)
      ? this.keepBuffer.shift()
      : 0;
    this.buffer.push(value);
    this.keepBuffer.push(value);
    return removed;
  },

  __avg: function (arr, idx, range) {
    if (arr.length > idx && (idx + 1 - range) > -1) {

      const sum = this.__sum(arr.slice(Math.max(arr.length - range, 1)))
      this.avg = Math.atan2(this.sumSin / range, this.sumCos / range);

      return this.avg;
      //return sum / range;
    }
    else {
      this.avg = Math.atan2(this.sumSin / arr.length, this.sumCos / arr.length);
      return this.avg;
      return arr[idx]
    }
  },

  __sum: function (arr) {
    var len = arr.length;
    var num = 0;
    while (len--) num += Number(arr[len]);
    return num;
  },

  /**
   * Smooth value from stream
   *
   * @param {integer|float} nextValue
   * @returns {integer|float}
   * @access public
   */
  next: function (nextValue, type) {
    var self = this;
    // push new value to the end, and remove oldest one
    var removed = this.__push(nextValue);
    // smooth value using all values from buffer
    // var result = this.buffer.reduce(function (last, current) {
    //   return self.smoothing * current + (1 - self.smoothing) * last;
    // }, removed);
    var result = this.__avg(this.buffer, this.buffer.length - 1, this.avgRange);

    if (type === 'heading') {
      // if (nextValue < -3 || nextValue > 3) {
      //   result = this.buffer[this.buffer.length - 1];
      // }
    }

    // replace smoothed value
    //this.buffer[this.buffer.length - 1] = result;
    this.count++;
    return result;
  },
};


var LPF = function (smoothing) {
  this.smoothing = smoothing || 0.5; // must be smaller than 1
  this.buffer = []; // FIFO queue
  this.bufferMaxSize = 20;
  this.count = 0;
  this.sumSin = 0;
  this.sumCos = 0;
  this.avg = 0;
};

LPF.prototype = {

  /**
   * Init buffer with array of values
   * 
   * @param {array} values
   * @returns {array}
   * @access public
   */
  init: function (values) {
    for (var i = 0; i < values.length; i++) {
      this.__push(values[i]);
    }
    return this.buffer;
  },

  /**
   * Add new value to buffer (FIFO queue)
   *
   * @param {integer|float} value
   * @returns {integer|float}
   * @access private
   */
  __push: function (value) {
    var removed = (this.buffer.length === this.bufferMaxSize)
      ? this.buffer.shift()
      : 0;

    this.buffer.push(value);
    return removed;
  },

  /**
   * Smooth value from stream
   *
   * @param {integer|float} nextValue
   * @returns {integer|float}
   * @access public
   */
  next: function (nextValue, type) {
    var self = this;
    // push new value to the end, and remove oldest one
    var removed = this.__push(nextValue);
    // smooth value using all values from buffer
    var result = this.buffer.reduce(function (last, current) {
      return self.smoothing * current + (1 - self.smoothing) * last;
    }, removed);

    if (type === 'heading') {
      if (nextValue < -3 || nextValue > 3) {
        result = this.buffer.reduce(function (last, current) {
          return current;
        }, removed);
      }
    }

    // replace smoothed value
    this.buffer[this.buffer.length - 1] = result;
    this.count++;
    return result;
  },

  /**
   * Smooth array of values
   *
   * @param {array} values
   * @returns {undefined}
   * @access public
   */
  smoothArray: function (values) {
    var value = values[0];
    for (var i = 1; i < values.length; i++) {
      var currentValue = values[i];
      value += (currentValue - value) * this.smoothing;
      values[i] = Math.round(value);
    }
    return values;
  },
};
//#endregion

class ProjectStore {
  sensorFrequences = 30;
  smoothFilterType = 'na';
  isLoading = false
  kfHead = new KalmanFilter();
  kfPitch = new KalmanFilter();
  kfRoll = new KalmanFilter();
  filterHead = new LPF(0.5)
  filterPitch = new LPF(0.8)
  filterRoll = new LPF(0.8)
  maHead = new MA(this.sampleRange, 60)
  maPitch = new MA(this.sampleRange, 30)
  maRoll = new MA(this.sampleRange, 30)
  projectList = []
  projectDetail = {}
  modelList = []
  isMasking = false
  isExplorerVisible = false
  showAddProjectModal = false
  showEditLocation = false
  showProcessInBackground = false
  checkModalStatus = false
  dataAttributeModal = false
  zoomClick = false
  obtDeviceSensor = {}
  displayPanel = false
  currentAddTab = 'uploadTab'
  currentModelId = false // id of current selected model  
  // currentEditModel = false // data of current selected model
  currentInitLocation = false
  currentTile = false
  centerData = false
  selectedModel = false
  showProjectInfoDrawer = false
  deleteIds = []
  newModelId = false
  gpsMode = 'none' // gps mode : none, fix, free
  skyColor = 'none' // default, white, camera
  viewMode = 'Default mode' // default mode, measure point, measure distance
  showBoudingVolume = false
  showMap = true
  globeBehind
  showAntialiasing = true
  showRefPoint = false
  showHUD = false
  showAmbientOcclusion = true
  showInspectorTool = false
  modelEditPos = false
  geoWatch = {}
  obtGPSOrientation = {}
  averageOrientation = {}
  averageThreshold = 3
  obtGPSOrientations = []
  currentGPS = {}
  WorldTerrain = undefined
  clippingMode = null
  clippingPickDone = false
  projectionList = []
  epsgCodeList = []
  projectionDetail = []
  obtGPSHeight = { height: 0, lat: undefined, lon: undefined }
  isSendARInfo = false
  zoomToModel = false
  visibleTilesets = []
  visible4DFolders = []
  arToolTip = 'none';
  cleanMode = false
  showFov = false
  showAmbientOcclusionSetting = false
  showAddFolderModal = false
  showEditFileModal = false
  selectedNode = null
  isSelectedNode = false
  folderEdit = null
  sketchEdit = null
  fileEdit = null
  isUpdateViewOrigo = false //When origo update
  is2D = false
  currentProjectDetail = {}
  totalProject
  closeEditModel = false
  showProjectDashBoard = false
  changeModelStyle = 0
  currentCamera = null
  showBackgroundSetting = false
  maintenanceInfo = {}
  openCVData = {
    id: 0,
    angle: -1000,
    vAngle: -1000,
    apply: false,
    extra: {
      hessianThreshold: 400,
      nOctaves: 4,
      nOctaveLayers: 4,
      extended: 0,
      upright: 1,
      ratio_thresh: 0.8,
      angle_filter: 0,
      distance_filter: 80,
      pixel_size: 16,
      key_rotation: 80,
      pixel_diff: 50,
      fov: 60,
      fovy: 33.5,
      image_filter: 4
    },
    hc: 0, // horizontal calibration
    vc: 0
  }
  absoluteHPRData = []
  recordData = {
    sensorRawData: [],
    position: [],
    dataAfterSmoothingFiltering: []
  }

  underGroundSetting = {
    enabled: true,
    nearDistance: 1000,
    farDistance: 100000,
    nearAlpha: 0.1,
    farAlpha: 0.7,
  }

  ambientOccSetting = {
    intensity: 1.0,
    bias: 0.4,
    lengthCap: 0.3,
    stepSize: 2,
    frustumLength: 100.0,
    blurStepSize: 2
  }

  showHiddenTilesList = false
  showHiddenDrawer = false
  showHiddenAreaTab = false
  hiddenTileSelected = []
  hiddenCityGmlSelected = []
  visitedMode = true
  projectDetailError = {}
  combineBoundingSpheres = []
  isShowARCamera = false

  renderResolutionControl = false
  renderRecordDataControl = false

  sampleRange = 8
  startRecordData = false
  enableReCenterGPS = false
  reCenterGPS = false
  modelFeatures = {}
  alignmentFeatures = []
  showAddResourceModel = false
  selectedAttrData = null
  dropDownMenuCameraSettings = false
  selectedFeature = null
  loadedModelTreeData = null
  loadingAttrData = false
  hideFeatures = {}
  showUpdateResourceModel = false
  navigationStyles = 'xdTwin'
  progressUploadFile = 0
  progressFileOnS3Ion = false
  currentUserRoleInProject = null
  beforeVisibleTileset = []
  restoreUserView3Dmode = false
  pickPosition = null
  showFeatureSettingDrawer = false

  listMoveEndCb = []
  listOnTickCb = []

  webglContextAlpha = false

  shadowDarkness = 0.5
  shadowDistance = 1000
  shadowAccuracy = 4096
  softShadows = false

  changeHeadingRotation = false
  tileViews = []
  Alignment = []
  maximumScreenSpaceError = 16
  gmlmaximumScreenSpaceError = 100
  rebuildModel = false
  selectedStatus = false
  city3DDB = false
  dataTemplateChecked = {}
  showAddProjectTemplateModal = false
  selectedPreDefineAcess = {}
  polylines = []
  currentViewpoint = {}
  modelHiddenBeforeOpenViewPoint = []
  targetViewPoint = 0
  maxCountOfVisibleTiles = 200
  typeViewPoint = null
  feedbackReports = []
  uploadNewStartImage = false
  reloadAlignment = false
  showTwinGuideDialog = false
  hideTwinGuideDialog = false
  check2D = { load: false, status: false }
  projectStorage = 0
  expirationLicenses = []
  showDialogExpirationLicense = false
  clippingViewPoint = false
  showDuplicateProjectModal = false
  isExistLicenses = true
  attributesTab = 'attributes'
  showCustomAttributeDrawer = false
  prjCustomAttributeList = []
  objectCustomAttributes = {
    fileLevel: [],
    objectLevel: []
  }
  currentProjectAttribute = false
  visibleModalProjectAtt = false
  currentObjectAttribute = false
  visibleModalObjectAtt = false
  isEditingModelAttribute = false
  isEditingObjectAttribute = false
  isShowGenericReport = false

  selectedGenericTemplate = false
  projectGenericTemplates = []
  listModelProjectLink = [];
  listModelProject = []
  listProjectLink = []

  showProjectLinkDrawer = false;
  tileLinkViews = []
  listAllModel3DSLink = [];
  clickModelLink = false;
  isEditSessionVisibleData = false;
  featuresHelpList = null
  currentHelpfeature = ['all']
  showUserGroupDrawer = false

  showModalProcessingModel3D = false;

  reRenderModel = false;
  i3sViews = []
  i3sViewsLink = []
  listObjectHide = {}
  isRerenderFeatureTile = false
  isChangeLogoXDTwin = false
  previousCameraPosition = [];
  isDownloadingModel3d = [];
  pastLocations = []
  isMovingCamera = true;
  cameraLoadFinish = false;
  cameraHome = false;
  isEditModel = false
  showLighting= false;
  showDrawerCalculation= false;
  isAnyInputFocused= false;
  rerenderWMS= false;
  previous3DViewSetting = false
  WMSOrders = []
  msaaSamples = 4;
  fxaa = true;
  tilesLoaded=[]
  loginfo = false;
  isLogInfoLoading = false;
  showLogInfoProjectDrawer = false;

  setShowLogInfoProjectDrawer = (data) => {
    this.showLogInfoProjectDrawer = data
  }

  setIsLogInfoLoading = (data) => {
    this.isLogInfoLoading = data
  }

  setTilesLoaded = (data)=>{
    this.tilesLoaded = data
  }

  setFXAA = (data) => {
    this.fxaa = data;
  }

  setMSAASamples = (data) => {
    this.msaaSamples = data;
  };

  setWMSOrders = data => {
    this.WMSOrders = data
  }

  setPrevious3DViewSetting = show => {
    this.previous3DViewSetting = show
  }

  setShowDrawerCalculation = show => {
    this.showDrawerCalculation = show
  }

  setRerenderWMS = (stt) => {
    this.rerenderWMS = stt;
  }

  setIsAnyInputFocused = (stt) => {
    this.isAnyInputFocused = stt;
  }

  setShowLighting = (stt) => {
    this.showLighting = stt;
  }

  setIsEditModel = (data) => {
    this.isEditModel = data;
  }

  setCameraHome = (data) => {
    this.cameraHome = data;
  }

  setCameraLoadFinish = (stt) => {
    this.cameraLoadFinish = stt;
  }

  setMovingCamera = (stt) => {
    this.isMovingCamera = stt;
  }

  setIsDownloadingModel3d = (payload) => {
    this.isDownloadingModel3d = payload;
  }

  addPastLocations = (data) => {
    if (!data) {
      this.pastLocations = []
    } else {
      const maxPastLocations = 10;
      this.pastLocations.push(toJS(data))
      // Keep only the last maxPastLocations locations
      if (this.pastLocations.length > maxPastLocations) {
        this.pastLocations.shift();
      }
    }
  }

  setChangeLogoXDTwin = (data) => {
    this.isChangeLogoXDTwin = data
  }

  setPreviousCameraPosition = (payload) => {
    this.previousCameraPosition = payload;
  }

  setIsRerenderFeatureTile = (data) => {
    this.isRerenderFeatureTile = data
  }

  setListObjectHide = (data) => {
    this.listObjectHide = data
  }
  listObjectColor = {}
  setListObjectColor = (data) => {
    this.listObjectColor = data
  }
  isSocketOpenProjectError = false

  setIsSocketOpenProjectError = (data) => {
    this.isSocketOpenProjectError = data
  }

  setI3sViews = data => {
    this.i3sViews = data;
  };

  setI3sViewsLink = data => {
    this.i3sViewsLink = data;
  };

  setReRenderModel = status => {
    this.reRenderModel = status;
  };

  setShowModalProcessingModel3D = status => {
    this.showModalProcessingModel3D = status
  }
  setShowUserGroupDrawer = status => {
    this.showUserGroupDrawer = status
  }

  setCurrentHelpfeature = (data, mode) => {
    if (mode === 0 || !mode) {
      const queue = (arr, newElement) => {
        if (!arr?.length) return
        let index = arr.findIndex(el => el === newElement)
        if (index === -1) {
          arr.push(newElement)
          return arr
        }
        arr.splice(index, 1);
        arr.push(newElement)
        return arr
      }
      queue(this.currentHelpfeature, data)
    } else if (mode === 1) {
      this.currentHelpfeature = this.currentHelpfeature.filter(el => el !== data)
    }
  }

  setFeaturesHelpList = (data) => {
    this.featuresHelpList = data
  }

  clearFeaturesHelpList = () => {
    this.featuresHelpList = null
  }

  setIsEditSessionVisibleData = (data) => {
    this.isEditSessionVisibleData = data
  }
  setClickModelLink = (data) => {
    this.clickModelLink = data
  }
  setListAllModel3DSLink(panelName) {
    this.listAllModel3DSLink = panelName
  }
  setListProjectLink = data => {
    this.listProjectLink = data;
  }
  setListModelProjectLink = data => {
    this.listModelProjectLink = data;
  }
  setListModelProject = data => {
    this.listModelProject = data;
  }
  setShowProjectLinkDrawer = data => {
    this.showProjectLinkDrawer = data;
  }
  setTileLinkViews = data => {
    this.tileLinkViews = data
  }
  async getListModelProject(projectId) {
    this.setLoadingProgress(true)
    return new Promise((resolve, reject) => {
      ProjectLinkRequest.getListModelProject(projectId).then((response) => {
        let listModel = response.data.filter(m => !m.isDeleted)
        this.setListModelProject(listModel)
        this.setLoadingProgress(false)
        resolve(response.data);
      }).catch(err => {
        this.setLoadingProgress(false)
        reject(err)
      })
    })
  }

  async getListProjectLink(projectId) {
    this.setLoadingProgress(true)
    return new Promise((resolve, reject) => {
      ProjectLinkRequest.getListProjectLink(projectId)
        .then(response => {
          let allModels = []
          let projectLinks = response.data?.map(item => {
            let models = item.model3ds ? item.model3ds.map(m => {
              return {
                ...m,
                link: {
                  id: item?.link?.id,
                  name: item?.link?.name
                },
                isVisibleClip: true,
                isVisible: true,
                isVisible4D: true
              }
            }) : []
            let userVisibles = item?.visibleData || []
            let u = userVisibles.find(x => x.userId === usersStore.currentUser?._id);
            if (u) {
              item.isVisibleClip = u.isVisibleClip
              item.isVisible = u.isVisible
              item.isVisible4D = u.isVisible4D
              models.map(c => {
                allModels.push({
                  ...c,
                  isVisibleClip: u.isVisibleClip,
                  isVisible: u.isVisible,
                  isVisible4D: u.isVisible4D
                })
              })
            } else {
              models.map(c => allModels.push(c))
            }
            return item;
          })
          this.setListAllModel3DSLink(allModels)
          this.setListProjectLink(projectLinks)
          this.setLoadingProgress(false)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          this.setLoadingProgress(false)
          reject(error)
        })
    })
  }

  async createProjectLink(projectData) {
    return new Promise((resolve, reject) => {
      ProjectLinkRequest.createProjectLink(projectData)
        .then(response => {
          const _project = response.data;
          this.setListProjectLink([
            ...this.listProjectLink,
            {
              ..._project,
              link: {
                id: _project?.link?.id,
                name: _project?.link?.name
              },
              isVisibleClip: true,
              isVisible: true,
              isVisible4D: true
            }])
          if (_project?.model3ds?.length) {
            _project?.model3ds.map(c => {
              this.listAllModel3DSLink.push({
                ...c,
                isVisibleClip: true,
                isVisible: true,
                isVisible4D: true
              })
            })
          }
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  async deleteProjectLink(projectId) {
    return new Promise((resolve, reject) => {
      ProjectLinkRequest.deleteProjectLink(projectId)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }
  async updateProjectLinkVisible(id, projectData) {
    return new Promise((resolve, reject) => {
      ProjectLinkRequest.updateProjectLink(id, projectData)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }
  async updateProjectLink(id, projectData) {
    this.setLoadingProgress(true)
    return new Promise((resolve, reject) => {
      ProjectLinkRequest.updateProjectLink(id, projectData)
        .then(response => {
          const foundIndex = this.listProjectLink.findIndex(x => x.id === response?.data?.id)
          const temp = [...this.listProjectLink]
          temp[foundIndex] = response.data
          let listModel = []
          temp.map(item => {
            let modelData = item.model3ds.map(model => {
              return {
                ...model,
                link: item.link
              }
            })
            listModel = [...listModel, ...modelData]
          })
          let projectLinks = temp?.map(item => {
            if (item.visibleData?.length > 0) {
              let visibleData = item.visibleData.find(x => x.userId === usersStore.currentUser?.id);
              if (visibleData) {
                item.isVisibleClip = visibleData.isVisibleClip
                item.isVisible = visibleData.isVisible
                item.isVisible4D = visibleData.isVisible4D
              }
            }
            return item;
          })
          this.setListAllModel3DSLink(listModel)
          this.setListProjectLink(projectLinks)
          this.setLoadingProgress(false)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          this.setLoadingProgress(false)
          reject(error)
        })
    })
  }

  stateBeforeRebuild = {}

  setStateBeforeRebuild = data => {
    this.stateBeforeRebuild = data
  }

  setSelectedGenericTemplate = data => {
    this.selectedGenericTemplate = data
  }

  setProjectGenericTemplates = data => {
    this.projectGenericTemplates = data
  }

  setShowGenericReport = stt => {
    this.isShowGenericReport = stt
  }

  setIsEditingModelAttribute = stt => {
    this.isEditingModelAttribute = stt
  }

  setIsEditingObjectAttribute = stt => {
    this.isEditingObjectAttribute = stt
  }

  setVisibleModalObjectAtt = data => {
    this.visibleModalObjectAtt = data
  }


  setCurrentObjectAttribute = data => {
    this.currentObjectAttribute = data
  }

  setVisibleModalProjectAtt = data => {
    this.visibleModalProjectAtt = data
  }


  setCurrentProjectAttribute = data => {
    this.currentProjectAttribute = data
  }

  setShowCustomAttributeDrawer = status => {
    this.showCustomAttributeDrawer = status
  }

  changeAttributesTab = tab => {
    this.attributesTab = tab
  }

  setIsExistLicenses = status => {
    this.isExistLicenses = status
  }

  setShowDuplicateProjectModal = status => {
    this.showDuplicateProjectModal = status
  }

  setExpirationLicenses = data => {
    this.expirationLicenses = data
  }

  setShowDialogExpirationLicense = data => {
    this.showDialogExpirationLicense = data
  }

  setReloadAlignment = statu => {
    this.reloadAlignment = statu
  }

  setUploadNewStartImage = stt => {
    this.uploadNewStartImage = stt
  }

  setTypeViewPoint = type => {
    this.typeViewPoint = type
  }


  // set clipping viewpoint data
  setClippingViewPoint = type => {
    this.clippingViewPoint = type
  }

  setMaxCountOfVisibleTiles = data => {
    this.maxCountOfVisibleTiles = data
  }
  maximumAttenuation = 5
  setMaximumAttenuation = data => {
    this.maximumAttenuation = data
  }
  eyeDomeLightingStrength = 1
  setEyeDomeLightingStrength = data => {
    this.eyeDomeLightingStrength = data
  }
  eyeDomeLightingRadius = 1
  setEyeDomeLightingRadius = data => {
    this.eyeDomeLightingRadius = data
  }
  geometricErrorScale = 1
  setGeometricErrorScale = data => {
    this.geometricErrorScale = data
  }

  setModelHiddenBeforeOpenViewPoint = data => {
    this.modelHiddenBeforeOpenViewPoint = data
  }

  setTargetViewPoint = () => {
    this.targetViewPoint = this.targetViewPoint + 1
  }

  clearTargetViewPoint = () => {
    this.targetViewPoint = 0;
  }

  setCurrentViewpoint = data => {
    this.currentViewpoint = data
  }

  setSelectedStatus = status => {
    this.selectedStatus = status
  }

  setSelectedPreDefineAcess = data => {
    this.selectedPreDefineAcess = data
  }

  setShowBackgroundSetting = status => {
    this.showBackgroundSetting = status
  }

  setMaximumScreenSpaceError = data => {
    this.maximumScreenSpaceError = data
  }
  setgmlMaximumScreenSpaceError = data => {
    this.gmlmaximumScreenSpaceError = data
  }

  setShadowDarkness = data => {
    this.shadowDarkness = data
  }
  setShadowDistance = data => {
    this.shadowDistance = data
  }
  setShadowAccuracy = data => {
    this.shadowAccuracy = data
  }

  setSoftShadows = data => {
    this.softShadows = data
  }

  setTileViews = data => {
    this.tileViews = data
  }
  setAlignment = data => {
    this.Alignment = data
  }
  setPickPosition = value => {
    this.pickPosition = value
  }

  setShowFeatureSettingDrawer = value => {
    this.showFeatureSettingDrawer = value
  }

  obtAbsoluteDeviceSensor = undefined
  setobtAbsoluteDeviceSensor = (value) => {
    this.obtAbsoluteDeviceSensor = value
  }
  obtRelativeDeviceSensor = undefined
  setobtRelativeDeviceSensor = (value) => {
    this.obtRelativeDeviceSensor = value
  }
  setWebglContextAlpha = value => {
    this.webglContextAlpha = value
  }
  /**
   * Set navagation style 
   * @param {*} x type object
   */
  setNavigationStyles = x => {
    this.navigationStyles = x
  }

  clearNavigationStyles = () => {
    this.navigationStyles = false
  }

  setShowUpdateResourceModel = x => {
    this.showUpdateResourceModel = x;
  }

  setDataAttributeModal = (state) => {
    this.dataAttributeModal = state
  }

  setHideFeatures = x => {
    this.hideFeatures = x;
  }
  setChangeModelStyle = () => {
    this.changeModelStyle = this.changeModelStyle + 1;
  }
  setLoadingAttrData = x => {
    this.loadingAttrData = x;
  }

  setLoadedModelTreeData = x => {
    this.loadedModelTreeData = x;
  }

  setShowProcessInBackground(status) {
    this.showProcessInBackground = status
  }

  setCheckModalStatus(status) {
    this.checkModalStatus = status
  }

  setSelectedFeature = x => {
    this.selectedFeature = x;
  }

  setSelectedAttrData = x => {
    this.selectedAttrData = x;
  }

  setModelFeatures = x => {
    this.modelFeatures = x;
  }
  updateModelFeatures = (modelId, modelFeatures, key) => {
    if (!this.modelFeatures?.[modelId]) {
      this.modelFeatures[modelId] = {}
    }
    if (modelFeatures[key]) {
      this.modelFeatures[modelId][key] = modelFeatures[key]
    } else {
    }
  }
  setAlignmentFeatures = x => {
    this.alignmentFeatures = x;
  }

  setReCenterGPS = b => {
    this.reCenterGPS = b;
  }

  setEnableReCenterGPS = b => {
    this.enableReCenterGPS = b;
  }

  setDropDownMenuCameraSettings = b => {
    this.dropDownMenuCameraSettings = b;
  }


  setStartRecodData = b => {
    this.startRecordData = b;
  }
  setArToolTip = t => {
    this.arToolTip = t;
  }
  setRenderRecordDataControl = b => {
    this.renderRecordDataControl = b
  }
  setMasking = b => {
    this.isMasking = b
  }
  setSensorFrequences = b => {
    if (!isNaN(b))
      this.sensorFrequences = Number(b);
  }
  setSampleRange = b => {
    if (b) {
      this.maHead = new MA(b, 60)
      this.filterHead = new LPF(b)
      this.sampleRange = b
    }
  }
  setSmoothFilterType = b => {
    this.smoothFilterType = b;
  }
  compassMode = 'a'
  setCompassMode = b => {
    this.compassMode = b;
  }
  setRecordData = data => {
    Object.assign(this.recordData, data)
  }

  setShowRenderResolutionControl = b => {
    this.renderResolutionControl = b
  }

  setShowARCamera = b => {
    this.isShowARCamera = b
  }

  setCombineBoundingSpheres = b => {
    this.combineBoundingSpheres = b
  }

  setVisitedMode(visitedMode) {
    this.visitedMode = visitedMode
  }

  setProjectDetailError(error) {
    this.projectDetailError = error
  }

  setHiddenTileSelected = x => {
    this.hiddenTileSelected = x
  }
  setHiddenCityGmlSelected = x => {
    this.hiddenCityGmlSelected = x
  }

  setShowHiddenTilesList = show => {
    this.showHiddenTilesList = show
  }

  setShowHiddenDrawer = show => {
    this.showHiddenDrawer = show
  }

  setShowHiddenAreaTab = show => {
    this.showHiddenAreaTab = show
  }

  showDrawerConnectNavigation = false

  setShowDrawerConnectNavigation = show => {
    this.showDrawerConnectNavigation = show
  }

  // opencvCalcAngle = id => {
  //   this.openCVData.id = id
  //   return new Promise((resolve, reject) => {
  //     ProjectRequest.getAngleFromArTest(id)
  //       .then(response => {
  //         if (response.data.angle) {
  //           // this.openCVData.angle = response.data.angle
  //           // console.log('response.data', response.data)
  //           resolve(response.data.angle)
  //         } else resolve(999)
  //       })
  //       .catch(error => {
  //         resolve(999)
  //       })
  //   })
  // }
  setOpenCVData = data => {
    Object.assign(this.openCVData, data)
  }
  setAbsoluteHPRData = data => {
    this.absoluteHPRData = data
  }
  set2D = b => {
    this.is2D = b
    projectSettingStore.set2D(b)
  }

  setShowFov = show => {
    this.showFov = show
  }

  setShowAmbientOccSetting = show => {
    this.showAmbientOcclusionSetting = show
  }

  setCleanMode = b => {
    this.cleanMode = b
  }

  setZoomToModel = modelId => {
    this.zoomToModel = modelId
  }

  setSendARInfo = p => {
    this.isSendARInfo = p
  }

  setVisibleTilesets = p => {
    this.visibleTilesets = p
  }

  setVisible4DFolders = p => {
    this.visible4DFolders = p
  }

  setSkyColor = ort => {
    this.skyColor = ort
  }
  setObtDeviceSensor = ort => {
    this.obtDeviceSensor = ort
  }
  setWorldTerrain = ort => {
    this.WorldTerrain = ort
  }
  setGeoWatch = ort => {
    this.geoWatch = ort
  }

  setCurrentGPS = ort => {
    this.currentGPS = ort
  }

  setClippingMode = mode => {
    this.clippingMode = mode //model: vertical; horizoltal; alignment; false
  }
  setClippingPickDone = value => {
    this.clippingPickDone = value
  }

  setCurrentTile = tile => {
    this.currentTile = tile
  }

  setModelEditPos = (pos, isFromViewer) => {
    // if (!isFromViewer) isFromViewer=false
    if (!pos) {
      this.modelEditPos = false
      return
    }
    Object.assign(this.modelEditPos, pos)
  }

  setShowMap = show => {
    this.showMap = show
  }

  setGlobeBehind = show => {
    this.globeBehind = show
  }

  setShowAntialiasing = show => {
    this.showAntialiasing = show
  }
  setShowRefPoint = show => {
    this.showRefPoint = show
  }
  setShowHUD = show => {
    this.showHUD = show
  }

  setShowAmbientOcclusion = show => {
    this.showAmbientOcclusion = show
  }
  setShowInspectorTool = show => {
    this.showInspectorTool = show
  }
  setObtGPSElevation = (height, lat, lon, p) => {
    this.obtGPSHeight = { height: height, lat: lat, lon, lon, p: p }
  }
  calculateGPSDistance(p1, p2) {
    try {
      var startCartesian3Point = Cartesian3.fromDegrees(p1.lon, p1.lat);
      var endCartesian3Point = Cartesian3.fromDegrees(p2.lon, p2.lat);

      var startCartographicPoint = Cartographic.fromCartesian(startCartesian3Point);
      var endCartographicPoint = Cartographic.fromCartesian(endCartesian3Point);

      var ellipsoidGeodesic = new EllipsoidGeodesic(startCartographicPoint,
        endCartographicPoint);
      var distance = ellipsoidGeodesic.surfaceDistance;
      return distance
    } catch (error) {
      return 2
    }
  }
  // lowPass(prev, curr, co) {
  //   return prev * co + curr * (1 - co);
  // }
  setObtGPSOrientation = ort => {
    this.obtGPSOrientation = ort
    if (ort.heading) {
      let heading = ort.heading;
      let pitch = ort.pitch;
      let roll = ort.roll;
      if (this.smoothFilterType === 'ma') {
        heading = this.maHead.next(ort.heading, 'heading');
        pitch = this.maPitch.next(ort.pitch, 'pitch')
        roll = this.maRoll.next(ort.roll, 'role')
      }
      if (this.smoothFilterType === 'lp') {
        heading = this.filterHead.next(ort.heading, 'heading')
        pitch = this.filterPitch.next(ort.pitch, 'pitch')
        roll = this.filterRoll.next(ort.roll, 'role')
      }
      // let heading = ort.heading
      // if (!(ort.heading < -3 || ort.heading > 3)) { 
      if (this.smoothFilterType === 'kf') {
        heading = this.kfHead.filter(ort.heading)
        // }
        pitch = this.kfPitch.filter(ort.pitch)
        roll = this.kfRoll.filter(ort.roll)
      }
      this.averageOrientation = ort
      if (true || this.filterRoll.count > 1) {
        this.averageOrientation.heading = heading
        this.averageOrientation.pitch = pitch
        this.averageOrientation.roll = roll
      }
    } else {
      this.obtGPSOrientations = []
      this.averageOrientation = {}
      this.filterHead = new LPF(0.5)
      this.filterPitch = new LPF(0.8)
      this.filterRoll = new LPF(0.8)

      this.maHead = new MA(this.sampleRange, 60)
      this.maPitch = new MA(this.sampleRange, 30)
      this.maRoll = new MA(this.sampleRange, 30)
    }
  }

  setGpsMode = mode => {
    this.gpsMode = mode
  }

  setCurrentInitLocation = loc => {
    this.currentInitLocation = loc
  }

  setViewMode = mode => {
    this.viewMode = mode
  }

  setShowBoudingVolume(b) {
    this.showBoudingVolume = b
  }
  updateProjectCover = val => {
    if (!this.projectDetail['thumbnail']) {
      this.projectDetail['thumbnail'] = {
        base64: val,
      }
      return
    }
    this.projectDetail['thumbnail']['base64'] = val
  }

  setCurrentModelId(id) {
    this.currentModelId = id
  }

  clearProjectList = () => {
    this.projectList = []
  }

  clearProjectDetail = () => {
    this.projectDetail = {}
  }

  deleteProject = projectId => {
    return new Promise((resolve, reject) => {
      ProjectRequest.deleteProject(projectId)
        .then((res) => {
          resolve(res)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  setDeleteIds = (Ids) => {
    this.deleteIds = Ids
  }

  setShowEditLocation(model, b) {
    // console.log('model', toJS(model))
    // this.currentEditModel = model
    if (model) {
      if (!model.crs) model.crs = {}
      if (!model.crs.savePos) {
        model.crs.savePos = {
          lat: 0,
          lng: 0,
          height: 0,
          heading: 0,
          pitch: 0,
          roll: 0,
          scale: 1,
        }
      }
      this.modelEditPos = model.crs.savePos
    } else this.modelEditPos = false

    // var modelLocation = model
    // if (modelLocation) {
    //   if (!modelLocation.crs) modelLocation.crs = {}
    //   // if (!modelLocation.crs.transform) {
    //   if (!modelLocation.crs.initPos) {
    //     modelLocation.crs.transform = {
    //       lat: 0,
    //       lng: 0,
    //       height: 0,
    //       heading: 0,
    //       pitch: 0,
    //       roll: 0,
    //       scale: 1,
    //     }
    //     modelLocation.crs.initPos = modelLocation.crs.transform
    //   } else {
    //     modelLocation.crs.transform = {
    //       lat: modelLocation.crs.initPos.cartographic.latitude * CesiumMath.DEGREES_PER_RADIAN,
    //       lng: modelLocation.crs.initPos.cartographic.longitude * CesiumMath.DEGREES_PER_RADIAN,
    //       height: 0,
    //       heading: 0,
    //       pitch: 0,
    //       roll: 0,
    //       scale: 1,
    //     }
    //   }
    //   Object.assign(modelLocation.crs.initPos, {
    //     height: 0,
    //     heading: 0,
    //     pitch: 0,
    //     roll: 0,
    //     scale: 1,
    //   })
    //   this.modelEditPos = modelLocation.crs.transform
    // } else this.modelEditPos = false
    this.showEditLocation = b
  }

  hideEditLocation = () => {
    this.showEditLocation = false
  }

  displayEditLocation = () => {
    this.showEditLocation = true
  }

  setZoomClick(b) {
    this.zoomClick = b
  }

  setSelectedModel(b) {
    this.selectedModel = b
  }

  setShowAddProjectModal(show) {
    this.showAddProjectModal = show
  }

  setDisplayPanel(panelName) {
    this.displayPanel = panelName
  }

  setShowAddResourceModel = state => {
    this.showAddResourceModel = state
  }

  setCurrentAddTab(tabName) {
    this.currentAddTab = tabName
  }

  setProjects(p) {
    this.projectList = p
  }

  setModelList(m) {
    // this.modelList.replace(m)
    this.modelList = m
  }

  setProjectDetail(p) {
    this.projectDetail = p
  }

  async deleteTileset(tilesetId, treeNodeKey = undefined) {
    return new Promise((resolve, reject) => {
      ProjectRequest.deleteTileset(tilesetId, treeNodeKey)
        .then((res) => {
          this.setModelList(this.modelList.filter(t => t._id !== tilesetId))
          this.setDeleteIds([tilesetId])
          resolve(res.data)
        })
        .catch(error => {
          console.log(error)
          reject()
        })
    })
  }

  setLoadingProgress = state => {
    this.isLoading = state
  }

  getAllProjects = () => {
    const getInviteStatus = (data) => {
      switch (data) {
        case 'accepted':
          return 6
        case 'waiting_accept':
          return 5
        case 'rejected':
          return 4
        case 'user_not_exist':
          return 3
        default:
          return 2;
      }
    }
    return new Promise((resolve, reject) => {
      ProjectRequest.getAllProjects()
        .then(response => {
          let _x = response.data.filter(x => x.project)
          let projectListData = [..._x]
          projectListData.map(project => {
            for (var i = 0; i < _x.length; i++) {
              let _project = _x[i]
              if (project?._id !== _project?._id) {
                if (project?.project?._id === _project?.project?._id) {
                  if (getInviteStatus(project.inviteStatus) > getInviteStatus(_project.inviteStatus)) {
                    _x = _x.filter(x => x._id !== _project._id)
                    break
                  } else {
                    _x = _x.filter(x => x._id !== project._id)
                    break
                  }
                }
              }
            }
          })
          this.projectList = _x
          resolve()
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  getTotalProjects = () => {
    this.setLoadingProgress(true)
    return new Promise((resolve, reject) => {
      ProjectRequest.getTotalProjects()
        .then(response => {
          this.totalProject = response.data
          this.setLoadingProgress(false)
          resolve()
        })
        .catch(error => {
          console.log(error)
          this.setLoadingProgress(false)
          reject()
        })
    })
  }

  setCurrentProjectDetail = (payload) => {
    this.currentProjectDetail = payload;
  }

  getProjectDetail = async (id, notGetModels) => {
    return new Promise((resolve, reject) => {
      ProjectRequest.getDetail(id)
        .then(response => {
          this.setMaximumScreenSpaceError(response.data?.metadata?.renderResolution?.maximumScreenSpaceError || 16)
          this.setgmlMaximumScreenSpaceError(response.data?.metadata?.renderResolution?.gmlmaximumScreenSpaceError || 100)
          this.setMaximumAttenuation(response.data?.metadata?.renderResolution?.maximumAttenuation || 5)
          this.setGeometricErrorScale(response.data?.metadata?.renderResolution?.geometricErrorScale || 1)
          this.setMaxCountOfVisibleTiles(response.data?.metadata?.renderResolution?.maxCountOfVisibleTiles || 200)
          // this.setShadowDarkness(response.data?.metadata?.renderResolution?.shadowDarkness || 0.5)
          // this.setShadowDistance(response.data?.metadata?.renderResolution?.shadowDistance || 1000)
          // this.setShadowAccuracy(response.data?.metadata?.renderResolution?.shadowAccuracy || 4096)
          // this.setSoftShadows(response.data?.metadata?.renderResolution?.softShadows || false)
          this.setProjectDetail(response.data)
          this.setProjectStorage(parseFloat(response.data?.storage.toFixed(3)) || 0)
          if (response.data.tilesetData) {
            if (response.data.tilesetData.hiddenTile) {
              this.setHiddenTileSelected(response.data.tilesetData.hiddenTile)
            }
            if (response.data.tilesetData.hiddenCityGmlObject) {
              this.setHiddenCityGmlSelected(response.data.tilesetData.hiddenCityGmlObject)
            }
          }
          if (response.data.model3DS && !notGetModels) {
            response.data.model3DS.forEach(m => {
              m.ref = false
            })
            this.setModelList(response.data.model3DS)
          }
          if (response.data.viewpoints)
            capturesStore.setCaptureList(response.data.viewpoints)

          if (response.data.organization)
            organizationStore.setCurrentOrganizations(response.data.organization)
          resolve(response)
        })
        .catch(error => {
          console.log(error);
          this.setProjectDetailError(error)
          reject(error)
        })
    })

  }
  getCurrentProjectStorageQuota(organization) {
    const licenses = organization.licenses
    if (licenses.length > 0) {
      let listLicenses = []
      listLicenses = checkAvaiableLicenses(licenses)

      if (listLicenses.length > 0) {
        let totalQuota = 0
        listLicenses.map(item => {
          totalQuota += item.storageQuota || 0
        })
        return {
          totalQuota: totalQuota,
          listLicenses: listLicenses
        }
      } else {
        return {
          totalQuota: 0,
          listLicenses: []
        }
      }
    }
    return {
      totalQuota: 0,
      listLicenses: []
    };
  }
  createProject(projectData) {
    this.setLoadingProgress(true)
    let param = {
      tilesetData: {},
      metadata: {},
    }
    param = Object.assign(param, projectData)
    return new Promise((resolve, reject) => {
      ProjectRequest.createProject(param)
        .then(response => {
          this.projectList.push(response.data)
          this.setLoadingProgress(false)
          this.setShowAddProjectModal(false)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          this.setLoadingProgress(false)
          this.setShowAddProjectModal(false)
          reject(error)
        })
    })
  }
  async createProjectTemplate(projectId, data) {
    return new Promise((resolve, reject) => {
      ProjectRequest.createProjectTemplate(projectId, data).then((response) => {
        resolve(response.data);
      }).catch(err => {
        this.setLoadingProgress(false)
        console.log(err)
        message.error(t('an-error-occurred'))
        reject(err)
      })
    })
  }



  setNewModelId = id => {
    this.newModelId = id
    // const index = this.modelList.findIndex(x => x.id === id)
  }

  createModel = async (modelData) => {
    return new Promise((resolve, reject) => {
      ProjectRequest.createModel(modelData)
        .then(info => {
          if (!info.data) reject(false)
          resolve(info.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  async updateVersion(id, modelData) {
    return new Promise((resolve, reject) => {
      ProjectRequest.updateVersion(id, modelData)
        .then(async info => {
          if (!info.data) reject(false)
          resolve(info.data)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  async updateIFCEllipsoid(id, modelData) {
    return new Promise((resolve, reject) => {
      ProjectRequest.updateIFCEllipsoid(id, modelData)
        .then(async info => {
          if (!info.data) reject(false)
          resolve(info.data)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  async updateLocation(id, locationData, isUpdatePublicLink) {
    return ProjectRequest.updateModel(id, locationData)
      //.then(res => resolve(res))
      .then(async info => {

        let modelN = info.data
        if (modelN.sourceType === 'local')
          if (modelN.type === 'ifc' || modelN.type == 'landxml' || modelN.type == 'landxmlBackground' || modelN.type === 'cad') {
            if (isUpdatePublicLink)
              await processPublicLink(info.data);
            let uri = (modelN?.api_ver === 3) ? '/ModelTileset.json' : '/tileset.json'
            reloadUrl(assetUrl + modelN.hash + uri)
          }

        this.setCurrentModelId(false)
        const index = this.modelList.findIndex(x => x._id === modelN._id)
        const temp1 = [...this.modelList]
        temp1.splice(index, 1)
        this.setModelList(temp1)
        // this.setDeleteIds([modelN._id])

        modelN.ref = false;
        modelN.isUpdate = true;
        temp1.map(item => {
          delete item.isUpdate
          return item
        })

        temp1.splice(index, 0, modelN)
        this.setModelList(temp1)
        // this.setNewModelId(modelN._id)
        this.setSelectedModel(
          this.modelList[this.modelList.length - 1]
        )

        //check if when update model has project 
        if (locationData.updateproject) {
          this.setCurrentModelId(false)
          this.setNewModelId(modelN.id) //for remerge all model after project new refpoint
          if (modelN.data?.saveMatrix?.xyzLocal && modelN.data.ext && !['.png', '.jpg', '.jpeg', '.pdf', '.doc', '.docx', '.ppt', '.pptx', '.xls', '.xlsx', '.txt'].includes(modelN.data.ext.toLowerCase()) && modelN.type !== 'landxmlBackground' && !isIFCEllipsoid(modelN) && !isCloudPointXDEngineEllipsoid(modelN) && !isCloudPointCesiumION(modelN) && !isE57XDEngineEllipsoid(modelN)) {
            await this.updateProjectRefPoint(modelN.project)
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  update3DMODELS(id, body) {
    return new Promise((resolve, reject) => {
      return ProjectRequest.updateModel(id, body)
        .then(res => resolve(res))
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  acceptProjectInvitation = (projectId, submitValues) => {
    this.setLoadingProgress(true)
    ProjectRequest.acceptProjectInvitation(projectId, submitValues)
      .then(() => {
        this.getAllProjects().then(() => {
          message.success(t('project-invitation-accepted'))
          this.setLoadingProgress(false)
        })
      })
      .catch(error => {
        console.log(error)
        this.setLoadingProgress(false)
      })
  }

  toggleProjectInfoDrawer = state => {
    this.showProjectInfoDrawer = state
  }
  getSearchProjection = value => {
    return ProjectRequest.searchProjection(value)
      .then(response => {
        this.projectionList = response.data.results.map(item => ({
          code: item.id.code.toString(),
          name: item.name,
          unit: item.unit,
          proj4: item.exports.proj4,
          wkt: item.exports.wkt // Assuming 'wkt' was intended to be 'item.exports.wkt'
        }));
      })
      .catch(error => {
        console.error("Error fetching search projection:", error);
      });
  }
  setProjectionDetail = p => {
    this.projectionDetail = p
  }

  setEpsgCodeList = epsp => {
    this.epsgCodeList = epsp
  }

  async sendARInfo(data) {
    this.setLoadingProgress(true)
    return ProjectRequest.sendARCaptureInfo(data)
      .then(response => {
        console.log('response AR', response)
        if (response.data.id)
          this.openCVData.id = response.data.id
        var calibration = response.data.modelRenderInfo.calibration
        this.openCVData.angle = -1000
        this.openCVData.vAngle = -1000
        if (calibration.Status === 'OK') {
          if (calibration.Angle[0] !== -1000) {
            this.openCVData.angle = calibration.Angle[0]
            this.openCVData.vAngle = calibration.Angle[1]
            this.openCVData.hc = -calibration.Angle[0]
            this.openCVData.vc = -calibration.Angle[1]
            this.openCVData.apply = true
            message.success(t('calibration-successfully'))
          } else {
            this.openCVData.apply = false
            message.error(t('calibration-fail-try-again'))
          }
        } else {
          message.error(t('calibration-fail-try-again'))
          this.openCVData.apply = false
        }
        // if (response.data.angle) {
        //   var angle = response.data.angle
        //   this.openCVData.apply = false
        //   if (angle !== 999) {
        //     if (this.openCVData.angle !== 999)
        //       angle += this.openCVData.angle
        //   }
        //   this.openCVData.angle = angle
        //   if (response.data.angle !== 999) {
        //     this.openCVData.apply = true
        //     message.success('Calibration successful.')
        //   } else {
        //     message.error('Calibration fail. Try again.')
        //   }
        // }
        this.setLoadingProgress(false)
      })
      .catch(error => {
        console.log(error)
        message.error(t('calibration-fail-try-again'))
        this.setLoadingProgress(false)
      })
  }



  findModelByUrl = (url, jsonFile) => {
    let out = this.modelList.find(function (model) {
      var tileUrl = false
      if (model.sourceType === 'external') {
        if (model.data.ionAssetId) {

          let checkIon = false
          var index = url?.indexOf(`/${model.data.ionAssetId}/`)
          if (index > -1) {
            checkIon = true
          }

          return checkIon
        } else {
          var tUrl = getModelUrBySrc(model.src)
          return url === tUrl
        }
      }
      switch (model.type) {
        case 'landxmlBackground':
          return url === assetUrl + model.hash + '/tileset.json'
        case 'ifc':
          if (isIFCEllipsoid(model)) {
            return url === assetUrl + model.hash + '/tileset.json'
          } else {
            tileUrl = assetUrl + model.hash
            if (jsonFile) tileUrl += '/' + jsonFile
            else
              tileUrl +=
                model.data?.api_ver === 3
                  ? '/ModelTileset.json'
                  : '/tileset.json'
            return url === tileUrl
          }
        case 'landxml':
        case 'external-plugin':
        case 'cad':
          tileUrl = assetUrl + model.hash
          if (jsonFile) tileUrl += '/' + jsonFile
          else
            tileUrl +=
              model.data?.api_ver === 3 ? '/ModelTileset.json' : '/tileset.json'
          return url === tileUrl
        case 'model3d':
        case 'kmz':
        case 'geotiff':
        case 'cloudpoint':
          if (!model.data.ionAssetId && !model.data?.srcTileset) return false
          if (model.data?.srcTileset) {
            return model.data?.srcTileset === url
          } else if (model.data.ionAssetId) {
            const index = url?.indexOf(`/${model.data.ionAssetId}/`)
            if (index > -1) {
              return true
            }
          }
          return false
        case 'e57':
          if (!model.data?.srcTileset) return false
          return model.data?.srcTileset === url
        default:
          break
      }

      // return item.id+'-tile' === tile.key
    })
    return out
  }

  findModelByUrlLink = (url, jsonFile) => {
    let out = this.listAllModel3DSLink.find(function (model) {
      var tileUrl = false
      if (model.sourceType === 'external') {
        if (model.data.ionAssetId) {
          var patt = /(http[s]?:\/\/)?([^\/\s]+\/)([^\/\s]+\/)(.*)/gm
          var result = patt.exec(url)
          if (result) {
            let checkIon = false
            var index = url?.indexOf(`/${model.data.ionAssetId}/`)
            if (index > -1) {
              checkIon = true
            }
            if (result[4] && !checkIon) {
              var assetId = result[4].split('/')[0]
              checkIon = assetId == model.data.ionAssetId
            }
            return checkIon
          }
          return false
        } else {
          var tUrl = getModelUrBySrc(model.src)
          return url === tUrl
        }
      }
      switch (model.type) {
        case 'landxmlBackground':
          return url === assetUrl + model.hash + '/tileset.json'
        case 'ifc':
          if (isIFCEllipsoid(model)) {
            return url === assetUrl + model.hash + '/tileset.json'
          } else {
            tileUrl = assetUrl + model.hash
            if (jsonFile) tileUrl += '/' + jsonFile
            else
              tileUrl +=
                model.data?.api_ver === 3
                  ? '/ModelTileset.json'
                  : '/tileset.json'
            return url === tileUrl
          }
        case 'landxml':
        case 'external-plugin':
        case 'cad':
          tileUrl = assetUrl + model.hash
          if (jsonFile) tileUrl += '/' + jsonFile
          else
            tileUrl +=
              model.data?.api_ver === 3 ? '/ModelTileset.json' : '/tileset.json'
          return url === tileUrl
        case 'model3d':
        case 'kmz':
        case 'geotiff':
        case 'cloudpoint':
          if (!model.data.ionAssetId && !model.data?.srcTileset) return false
          if (model.data.ionAssetId) {
            var index = url?.indexOf(`/${model.data.ionAssetId}/`)
            if (index > -1) {
              return true
            }
          } else if (model.data?.srcTileset) {
            return model.data?.srcTileset === url
          }
          return false
        case 'e57':
          if (!model.data?.srcTileset) return false
          return model.data?.srcTileset === url
        default:
          break
      }

      // return item.id+'-tile' === tile.key
    })
    return out
  }

  checkCityModelByUrl = url => {
    let out = this.modelList.find(function (model) {
      if (model.sourceType === 'external') {
        if (model.data.ionAssetId) {
          return false
        } else {
          return url === getModelUrBySrc(model.src)
        }
      }
    })
    return out
  }

  async updateProject(locationData) {
    try {
      this.setLoadingProgress(true);
      const response = await ProjectRequest.updateProject(this.projectDetail._id, locationData);

      if (locationData.store !== 'nodata') {
        const projectUserRole = response.data.projectuserroles.find(
          pur => pur.email === response.data.user.email && pur.inviteStatus === 'accepted'
        );

        response.data.isUserProjectMember = !!projectUserRole;
        this.setProjectDetail(response.data);

        if (response.data.tilesetData) {
          if (response.data.tilesetData.hiddenTile) {
            this.setHiddenTileSelected(response.data.tilesetData.hiddenTile);
          }
          if (response.data.tilesetData.hiddenCityGmlObject) {
            this.setHiddenCityGmlSelected(response.data.tilesetData.hiddenCityGmlObject);
          }
        }
      }

      this.setLoadingProgress(false);
    } catch (error) {
      console.log(error);
      this.setLoadingProgress(false);
    }

  }
  getCurrentProjectSetting = () => {
    let _meta = this.projectDetail?.metadata?.projectSetting
    let _ps = projectSettingStore.defaultSystemProjectSetting
    let _metadata = toJS(this.projectDetail?.metadata);
    if (_meta && _meta.length > 0 && usersStore.currentUser._id) {
      let _current = _meta.find(elm => elm.userid === usersStore.currentUser._id)
      if (_current) {
        _ps = _current.projectSetting
        projectSettingStore.setSystemProjectSetting(_ps)
      }
    }
    if (_metadata?.ifcSetting) {
      _ps.ifcSetting = _metadata.ifcSetting
    }
    if (_metadata?.sensorSetting) {
      _ps.sensorFrequences = _metadata.sensorSetting?.sensorFrequences
      _ps.sampleRange = _metadata.sensorSetting?.sampleRange
      _ps.smoothFilterType = _metadata.sensorSetting?.smoothFilterType
    }
    return _ps;
  }
  async updateProjectData(locationData) {
    return new Promise((resolve, reject) => {
      if (this.projectDetail?._id) {
        //locationData.store = 'project'
        if (!locationData.store) {
          locationData.store = 'metadata'
        }
        ProjectRequest.updateProject(this.projectDetail._id, locationData)
          .then((response) => {
            if (response.data?.metadata) {
              this.projectDetail.metadata = response.data.metadata
            }
            resolve(response.data)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      }
      else reject()
    })
  }

  async updateProjectMetadata(locationData) {
    return new Promise((resolve, reject) => {
      if (this.projectDetail?._id) {
        if (!locationData.store) {
          locationData.store = 'metadata'
        }
        ProjectRequest.updateProjectMetadata(this.projectDetail._id, locationData)
          .then((response) => {
            if (response.data?.metadata) {
              this.projectDetail.metadata = response.data.metadata
            }
            resolve(response.data)
          })
          .catch(error => {
            // console.log(error)
            reject(error)
          })
      }
      else reject()
    })
  }

  async updateProjectTreeData(treeData) {
    return new Promise((resolve, reject) => {
      if (this.projectDetail?._id) {
        ProjectRequest.updateProjectTreeData(this.projectDetail._id, treeData)
          .then((response) => {
            if (response.data?.treeData) {
              this.projectDetail.treeData = response.data.treeData
            }
            resolve(response.data)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      }
      else reject()
    })
  }

  async updateProjectImages(data) {
    return new Promise((resolve, reject) => {
      if (this.projectDetail?._id) {
        ProjectRequest.updateProject(this.projectDetail._id, data)
          .then((response) => {
            this.setProjectDetail({ ...this.projectDetail, data })
            resolve(response.data)
          })
          .catch(err => {
            reject(err);
          })
      }
      else reject();
    })
  }

  async updateProjectSetting(data) {
    return new Promise((resolve, reject) => {
      if (this.projectDetail?._id) {
        ProjectRequest.updateProjectSetting(this.projectDetail._id, data)
          .then((response) => {
            this.setProjectDetail({ ...this.projectDetail, data })
            resolve(response.data)
          })
          .catch(err => {
            reject(err);
          })
      }
      else reject();
    })
  }

  async updateProjectDataForGuest(locationData) {
    return new Promise((resolve, reject) => {
      if (this.projectDetail?._id) {
        ProjectRequest.updateProjectForGuest(this.projectDetail._id, locationData)
          .then((response) => {
            if (response.data.metadata) {
              this.projectDetail.metadata = response.data.metadata
            }
            resolve(response.data)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      }
      else reject()
    })
  }

  setCenterData = data => {
    this.centerData = data
  }

  /**
   * WGS84 to Local ouput [] X, Y, Z
   * @param {*} reflat degrees
   * @param {*} reflng degrees
   * @param {*} refheight degrees
   * @param {*} lat degrees
   * @param {*} lng degrees
   * @param {*} height degrees
   * @param {*} epsgCode 
   * @param {*} heightSystem 
   */
  getHeightWGS84ToLocal = async (reflat, reflng, refheight, lat, lng, height, epsgCode, heightSystem) => {
    // this.setLoadingProgress(true)
    return new Promise((resolve, reject) => {
      ProjectRequest.getHeightWGS84ToLocal(reflat, reflng, refheight, lat, lng, height, epsgCode, heightSystem)
        .then((response) => {
          // this.setLoadingProgress(false)
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          // this.setLoadingProgress(false)
          reject()
        })
    })
  }

  /**
   * Local to WGS84 output [lat,lon,height] 
   * @param {*} reflat 
   * @param {*} reflng 
   * @param {*} refheight 
   * @param {*} lat 
   * @param {*} lng 
   * @param {*} height 
   * @param {*} epsgCode 
   * @param {*} heightSystem 
   */
  getHeightLocalToWGS84 = async (reflat, reflng, refheight, lat, lng, height, epsgCode, heightSystem) => {
    return new Promise((resolve, reject) => {
      ProjectRequest.getHeightLocalToWGS84(reflat, reflng, refheight, lat, lng, height, epsgCode, heightSystem)
        .then((response) => {
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          reject()
        })
    })
  }

  getHeightTwoPointsWGS84ToLocal = async (refPoint1Lat, refPoint1Lng, refPoint1Height, refPoint2Lat, refPoint2Lng, refPoint2Height, Point1lat, Point1lng, Point1height, Point2lat, Point2lng, Point2height, epsgCode, heightSystem) => {
    return new Promise((resolve, reject) => {
      ProjectRequest.getHeightTwoPointsWGS84ToLocal(refPoint1Lat, refPoint1Lng, refPoint1Height, refPoint2Lat, refPoint2Lng, refPoint2Height, Point1lat, Point1lng, Point1height, Point2lat, Point2lng, Point2height, epsgCode, heightSystem)
        .then((response) => {
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          reject()
        })
    })
  }


  /**
   * Convert from Project Plane To WGS84  
   * @param {*} reflat 
   * @param {*} reflng 
   * @param {*} refheight 
   * @param {*} x
   * @param {*} y 
   * @param {*} z 
   * @param {*} epsgCode 
   * @param {*} heightSystem 
   * @returns lat long height radian
   */
  convertProjectPlaneToWGS84Coordinate = async (reflat, reflng, refheight, x, y, z, epsgCode, heightSystem) => {
    return new Promise((resolve, reject) => {
      ProjectRequest.convertProjectPlaneToWGS84Coordinate(reflat, reflng, refheight, x, y, z, epsgCode, heightSystem)
        .then((response) => {
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          reject()
        })
    })
  }


  /**
   * Convert from WGS84 To Project Plane   
   * @param {*} reflat 
   * @param {*} reflng 
   * @param {*} refheight 
   * @param {*} lat
   * @param {*} lng
   * @param {*} height 
   * @param {*} epsgCode 
   * @param {*} heightSystem 
   * @returns x y z
   */
  convertWGS84ToProjectPlaneCoordinate = async (reflat, reflng, refheight, lat, lng, height, epsgCode, heightSystem) => {
    return new Promise((resolve, reject) => {
      ProjectRequest.convertWGS84ToProjectPlaneCoordinate(reflat, reflng, refheight, lat, lng, height, epsgCode, heightSystem)
        .then((response) => {
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          reject()
        })
    })
  }

  /**
   * Convert from Project Plane To WGS84 
   * @param {*} x // 6673400.06456
   * @param {*} y // 25477661.994482335
   * @param {*} z // 0
   * @param {*} epsgCode // 3879
   * @param {*} heightSystem // None
   * @returns lat long height
   */
  convertProjectPlaneToWGS84 = async (x, y, z, epsgCode, heightSystem) => {
    return new Promise((resolve, reject) => {
      ProjectRequest.convertProjectPlaneToWGS84(x, y, z, epsgCode, heightSystem)
        .then((response) => {
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          reject()
        })
    })
  }

  /**
   * Convert from Project Plane To WGS84 
   * @param {*} lat // 60.232306891294
   * @param {*} lng  // 24.9343085259955
   * @param {*} height // 0
   * @param {*} epsgCode // 3879
   * @param {*} heightSystem // None
   * @returns x y z ex[ 25496360.145276207, 6679956.930667244, -35.85264816292427 ]
   */
  convertProjectPlaneToWGS84XYZ = async (lat, lng, height, epsgCode, heightSystem) => {
    return new Promise((resolve, reject) => {
      ProjectRequest.convertProjectPlaneToWGS84XYZ(lat, lng, height, epsgCode, heightSystem)
        .then((response) => {
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          reject()
        })
    })
  }

  /**
   * Calculate height of center model in geoid 
   * @param {*} cenlat 
   * @param {*} cenlng 
   * @param {*} cenheight 
   * @param {*} geoid  
   * @returns height flow geoid
   */
  calculateHeighCenterModelFlowGeoid = async (cenlat, cenlng, cenheight, geoid) => {
    return new Promise(async (resolve, reject) => {
      const waitcompleted = async () => {
        ProjectRequest.calculateHeighCenterModelFlowGeoid(cenlat, cenlng, cenheight, geoid).then((response) => {
          resolve(response.data);
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      }
      await waitcompleted()
    })
  }

  getTilesetExistCityModel = viewer => {
    if (!viewer) return []
    if (!viewer.scene) return []
    if (!viewer.scene.primitives) return []
    let citymodel = []
    let primitives = viewer.scene.primitives;
    for (var i = 0; i < primitives.length; i++) {
      let _tileset = primitives.get(i)
      let model = this.findModelByUrl(_tileset?.resource?.url)
      if (model && (extractHostname(model.src) === 'kartta.hel.fi' || extractHostname(model.src) === 'xd-visuals.com')) {
        citymodel.push(_tileset)
      }
    }
    return citymodel
  }
  getGMLObjectInfo = async (id, model) => {
    function detectCity(src) {
      const availableCity = ['turku', 'espoo', 'lappeenranta', 'vuosaari', 'vanta', 'helsinki', 'oulu']
      for (let i = 0; i < availableCity.length; i++) {
        const city = availableCity[i];
        if (src && src.toLowerCase().indexOf(city) > -1) {
          return city
        }
      }
    }

    const city = detectCity(model.src)
    return new Promise(async (resolve, reject) => {
      const waitcompleted = async () => {
        ProjectRequest.getGMLObjectInfo(id, city).then((response) => {
          resolve(response.data);
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      }
      await waitcompleted()
    })
  }

  getPublicJson = async (data, token) => {
    return new Promise((resolve, reject) => {
      ProjectRequest.getPublicJson(data, token)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          resolve(error)
        })
    })
  }

  setUndergroundSetting = v => {
    Object.assign(this.underGroundSetting, v)
  }

  updateProjectRefPoint = async (project) => {
    if (!this.projectDetail || !project) return
    this.projectDetail.tilesetData = project.tilesetData
    this.projectDetail.coordinateSystem = project.coordinateSystem
    this.projectDetail.elevationSystem = project.elevationSystem ? project.elevationSystem : 'None'
    this.projectDetail.treeData = project.treeData.filter(x => !x.isDeleted)
    if (project.model3DS) {
      let _mergemodels = project.model3DS.filter(v => !v.isDeleted)
      if (_mergemodels.length > 0) {
        for (let index = 0; index < _mergemodels.length; index++) {
          const element = _mergemodels[index];
          let isExist = this.modelList.findIndex(c => c._id === element._id)
          if (isExist !== -1) {
            this.modelList[isExist] = element;
          } else {
            this.modelList.push(element)
          }
        }
      }
    }
  }

  setAmbientOccSetting = v => {
    Object.assign(this.ambientOccSetting, v)
  }

  setShowAddFolderModal = (show) => {
    this.showAddFolderModal = show
  }
  setShowProjectDashBoard = show => {
    this.showProjectDashBoard = show
  }

  setShowEditFileModal = (show) => {
    this.showEditFileModal = show
  }

  setFolderEdit = (folder) => {
    this.folderEdit = folder;
  }

  setFileEdit = (file) => {
    this.fileEdit = file;
  }

  setSketchEdit = (sketch) => {
    this.sketchEdit = sketch;
  }

  setSelectedNode = (node) => {
    this.selectedNode = node
  }

  setIsSelectedNode = (node) => {
    this.isSelectedNode = node
  }


  findHideFeaturesByUrl = url => {
    const model = this.findModelByUrl(url)
    return model && this.hideFeatures[model.id] ? this.hideFeatures[model.id] : []
  }

  async getModel3DSById(model3dsId) {
    return new Promise((resolve, reject) => {
      ProjectRequest.getModel3DSById(model3dsId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(error => {
          resolve(error)
        })
    })
  }

  /**
   * 
   * @param {*} f: type boolean
   */
  setIsUpdateViewOrigo = (f) => {
    this.isUpdateViewOrigo = f
  }

  async getProjectById(projectId) {
    return new Promise((resolve, reject) => {
      ProjectRequest.getDetail(projectId).then((response) => {
        resolve(response);
      }).catch(err => {
        console.log(err);
        reject(err)
      })
    })
  }
  async getProjectSetting(projectId) {
    return new Promise((resolve, reject) => {
      ProjectRequest.getProjectSetting(projectId).then((response) => {
        this.setProjectStorage(parseFloat(response.data?.storage ? response.data?.storage.toFixed(3) : 0) || 0)
        resolve(response);
      }).catch(err => {
        console.log(err);
        reject(err)
      })
    })
  }
  setCloseEditModel = isCancel => {
    this.closeEditModel = isCancel
  }

  /**
   * Action set percentage upload file to server
   * @param {*} value number
   */
  setProgressUploadFile = value => {
    this.progressUploadFile = value
  }

  /**
   * Acction set process file on Amazon s3 or Cesium Ion
   * @param {*} data
   */
  setProgressFileOnS3Ion = data => {
    this.progressFileOnS3Ion = data
  }

  /**
     * Acction set find role user in project
     * @param {*} projectId 
     * @param {*}  userId
     */

  async findProjectUserRole(projectId) {
    return new Promise((resolve, reject) => {
      ProjectRequest.getProjectUserRole(projectId).then((response) => {
        let res = response.data
        if (res.status === 200) {
          this.currentUserRoleInProject = response.data.role
        }
        resolve(response);
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }

  clearCurrentUserRoleInProject = () => {
    this.currentUserRoleInProject = null
  }

  setCurrentUserRoleInProject = (role) => {
    this.currentUserRoleInProject = role
  }

  setBeforeVisibleTileset = data => {
    this.beforeVisibleTileset = data
  }

  setRestoreUserView3Dmode = f => {
    this.restoreUserView3Dmode = f
  }

  /**
   * getMeshModelInfo
   * @param {*} data {initPos:[lat, long, height], modelMatrix: Matrix4, projRefPoint:[lat, long, height], epsg, elevationSystem}
   * @returns RefPoint, LocalOrg, fileOrg, ModelCenter
   */
  async getMeshModelInfo(data) {
    return new Promise(async (resolve, reject) => {
      const waitcompleted = async () => {
        ProjectRequest.getMeshModelInfo(data).then((response) => {
          resolve(response.data);
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      }
      await waitcompleted()
    })
  }
  async getS3ModelTilesetSize(data) {
    return new Promise(async (resolve, reject) => {
      const waitcompleted = async () => {
        ProjectRequest.getS3ModelTilesetSize(data).then((response) => {
          resolve(response.data);
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      }
      await waitcompleted()
    })
  }

  async generatePreSignedPutUrl(data) {
    return new Promise(async (resolve, reject) => {
      const waitcompleted = async () => {
        ProjectRequest.generatePreSignedPutUrl(data).then((response) => {
          resolve(response.data);
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      }
      await waitcompleted()
    })
  }
  async getProjectRefPointOnProjectionPlane(data) {
    return new Promise(async (resolve, reject) => {
      const waitcompleted = async () => {
        ProjectRequest.getProjectRefPointOnProjectionPlane(data).then((response) => {
          resolve(response.data);
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      }
      await waitcompleted()
    })
  }

  /**
   * getMeshModelInfoOnWGS84
   * @param {*} data {initPos:[lat, long, height], modelMatrix: Matrix4}
   * @returns ModelCenter
   */
  async getMeshModelInfoOnWGS84(data) {
    return new Promise(async (resolve, reject) => {
      const waitcompleted = async () => {
        ProjectRequest.getMeshModelInfoOnWGS84(data).then((response) => {
          resolve(response.data);
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      }
      await waitcompleted()
    })
  }
  setcurrentCamera(data) {
    this.currentCamera = data
  }

  setChangeHeadingRotation = (f) => {
    this.changeHeadingRotation = f
  }

  async waitUntilReady(listHashModel, maxRetry = 1000) {
    var numb = 0;
    return new Promise((resolve, reject) => {
      const waitUntilReady = async (i, listHashModel) => {
        await ProjectRequest.checkReadyRebuildProject(listHashModel).then(res => {
          const isnotfinish = res.data.some(item => item.status === 'waiting');
          if (isnotfinish) {
            i++;
            if (i > maxRetry) {
              console.log('Error maxRetry: ');
              resolve(res?.data);
            } else {
              console.log(`waiting retry..${i}`, toJS(res?.data));
              setTimeout(function () { waitUntilReady(i, res.data); }, 30000); // 5000 milliseconds = 5 second
            }
          } else {
            resolve(res?.data);
          }
        }).catch(err => {
          reject(err)
        })

      };
      (async () => {
        await waitUntilReady(numb, listHashModel);
      })();
    });
  }
  async rebuildProjectModel(data) {
    return new Promise(async (resolve, reject) => {
      const waitcompleted = async () => {
        ProjectRequest.rebuildProjectModel(data).then(async (response) => {
          let result = await this.waitUntilReady(response.data)
          resolve(result[0])
        }).catch(err => {
          console.log(err)
          reject(err)
        })
      }
      await waitcompleted()
    })
  }

  async updateMaintenanceInfo(data) {
    return new Promise(async (resolve, reject) => {
      ProjectRequest.updateMaintenanceInfo(data).then((response) => {
        resolve(response.data);
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }

  async getMaintenanceInfo() {
    return new Promise(async (resolve, reject) => {
      ProjectRequest.getMaintenanceInfo().then((response) => {
        resolve(response.data);
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }

  async getFeaturesHelpListInfo() {
    return new Promise(async (resolve, reject) => {
      
      const key = siteMode === "6dplanner" ? '6dplanner_features_list.json' : 'features_list.json';
      ProjectRequest.getFeaturesHelpListInfo(key).then((response) => {
        if (response.data.statusCode === 200)
          this.featuresHelpList = response.data.data
        resolve(response.data);
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }

  async getFeedbackReport(projectId) {
    return new Promise(async (resolve, reject) => {
      ProjectRequest.getFeedbackReport(projectId).then((response) => {

        this.feedbackReports = response.data.filter(c => c.feedbackAnswers && c.feedbackAnswers.length > 0)
        resolve(response.data);
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }

  fetching = false
  async updateProjectStorage(projectID) {
    if(this.fetching) return ;
    this.fetching = true
    return new Promise(async (resolve, reject) => {
      ProjectRequest.updateProjectStorage(projectID ? projectID : this.projectDetail._id, []).then((response) => {
        this.setProjectStorage(parseFloat(response.data?.storage.toFixed(3)) || 0)
        if (response.data?.organization) {
          organizationStore.setCurrentOrganizations({
            ...organizationStore.currentOrganizations,
            storageQuotaUsage: response.data?.organization?.storageQuotaUsage,
            percentQuotaUsage: response.data?.organization?.percentQuotaUsage
          })
        }
        this.fetching = false;
        resolve(response.data);
      }).catch(err => {
        console.log(err)
        this.fetching = false;
        reject(err)
      })
    })
  }

  setProjectStorage = (data) => {
    this.projectStorage = data
  }

  clearFeedbackReport = () => {
    this.feedbackReports = []
  }

  setMaintenanceInfo = (payload) => {
    this.maintenanceInfo = payload
  }

  setRebuildModel = (f) => {
    this.rebuildModel = f
  }

  setCity3DDB = data => {
    this.city3DDB = data
  }

  setDataTemplateChecked = data => {
    this.dataTemplateChecked = data
  }

  setShowAddProjectTemplateModal = stt => {
    this.showAddProjectTemplateModal = stt
  }

  setPolylines = data => {
    this.polylines = data
  }

  async getAllGEOID() {
    return new Promise((resolve, reject) => {
      GEOIDRequest.getAllGEOID()
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  async getExpirationLicense(projectId) {
    return new Promise((resolve, reject) => {
      LicenseRequest.getExpirationLicense(projectId).then((response) => {
        this.setExpirationLicenses(response.data)
        resolve(response.data);
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }

  async duplicateProject(projectId, data) {
    return new Promise((resolve, reject) => {
      ProjectRequest.duplicateProject(projectId, data).then((response) => {
        resolve(response.data);
      }).catch(err => {
        this.setLoadingProgress(false)
        console.log(err)
        message.error(t('an-error-occurred'))
        reject(err)
      })
    })
  }

  async checkServiceChangeLogo(projectId) {
    return new Promise((resolve, reject) => {
      ProjectRequest.checkServiceChangeLogo(projectId).then((response) => {
        this.isChangeLogoXDTwin = response.data;
        resolve(response.data);
      }).catch(err => {
        this.isChangeLogoXDTwin = {
          customOrganizationLogo : 'fail',
          logo : false
        }
        this.setLoadingProgress(false)
        console.log(err)
        reject(err)
      })
    })
  }

  setShowTwinGuideDialog = data => {
    this.showTwinGuideDialog = data
  }
  setHideTwinGuideDialog = data => {
    this.hideTwinGuideDialog = data
  }

  setCheck2D = props => {
    if (props.hasOwnProperty('load')) {
      this.check2D.load = props.load
    }
    if (props.hasOwnProperty('status')) {
      this.check2D.status = props.status
    }
  }

  async getAllProjectCustomAttributes() {
    return new Promise((resolve, reject) => {
      ProjectCustomAttributesRequest.getAll().then((response) => {
        resolve(response.data);
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }

  async getProjectCustomAttributes(id) {
    return new Promise((resolve, reject) => {
      ProjectCustomAttributesRequest.getProjectCustomAttributes(id).then((response) => {
        this.prjCustomAttributeList = response.data
        resolve(response.data);
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }



  async getProjectCustomAttributeById(id) {
    return new Promise((resolve, reject) => {
      ProjectCustomAttributesRequest.getById(id).then((response) => {
        resolve(response.data);
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }

  async createProjectCustomAttributes(data) {
    return new Promise((resolve, reject) => {
      ProjectCustomAttributesRequest.create(data).then((response) => {
        this.prjCustomAttributeList.push(response.data)
        resolve(response.data);
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }

  async updateProjectCustomAttributes(id, body) {
    return new Promise((resolve, reject) => {
      ProjectCustomAttributesRequest.update(id, body).then((response) => {
        resolve(response.data);
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }

  async deleteProjectCustomAttributes(id) {
    return new Promise((resolve, reject) => {
      ProjectCustomAttributesRequest.delete(id).then((response) => {
        resolve(response.data);
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }

  async updateProjectAttributes(id, body) {
    return new Promise((resolve, reject) => {
      ProjectCustomAttributesRequest.updateProjectAttributes(id, body).then((response) => {
        resolve(response.data);
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }

  setProjectCustomAttributes = (data) => {
    this.prjCustomAttributeList = data
  }

  async getModelAttributes(objectId, body) {
    return new Promise((resolve, reject) => {
      ModelCustomAttributesRequest.getModelAttributes(objectId, body).then((response) => {
        resolve(response.data);
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }

  async updateModelAttributes(body) {
    return new Promise((resolve, reject) => {
      ModelCustomAttributesRequest.updateModelAttributes(body).then((response) => {
        resolve(response.data);
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }

  setObjectCustomAttributes = (data) => {
    this.objectCustomAttributes = data
  }

  async createModelCustomAttributes(data) {
    return new Promise((resolve, reject) => {
      ModelCustomAttributesRequest.create(data).then((response) => {
        resolve(response.data);
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }

  async updateModelCustomAttributes(id, body) {
    return new Promise((resolve, reject) => {
      ModelCustomAttributesRequest.update(id, body).then((response) => {
        resolve(response.data);
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }

  async deleteModelCustomAttributes(id) {
    return new Promise((resolve, reject) => {
      ModelCustomAttributesRequest.delete(id).then((response) => {
        resolve(response.data);
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }

  async getProjectGenericTemplate(projectId) {
    return new Promise((resolve, reject) => {
      GenericTemplateRequest.getProjectTemplate(projectId).then((response) => {
        this.projectGenericTemplates = response.data;
        resolve(response.data);
      }).catch(err => {
        reject(err)
      })
    })
  }

  async createGenericTemplate(data) {
    return new Promise((resolve, reject) => {
      GenericTemplateRequest.create(data).then((response) => {
        this.selectedGenericTemplate = response.data
        this.projectGenericTemplates = [...this.projectGenericTemplates, response.data]
        resolve(response.data);
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }

  async updateGenericTemplate(id, body) {
    return new Promise((resolve, reject) => {
      GenericTemplateRequest.update(id, body).then((response) => {
        let index
        for (let i = 0; i < this.projectGenericTemplates.length; i++) {
          if (this.projectGenericTemplates[i].id === id) {
            index = i
          }
        }
        this.projectGenericTemplates[index] = response.data
        resolve(response.data);
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }

  async deleteGenericTemplate(id) {
    return new Promise((resolve, reject) => {
      GenericTemplateRequest.delete(id).then((response) => {
        const index = this.projectGenericTemplates.findIndex(x => x.id === id)
        this.projectGenericTemplates.splice(index, 1)
        resolve(response.data)
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }

  async updateProjectTilesetData(projectId, body) {
    return new Promise((resolve, reject) => {
      ProjectRequest.updateProjectTilesetData(projectId, body).then((response) => {
        resolve(response.data);
      }).catch(err => {
        reject(err)
      })
    })
  }

  getListModelsByProjectId = (projectId, params) => {
    return new Promise((resolve, reject) => {
      ProjectRequest.getListModelsByProjectId(projectId, params).then((res) => {
        resolve(res);
      }).catch(err => {
        console.log(err);
        reject(err)
      })
    })
  }

  /**
   * Method Post
   * Delete moddel, menu key,
   * @param {*} body {projectId: required, modelId: optional, treeNodeKey: optional}
   * return boolean
   */
  async deleteModel(body) {
    let _project = await this.delete3DModel(body)
    if (_project) {
      await this.updateProjectRefPoint(_project)
      if (body.modelId) this.setDeleteIds([body.modelId])
      this.setRebuildModel(true) // for effect load updatePlaneMatix
    }
  }

  async delete3DModel(body) {
    return new Promise((resolve, reject) => {
      ProjectRequest.deleteModel(body).then((response) => {
        resolve(response.data);
      }).catch(err => {
        reject(err)
      })
    })
  }

  /**
   * Method Post Delete multi model
   * @param {*} body: {projectId: required, deleteData: { modelId: required, treeNodeKey: required }},
   * @returns project populate model3d
   */
  async deleteMultiModels(body) {
    return new Promise((resolve, reject) => {
      ProjectRequest.deleteMultiModels(body).then((response) => {
        resolve(response.data);
      }).catch(err => {
        reject(err)
      })
    })
  }

  async checkProjectIsDeleted(body) {
    return new Promise((resolve, reject) => {
      ProjectRequest.checkProjectIsDeleted(body).then((response) => {
        resolve(response.data);
      }).catch(err => {
        reject(err)
      })
    })
  }

  waitUntilDataTreeReady = async (modelId) => {
    return new Promise((resolve, reject) => {
      ProjectRequest.waitUntilDataTreeReady(modelId)
        .then((response) => {
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          reject()
        })
    })
  }

  updateModelStatus = async (modelId, body) => {
    return new Promise((resolve, reject) => {
      ProjectRequest.updateModelStatus(modelId, body)
        .then((response) => {
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          reject()
        })
    })
  }

  getAllLasTrigger = (projectId) => {
    return new Promise((resolve, reject) => {
      QueueLamdaLas.getAllLasTrigger(projectId).then((res) => {
        resolve(res.data);
      }).catch(err => {
        console.log(err);
        reject([])
      })
    })
  }

  deleteLasTriggerId = (id) => {
    return new Promise((resolve, reject) => {
      QueueLamdaLas.delete(id).then((res) => {
        resolve(true);
      }).catch(err => {
        console.log(err);
        reject(false)
      })
    })
  }

  backToHome = (viewer) => {
    const cameraHome = this.cameraHome;
    if (!cameraHome) return;
    if (cameraHome.up) {
      goViewpoint(viewer, cameraHome)
    } else {
      setxDCamera(viewer, cameraHome)
    }
  }

  backToPastLocation = (viewer) => {
    if (!this.pastLocations || this.pastLocations?.length === 0) return;
    let position = this.pastLocations.pop();
    if (position) {
      this.isMovingCamera = true;
      setxDCamera(viewer, position)
    }
  }

  getFoldersWithChildren = () => {
    const getAllFolder = (node) => {
      let result = [];
      if (node.type === 'FOLDER') {
        result.push(node);
      }
      if (node.children) {
        for (const child of node.children) {
          result = result.concat(getAllFolder(child));
        }
      }
      return result;
    }
    const foldersWithChildren = this.projectDetail?.treeData?.length > 0 ? this.projectDetail.treeData.reduce((acc, node) => acc.concat(getAllFolder(node)), []) : [];
    return foldersWithChildren.map(item => {
      return { key: item.key, parentKey: item.parentKey, title: item.title, isVisible4D: item.isVisible4D, startDate: item.startDate, endDate: item.endDate }
    })
  }

  hiddenModelLinkIDs = []

  setHiddenModelLinkIDs = (data) => {
    this.hiddenModelLinkIDs = data
  }

  getVisibleTileViewLinkIds = (tileLink) => {
    let visibleTileViewIds = [];
    for (var i = 0; i < this.listProjectLink.length; i++) {
      let projectLink = this.listProjectLink[i]
      this.listAllModel3DSLink.map(item => {
        if (item.link?.id === projectLink.link?.id) {
          if (projectLink.isVisible) {
            if (tileLink) {
              visibleTileViewIds.push(`${item.id}-tile-link`)
            } else {
              visibleTileViewIds.push(item.id)
            }
          }
        }
      })
    }
    return visibleTileViewIds;
  }

  cameraHeight = 0;
  setCameraHeight = (data) => {
    this.cameraHeight = data;
  }

  /**
  * Search object info in project
  * @param {*} projectId project id
  * @param {*} search param query , type : string
  */
  async searchObjectInfor(projectId, search) {
    const param = {
      projectId: projectId,
      search: search
    }
    return new Promise((resolve, reject) => {
      ObjectInforRequest.searchObjectInfor(param).then(response => {
        resolve(response.data);
      })
        .catch(err => {
          reject(err)
        })
    })
  }
  downloadIonUploadToS3 = (data) => {
    return new Promise((resolve, reject) => {
      ProjectRequest.downloadIonUploadToS3(data)
        .then((response) => {
          resolve(response.data)
        })
        .catch(error => {
          console.log(error)
          reject()
        })
    })
  }
  
  async checkXDEnginePointCloudReady(body) {
    return new Promise((resolve, reject) => {
      if (this.projectDetail?._id) {
        ProjectRequest.checkXDEnginePointCloudReady(this.projectDetail._id, body)
        .then((response) => {
          const results = response.data || [];
          results.forEach(c => {
            if (c.status === "process_success") {
              let index = this.modelList.findIndex(m => (m.id ? m.id : m._id) === c?._id);
              if (index !== -1) {
                let crr = this.modelList[index]
                const updatedModel = {
                    ...crr,
                    ref : false,
                    name: c.name,
                    data: c.data,
                    crs: c.crs,
                    status: c.status
                  };
                  this.modelList[index] = updatedModel;
                }
              }
            });
            this.reRenderModel = !this.reRenderModel;
            resolve(response.data);
          })
          .catch(err => {
            reject(err);
          });
        } else {
          reject();
        }
      });
    }
  
    setLogInfo = (data) => {
      this.logInfo = data
    }
      
    getLogInfo = async (data) => {
      this.setIsLogInfoLoading(true)
      return new Promise((resolve, reject) => {
        ProjectRequest.getLog(data)
          .then((response) => {
            this.setLogInfo(response.data)
            this.setIsLogInfoLoading(false)
            resolve(response.data)
          })
          .catch(error => {
            console.log(error)
            this.setIsLogInfoLoading(false)
            reject()
          })
      })
    }
    
  }
  
  decorate(ProjectStore, {
    // Observables
  isLoading: observable,
  projectList: observable,
  projectDetail: observable,
  cameraHeight: observable,
  setCameraHeight: action,
  
  currentAddTab: observable,
  showAddProjectModal: observable,
  showEditLocation: observable,
  displayPanel: observable,
  modelList: observable,
  selectedModel: observable,
  zoomClick: observable,
  showProjectInfoDrawer: observable,
  deleteIds: observable,
  
  displayEditLocation: action,
  updateProjectCover: action,
  toggleProjectInfoDrawer: action,
  acceptProjectInvitation: action,
  setDisplayPanel: action,
  setLoadingProgress: action,
  setProjectDetail: action,
  getProjectDetail: action,
  updateProjectRefPoint: action,
  setProjects: action,
  toggleDrawer: action,
  setCurrentAddTab: action,
  setShowAddProjectModal: action,
  setModelList: action,
  createModel: action,
  setSelectedModel: action,
  getCurrentModel: action,
  setZoomClick: action,

  getAllProjects: action,
  deleteProject: action,
  deleteTileset: action,
  setDeleteIds: action,
  clearProjectList: action,
  clearProjectDetail: action,
  setShowEditLocation: action,

  hideEditLocation: action,

  currentModelId: observable,
  setCurrentModelId: action,

  currentInitLocation: observable,
  setCurrentInitLocation: action,

  showBoudingVolume: observable,
  setShowBoudingVolume: action,

  viewMode: observable,
  setViewMode: action,

  gpsMode: observable,
  setGpsMode: action,

  showMap: observable,
  setShowMap: action,

  globeBehind: observable,
  setGlobeBehind: action,

  showAntialiasing: observable,
  setShowAntialiasing: action,

  showRefPoint: observable,
  setShowRefPoint: action,

  showHUD: observable,
  setShowHUD: action,

  showAmbientOcclusion: observable,
  setShowAmbientOcclusion: action,
  setShowInspectorTool: action,
  showInspectorTool: observable,

  newModelId: observable,
  setNewModelId: action,

  modelEditPos: observable,
  setModelEditPos: action,

  currentTile: observable,
  setCurrentTile: action,
  geoWatch: observable,
  setGeoWatch: action,
  obtDeviceSensor: observable,
  obtGPSOrientation: observable,
  setObtGPSOrientation: action,
  setObtDeviceSensor: action,
  clippingMode: observable,
  clippingPickDone: observable,
  setClippingPickDone: action,
  setWorldTerrain: action,
  WorldTerrain: observable,
  setClippingMode: action,
  setCurrentGPS: action,
  currentGPS: observable,

  projectionList: observable,
  setProjectionDetail: action,
  skyColor: observable,
  setSkyColor: action,

  setEpsgCodeList: action,
  epsgCodeList: observable,

  visibleTilesets: observable,
  setVisibleTilesets: action,

  isSendARInfo: observable,
  setSendARInfo: action,
  sendARInfo: action,

  zoomToModel: observable,
  setZoomToModel: action,

  findModelByUrl: action,

  cleanMode: observable,
  setCleanMode: action,

  showFov: observable,
  setShowFov: action,

  is2D: observable,
  set2D: action,
  averageOrientation: observable,

  openCVData: observable,
  setOpenCVData: action,
  // opencvCalcAngle: action,

  showDrawerConnectNavigation: observable,
  setShowDrawerConnectNavigation: action,

  showHiddenTilesList: observable,
  setShowHiddenTilesList: action,

  setShowHiddenDrawer: action,
  showHiddenDrawer: observable,

  setShowHiddenAreaTab: action,
  showHiddenAreaTab: observable,

  checkCityModelByUrl: action,
  updateProject: action,

  hiddenTileSelected: observable,
  setHiddenTileSelected: action,

  centerData: observable,
  setCenterData: action,
  setCenterData: action,

  visitedMode: observable,
  setVisitedMode: action,

  projectDetailError: observable,
  setProjectDetailError: action,

  combineBoundingSpheres: observable,
  setCombineBoundingSpheres: action,

  getHeightWGS84ToLocal: action,
  getHeightLocalToWGS84: action,
  getHeightTwoPointsWGS84ToLocal: action,
  convertProjectPlaneToWGS84Coordinate: action,
  convertWGS84ToProjectPlaneCoordinate: action,
  calculateHeighCenterModelFlowGeoid: action,
  convertProjectPlaneToWGS84: action,

  isShowARCamera: observable,
  setShowARCamera: action,


  getTilesetExistCityModel: action,
  setObtGPSElevation: action,
  obtGPSHeight: observable,
  calculateGPSDistance: action,

  renderResolutionControl: observable,
  setShowRenderResolutionControl: action,

  recordData: observable,
  setRecordData: action,

  sampleRange: observable,
  setSampleRange: action,
  smoothFilterType: observable,
  setSmoothFilterType: action,
  sensorFrequences: observable,
  setSensorFrequences: action,

  startRecordData: observable,
  setStartRecodData: action,

  renderRecordDataControl: observable,
  setRenderRecordDataControl: action,

  dropDownMenuCameraSettings: observable,
  setDropDownMenuCameraSettings: action,

  getPublicJson: action,

  updateProjectData: action,
  updateProjectMetadata: action,
  updateProjectDataForGuest: action,

  underGroundSetting: observable,
  setUndergroundSetting: action,
  ambientOccSetting: observable,

  isMasking: observable,
  setMasking: action,

  setArToolTip: action,
  arToolTip: observable,
  enableReCenterGPS: observable,
  setEnableReCenterGPS: action,
  reCenterGPS: observable,
  setReCenterGPS: action,
  setShowAmbientOccSetting: action,
  showAmbientOcclusionSetting: observable,
  // currentEditModel: observable
  modelFeatures: observable,
  setModelFeatures: action,
  alignmentFeatures: observable,
  setAlignmentFeatures: action,

  showAddFolderModal: observable,
  setShowAddFolderModal: action,

  selectedNode: observable,
  setSelectedNode: action,

  setIsSelectedNode: action,
  isSelectedNode: observable,

  folderEdit: observable,
  setFolderEdit: action,

  sketchEdit: observable,
  setSketchEdit: action,

  showAddResourceModel: observable,
  setShowAddResourceModel: action,

  getModel3DSById: action,

  showEditFileModal: observable,
  setShowEditFileModal: action,

  fileEdit: observable,
  setFileEdit: action,

  setSelectedAttrData: action,
  selectedAttrData: observable,

  setSelectedFeature: action,
  selectedFeature: observable,

  setLoadedModelTreeData: action,
  loadedModelTreeData: observable,

  loadingAttrData: observable,
  setLoadingAttrData: action,

  showProcessInBackground: observable,
  setShowProcessInBackground: action,

  checkModalStatus: observable,
  setCheckModalStatus: action,

  setHideFeatures: action,
  hideFeatures: observable,

  setIsUpdateViewOrigo: action,
  isUpdateViewOrigo: observable,

  showUpdateResourceModel: observable,
  setShowUpdateResourceModel: action,

  setCurrentProjectDetail: action,
  currentProjectDetail: observable,

  totalProject: observable,
  getTotalProjects: action,
  setNavigationStyles: action,
  navigationStyles: observable,
  clearNavigationStyles: action,

  getProjectById: action,

  setCloseEditModel: action,
  closeEditModel: observable,

  setShowBackgroundSetting: action,
  showBackgroundSetting: observable,

  setProgressUploadFile: action,
  progressUploadFile: observable,

  setProgressFileOnS3Ion: action,
  progressFileOnS3Ion: observable,
  showProjectDashBoard: observable,
  setShowProjectDashBoard: action,
  listMoveEndCb: observable,
  listOnTickCb: observable,
  changeModelStyle: observable,
  setChangeModelStyle: action,

  dataAttributeModal: observable,
  setDataAttributeModal: action,
  currentUserRoleInProject: observable,
  findProjectUserRole: action,
  clearCurrentUserRoleInProject: action,
  setCurrentUserRoleInProject: action,

  setBeforeVisibleTileset: action,
  beforeVisibleTileset: observable,

  setRestoreUserView3Dmode: action,
  restoreUserView3Dmode: observable,
  webglContextAlpha: observable,
  setWebglContextAlpha: action,

  obtAbsoluteDeviceSensor: observable,
  setobtAbsoluteDeviceSensor: action,
  obtRelativeDeviceSensor: observable,
  setobtRelativeDeviceSensor: action,

  update3DMODELS: action,

  setAbsoluteHPRData: action,
  absoluteHPRData: observable,
  getMeshModelInfo: action,
  getProjectRefPointOnProjectionPlane: action,
  setPickPosition: action,
  pickPosition: observable,
  showFeatureSettingDrawer: observable,
  setShowFeatureSettingDrawer: action,
  currentCamera: observable,
  setcurrentCamera: action,
  setChangeHeadingRotation: action,
  changeHeadingRotation: observable,

  tileViews: observable,
  setTileViews: action,
  rebuildProjectModel: action,
  maximumScreenSpaceError: observable,
  setMaximumScreenSpaceError: action,
  gmlmaximumScreenSpaceError: observable,
  setgmlMaximumScreenSpaceError: action,
  setRebuildModel: action,
  rebuildModel: observable,
  setSelectedStatus: action,
  selectedStatus: observable,
  getCurrentProjectSetting: action,
  setCity3DDB: action,
  city3DDB: observable,
  getCurrentProjectStorageQuota: action,
  getGMLObjectInfo: action,
  updateMaintenanceInfo: action,
  getMaintenanceInfo: action,
  maintenanceInfo: observable,
  setMaintenanceInfo: action,
  setDataTemplateChecked: action,
  dataTemplateChecked: observable,
  createProjectTemplate: action,
  setHiddenCityGmlSelected: action,
  hiddenCityGmlSelected: observable,
  setShowAddProjectTemplateModal: action,
  showAddProjectTemplateModal: observable,
  setSelectedPreDefineAcess: action,
  selectedPreDefineAcess: observable,
  Alignment: observable,
  setAlignment: action,
  setPolylines: action,
  polylines: observable,
  setCurrentViewpoint: action,
  currentViewpoint: observable,
  setModelHiddenBeforeOpenViewPoint: action,
  modelHiddenBeforeOpenViewPoint: observable,
  setTargetViewPoint: action,
  clearTargetViewPoint: action,
  targetViewPoint: observable,
  setMaxCountOfVisibleTiles: action,
  maxCountOfVisibleTiles: observable,
  setTypeViewPoint: action,
  typeViewPoint: observable,
  getFeedbackReport: action,
  clearFeedbackReport: action,
  feedbackReports: observable,
  maximumAttenuation: observable,
  setMaximumAttenuation: action,
  geometricErrorScale: observable,
  setGeometricErrorScale: action,
  eyeDomeLightingStrength: observable,
  setEyeDomeLightingStrength: action,
  eyeDomeLightingRadius: observable,
  setEyeDomeLightingRadius: action,
  uploadNewStartImage: observable,
  setUploadNewStartImage: action,
  reloadAlignment: observable,
  setReloadAlignment: action,
  compassMode: observable,
  setCompassMode: action,
  getAllGEOID: observable,
  showTwinGuideDialog: observable,
  setShowTwinGuideDialog: action,
  hideTwinGuideDialog: observable,
  setHideTwinGuideDialog: action,
  setCheck2D: action,
  check2D: observable,
  updateProjectStorage: action,
  fetching : observable,
  setProjectStorage: action,
  projectStorage: observable,
  setExpirationLicenses: action,
  getExpirationLicense: action,
  expirationLicenses: observable,
  setShowDialogExpirationLicense: action,
  showDialogExpirationLicense: observable,
  setClippingViewPoint: action,
  clippingViewPoint: observable,

  setShowDuplicateProjectModal: action,
  showDuplicateProjectModal: observable,

  duplicateProject: action,
  setIsExistLicenses: action,
  isExistLicenses: observable,

  prjCustomAttributeList: observable,
  getProjectCustomAttributes: action,
  getAllProjectCustomAttributes: action,
  getProjectCustomAttributeById: action,
  createProjectCustomAttributes: action,
  updateProjectCustomAttributes: action,
  deleteProjectCustomAttributes: action,
  updateProjectAttributes: action,
  setProjectCustomAttributes: action,

  createModelCustomAttributes: action,
  updateModelCustomAttributes: action,
  deleteModelCustomAttributes: action,

  changeAttributesTab: action,
  attributesTab: observable,
  setShowCustomAttributeDrawer: action,
  showCustomAttributeDrawer: observable,
  setCurrentProjectAttribute: action,
  currentProjectAttribute: observable,
  setVisibleModalProjectAtt: action,
  visibleModalProjectAtt: observable,
  getModelAttributes: action,
  updateModelAttributes: action,
  setObjectCustomAttributes: action,
  objectCustomAttributes: observable,
  setVisibleModalObjectAtt: action,
  visibleModalObjectAtt: observable,
  setCurrentObjectAttribute: action,
  currentObjectAttribute: observable,
  setIsEditingModelAttribute: action,
  isEditingModelAttribute: observable,
  setIsEditingObjectAttribute: action,
  isEditingObjectAttribute: observable,
  setShowGenericReport: action,
  isShowGenericReport: observable,
  selectedGenericTemplate: observable,
  projectGenericTemplates: observable,
  createGenericTemplate: action,
  updateGenericTemplate: action,
  deleteGenericTemplate: action,
  getProjectGenericTemplate: action,
  setProjectGenericTemplates: action,
  setSelectedGenericTemplate: action,
  stateBeforeRebuild: observable,
  setStateBeforeRebuild: action,
  updateProjectTilesetData: action,
  getListModelsByProjectId: action,
  showDataTreePrjLink: observable,
  selectedNodePrjLink: observable,
  listModelProjectLink: observable,
  listProjectLink: observable,
  showProjectLinkDrawer: observable,
  listModelProject: observable,
  setShowProjectLinkDrawer: action,
  setListModelProjectLink: action,
  setListProjectLink: action,
  setListModelProject: action,
  getListModelProject: action,
  deleteProjectLink: action,
  updateProjectLink: action,
  getListProjectLink: action,
  createProjectLink: action,
  tileLinkViews: observable,
  setTileLinkViews: action,
  listAllModel3DSLink: observable,
  setListAllModel3DSLink: action,
  updateProjectLinkVisible: action,
  findModelByUrlLink: action,
  clickModelLink: observable,
  setClickModelLink: action,
  deleteModel: action,
  delete3DModel: action,
  isEditSessionVisibleData: observable,
  setIsEditSessionVisibleData: action,
  deleteMultiModels: action,
  checkProjectIsDeleted: action,
  featuresHelpList: observable,
  setFeaturesHelpList: action,
  clearFeaturesHelpList: action,
  getFeaturesHelpListInfo: action,
  currentHelpfeature: observable,
  setCurrentHelpfeature: action,
  showUserGroupDrawer: observable,
  setShowUserGroupDrawer: action,
  getProjectSetting: action,
  shadowDarkness: observable,
  shadowDistance: observable,
  shadowAccuracy: observable,
  softShadows: observable,
  setShadowDarkness: action,
  setShadowDistance: action,
  setShadowAccuracy: action,
  setSoftShadows: action,
  updateModelStatus: action,
  showModalProcessingModel3D: observable,
  setShowModalProcessingModel3D: action,
  reRenderModel: observable,
  setReRenderModel: action,
  i3sViews: observable,
  i3sViewsLink: observable,
  setI3sViews: action,
  setI3sViewsLink: action,
  isSocketOpenProjectError: observable,
  setIsSocketOpenProjectError: action,
  setListObjectHide: action,
  listObjectHide: observable,
  setListObjectColor: action,
  listObjectColor: observable,
  setIsRerenderFeatureTile: action,
  isRerenderFeatureTile: observable,
  updateModelFeatures: action,
  checkServiceChangeLogo: action,
  setChangeLogoXDTwin: action,
  isChangeLogoXDTwin: observable,
  previousCameraPosition: observable,
  setPreviousCameraPosition: action,
  deleteLasTriggerId: action,
  getAllLasTrigger: action,
  isDownloadingModel3d: observable,
  setIsDownloadingModel3d: action,
  addPastLocations: action,
  pastLocations: observable,
  isMovingCamera: observable,
  setMovingCamera: action,
  setCameraLoadFinish: action,
  cameraLoadFinish: observable,
  setCameraHome: action,
  cameraHome: observable,
  isEditModel: observable,
  setIsEditModel: action,
  backToHome: action,
  backToPastLocation: action,
  setVisible4DFolders: action,
  visible4DFolders: observable,
  getFoldersWithChildren: action,
  getVisibleTileViewLinkIds : action,
  setHiddenModelLinkIDs : action,
  hiddenModelLinkIDs : observable,
  setShowLighting : action,
  showLighting : observable,
  searchObjectInfor: action,
  setShowDrawerCalculation : action,
  showDrawerCalculation : observable,
  setIsAnyInputFocused : action,
  isAnyInputFocused :observable,
  downloadIonUploadToS3: action,
  setRerenderWMS : action,
  rerenderWMS : observable,
  setPrevious3DViewSetting : action,
  previous3DViewSetting : observable,
  setWMSOrders : action ,
  WMSOrders : observable,
  updateProjectSetting: action,
  updateIFCEllipsoid :action,
  checkXDEnginePointCloudReady : action,
  msaaSamples: observable,
  setMSAASamples: action,
  fxaa: observable,
  setFXAA: action,
  setTilesLoaded: action,
  tilesLoaded: observable,
  setLogInfo: action,
  logInfo: observable,
  getLogInfo: action,
  isLogInfoLoading: observable,
  setIsLogInfoLoading: action,
  showLogInfoProjectDrawer: observable,
  setShowLogInfoProjectDrawer: action,
})

export default new ProjectStore()
