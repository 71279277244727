import { ColumnWidthOutlined } from '@ant-design/icons'
import { Button, Drawer, Tooltip, Tabs } from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import React, { useCallback, useEffect, useState } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { TeamsPageWrapper } from './style'
import { HelpButton } from '@/components/elements'
import { HeaderDrawerWrapper } from './style'
import DrawerUserGroup from '../ProjectDetailPage/DrawerUserGroup'
import DrawerUserManagement from './DrawerUserManagement'


const DrawerProjectTeams = ({ projectTeamsStore, projectStore, usersStore, projectSettingStore, adminStore, licenseStore, commonStore, userGroupStore }) => {
    const { t } = useTranslation();
    moment.locale(`${commonStore.language}`)
    const isTabletOrMobile = isMobile || isTablet
    const [drawerWidth, setDrawerWidth] = useState(0);
    const [loading, setLoading] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [activeTab, setActiveTab] = useState('users')

    //#region event for mouse and touch to resize drawer panel
    const handleTouchStart = e => {
        e.preventDefault();
        if (e.touches.length !== 1) return null;
        document.addEventListener("touchmove", handleTouchMove, { passive: false });
        document.addEventListener("touchend", handleTouchEnd, { passive: false });
        document.addEventListener("touchcancel", handleTouchEnd, { passive: false });
    };

    const handleTouchMove = useCallback(e => {
        let touch = e.touches[0] || e.changedTouches[0];
        let target = document.elementFromPoint(touch.clientX, touch.clientY);
        let newWidth = (touch.clientX - (target && target.offsetRight ? target.offsetRight : 0));
        let minDrawerWidth = 360;
        if (newWidth < 0) {
            setDrawerWidth(10);
        }
        if (newWidth > minDrawerWidth) {
            setDrawerWidth(newWidth);
        }
        if (newWidth > window.innerWidth) {
            setDrawerWidth(window.innerWidth);
        }
    }, []);

    const handleTouchEnd = () => {
        document.removeEventListener("touchend", handleTouchEnd, { passive: false });
        document.removeEventListener("touchcancel", handleTouchEnd, { passive: false });
        document.removeEventListener("touchmove", handleTouchMove, { passive: false });
    };

    const handleMouseDown = e => {
        e.preventDefault();
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        let offsetRight = e.clientX - document.body.offsetLeft;
        let minDrawerWidth = 360;
        if (offsetRight > minDrawerWidth) {
            setDrawerWidth(offsetRight);
        }
        if (offsetRight > window.innerWidth) {
            setDrawerWidth(window.innerWidth);
        }
    }, []);
    //#endregion
    const handleChangeScreenSize = () => {
        setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleChangeScreenSize)

    useEffect(() => {
        if (drawerWidth > screenWidth) {
            setDrawerWidth(screenWidth)
        }
    }, [screenWidth])

    useEffect(() => {
        if (projectTeamsStore.showProjectTeamsDrawer) {
            setDrawerWidth(isTabletOrMobile ? 360 : 550)
            
            const loadInitData = async () => {
                setLoading(true)
                await userGroupStore.getAllProjectUserGroup(projectStore.projectDetail.id).catch(err => console.log(err))
        
                await projectTeamsStore.getProjectTeams(projectStore.projectDetail.id)
                if (projectStore.projectDetail.organization) {
                    await licenseStore.getQuotaLicenseOrganization(projectStore.projectDetail.organization.id).then(res => {
                        adminStore.setLoadingProgress(false)
                        adminStore.setLicenseMaxUser(res.licenseMaxUser || 0)
                        adminStore.setListUserProjectUsageLicense(res.userUsage || 0)
                    }).catch(error => {
                        setLoading(false)
                        adminStore.setLoadingProgress(false)
                    })
                }
                setLoading(false)
            }
            loadInitData()
        }
        return () => {
            adminStore.setLicenseMaxUser(0)
            adminStore.setListUserProjectUsageLicense(0)
        }
    }, [projectTeamsStore.showProjectTeamsDrawer])

    const onCloseProjectTeams = (openLogInfoDrawer) => {
        setLoading(false)
        setActiveTab('users')
        setDrawerWidth(0)
        projectTeamsStore.clearTeamList()
        projectStore.setShowUserGroupDrawer(false)
        projectTeamsStore.setShowProjectTeamsDrawer(false);
        !openLogInfoDrawer && projectSettingStore.toggleProjectSettingDrawer(true); //Show again project setting drawer    
    };

    const callbackTabClicked = (key, event) => {
        if (key === 'users') {
          setActiveTab(key);
          projectStore.setShowUserGroupDrawer(false)
        } else {
          setActiveTab(key);
          projectStore.setShowUserGroupDrawer(true)
        }
      };

    const onOpenLogInfoDrawer = () => {
        onCloseProjectTeams(true)
        projectStore.setShowLogInfoProjectDrawer({show: true, mode: 'user'})
    }    
    return (
        <Drawer
            title={
                <div className="help-btn-wrapper">
                    {t('project-users')}
                    <Tooltip title={t('commons.help')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                        <div>
                            <HelpButton helppage={"project_users"} />
                        </div>
                    </Tooltip>
                </div>
            }
            placement="left"
            width={drawerWidth}
            closable={true}
            onClose={e => onCloseProjectTeams()}
            visible={projectTeamsStore.showProjectTeamsDrawer}
            maskClosable={false}
            mask={false}
            destroyOnClose={true}
            forceRender
            className={projectTeamsStore.showProjectTeamsDrawer ? "project-team-drawer custom-wraper-splitPanel" : "project-team-drawer "}
            footer={
                <Button type='primary' onClick={onOpenLogInfoDrawer}>
                    {t('log')}
                </Button>
            }
            footerStyle={{ textAlign: 'right' }}
        >
            <TeamsPageWrapper>
                {isTabletOrMobile ? (
                    projectTeamsStore.showProjectTeamsDrawer ? (
                        <div className="splitpanel-mobile-left">
                            <Button onTouchStart={e => handleTouchStart(e)} type="dashed" shape="circle" className="btnSplitPanel">
                                <ColumnWidthOutlined />
                            </Button>
                        </div>
                    ) : " "

                ) : (<div onMouseDown={e => handleMouseDown(e)} className="splitpanel-left" />)
                }
                <Tabs defaultActiveKey={activeTab} activeKey={activeTab} onTabClick={callbackTabClicked}>
                    <Tabs.TabPane tab={t('users')} key="users">
                        <DrawerUserManagement loading={loading}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={t('user-groups')} key="user_groups">
                        <DrawerUserGroup drawerWidth={drawerWidth} loading={loading}/>
                    </Tabs.TabPane>
                </Tabs>
            </TeamsPageWrapper>
        </Drawer>
    )
}

export default inject(
    'projectTeamsStore',
    'projectStore',
    'usersStore',
    'projectSettingStore',
    'adminStore',
    'licenseStore',
    'commonStore',
    'userGroupStore'
)(observer(DrawerProjectTeams))
