import { AutoComplete, Button, Checkbox, Form, Input, message, Select } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import countryList from 'react-select-country-list';
import validator from '@/validator';
import { assetUrl } from '@/config';
import { NaviLink } from './CustomStyled';
import { useTranslation } from 'react-i18next';
import { renderTermsPolicyHref } from '@/lib/projectLib';

// Language configuration
const LANGUAGE_CONFIG = {
  countryLanguageMap: {
    'United States': 'en',
    Finland: 'fi',
    France: 'fr',
    Germany: 'de',
    Italy: 'it',
    Spain: 'es',
    Sweden: 'sv',
    'Viet Nam': 'vi'
  },
  emailDomainLanguageMap: {
    fi: 'fi',
    fr: 'fr',
    de: 'de',
    it: 'it',
    es: 'es',
    sv: 'sv',
    vn: 'vi'
  },
  availableLanguages: [
    { label: 'English', value: 'en' },
    { label: 'Finnish', value: 'fi' },
    { label: 'French', value: 'fr' },
    { label: 'German', value: 'de' },
    { label: 'Italian', value: 'it' },
    { label: 'Spanish', value: 'es' },
    { label: 'Swedish', value: 'sv' },
    { label: 'Vietnamese', value: 'vi' }
  ]
};

const TLD_TO_COUNTRY_MAP = {
  fi: 'Finland',
  fr: 'France',
  de: 'Germany',
  it: 'Italy',
  es: 'Spain',
  sv: 'Sweden',
  vn: 'Viet Nam',
  com: 'United States', // Default for generic TLDs like .com
  org: 'United States', // Default for generic TLDs like .org
};

const RegisterForm = ({ history, match, authStore, adminStore, organizationStore }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const authType = match.params.authType;

  const RegisterFormComponent = () => {
    const [confirmDirty, setConfirm] = useState(false);
    const [country, setCountry] = useState(null);
    const [language, setLanguage] = useState('en');
    const [maintenanceData, setMaintenanceData] = useState({});
    const [isMounted, setIsMounted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); // Track submission status

    const countries = useMemo(() =>
      countryList().getData().map(item => ({ value: item.label })),
      []
    );

    useEffect(() => {
      setIsMounted(true);
      return () => setIsMounted(false);
    }, []);

    const handleEmailChange = useCallback((e) => {
      const email = e.target.value;
      if (!email || !isMounted || isSubmitting) return;
    
      // Extract the TLD from the email (e.g., "fi" from "example@domain.fi")
      const domainParts = email.split('.');
      const tld = domainParts[domainParts.length - 1].toLowerCase();
    
      // Determine language from TLD
      const langFromDomain = LANGUAGE_CONFIG.emailDomainLanguageMap[tld] || 'en'; // Default to 'en' if no match
      const countryFromTld = TLD_TO_COUNTRY_MAP[tld] || 'United States'; // Default to 'United States' if no match
    
      // Update state and form fields
      setLanguage(langFromDomain);
      setCountry(countryFromTld);
      form.setFieldsValue({
        language: langFromDomain,
        orgCountry: countryFromTld,
      });
    }, [form, isMounted, isSubmitting]);

    const handleCountryChange = useCallback((selectedCountry) => {
      if (!isMounted || isSubmitting) return;
      setCountry(selectedCountry);
      const langFromCountry = LANGUAGE_CONFIG.countryLanguageMap[selectedCountry] || 'en';
      setLanguage(langFromCountry);
      form.setFieldsValue({ language: langFromCountry });
    }, [form, isMounted, isSubmitting]);

    const handleConfirmBlur = useCallback((e) => {
      if (!isMounted || isSubmitting) return;
      setConfirm(confirmDirty || !!e.target.value);
    }, [confirmDirty, isMounted, isSubmitting]);

    const compareToFirstPassword = useCallback((_, value) =>
      value && value !== form.getFieldValue('password')
        ? Promise.reject(t('passwords-do-not-match'))
        : Promise.resolve(),
      [form, t]
    );

    useEffect(() => {
      const invitationEmail = organizationStore.invitationDetail.email;
      if (invitationEmail && isMounted) {
        form.setFieldsValue({ email: invitationEmail });
        handleEmailChange({ target: { value: invitationEmail } });
      }
      return () => organizationStore.clearInvitationDetail();
    }, [organizationStore.invitationDetail, form, handleEmailChange, isMounted]);

    useEffect(() => {
      let isCancelled = false;
      const fetchMaintenanceData = async () => {
        try {
          const response = await fetch(`${assetUrl}maintananceInfo.json`, {
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'
            }
          });
          if (!response.ok) throw new Error('Network response was not ok');
          const data = await response.json();
          if (!isCancelled && isMounted) {
            setMaintenanceData(data);
          }
        } catch (error) {
          if (!isCancelled) {
            console.error('Failed to fetch maintenance data:', error);
          }
        }
      };
      fetchMaintenanceData();
      return () => {
        isCancelled = true;
      };
    }, [isMounted]);

    const handleSubmit = useCallback(async (values) => {
      if (!values.accept || !values.policyandterms) {
        message.error(t('please-read-and-accept-terms'));
        return;
      }

      setIsSubmitting(true); // Disable form on submit

      const submitValues = {
        username: values.username.trim().toLowerCase(),
        email: values.email.trim().toLowerCase(),
        password: values.password,
        confirmed: false,
        organizationName: values.organization,
        address: values.address,
        city: values.city,
        country: values.orgCountry,
        zipCode: values.zipCode,
        phone: values.phone || null,
        language: values.language
      };

      if (organizationStore.invitationDetail.email) {
        if (values.email !== organizationStore.invitationDetail.email) {
          message.error(t('email-not-same'));
          setIsSubmitting(false); // Re-enable form on validation error
          return;
        }
        submitValues.invitationId = organizationStore.invitationDetail.id;
      }

      try {
        await authStore.userRegister(submitValues, history);
        message.success(t('registration-successful'));
        // Form remains disabled after success
      } catch (error) {
        message.error(t('registration-failed'));
        console.error('Registration error:', error);
        setIsSubmitting(false); // Re-enable form on failure
      }
    }, [authStore, history, organizationStore, t]);

    const formItems = useMemo(() => [
      { name: 'username', label: t('username'), required: true, validator: validator.validateEmptyString },
      { name: 'email', label: t('email'), required: true, validator: validator.validateEmail, onChange: handleEmailChange, disabled: !!organizationStore.invitationDetail.email || isSubmitting },
      { name: 'password', label: t('password'), required: true, type: 'password' },
      { name: 'confirm', label: t('confirm-password'), required: true, validator: compareToFirstPassword, type: 'password', onBlur: handleConfirmBlur },
      { name: 'organization', label: t('organization'), required: true },
      { name: 'address', label: t('address'), required: true },
      { name: 'zipCode', label: t('zip-code'), required: true },
      { name: 'city', label: t('city'), required: true }
    ], [t, handleEmailChange, compareToFirstPassword, handleConfirmBlur, organizationStore.invitationDetail.email, isSubmitting]);

    return (
      <Form
        form={form}
        name="register-form"
        layout="vertical"
        className={`auth-form auth-form-${authType}`}
        onFinish={handleSubmit}
        initialValues={{ language }}
        disabled={isSubmitting} // Disable entire form
      >
        {formItems.map(item => (
          <Form.Item
            key={item.name}
            name={item.name}
            label={item.label}
            rules={[
              { required: item.required, message: t(`this-field-is-required`) },
              ...(item.validator ? [{ validator: item.validator }] : [])
            ]}
          >
            <Input
              type={item.type || 'text'}
              placeholder={t(`${item.name}`)}
              disabled={item.disabled || isSubmitting} // Individual input disable
              onChange={item.onChange}
              onBlur={item.onBlur}
            />
          </Form.Item>
        ))}

        <Form.Item
          name="orgCountry"
          label={t('country')}
          rules={[{ required: true, message: t('this-field-is-required') }]}
        >
          <AutoComplete
            placeholder={t('country')}
            value={country}
            options={countries}
            onChange={handleCountryChange}
            filterOption={(input, option) =>
              option.value.toLowerCase().includes(input.toLowerCase())
            }
            disabled={isSubmitting}
          />
        </Form.Item>

        <Form.Item
          name="language"
          label={t('language')}
          rules={[{ required: true, message: t('this-field-is-required') }]}
        >
          <Select
            placeholder={t('language')}
            options={LANGUAGE_CONFIG.availableLanguages}
            onChange={setLanguage}
            disabled={isSubmitting}
          />
        </Form.Item>

        <Form.Item
          name="phone"
          label={t('phone')}
          rules={[{ required: false }]}
        >
          <Input
            type="tel"
            placeholder={t('phone')}
            disabled={isSubmitting}
          />
        </Form.Item>

        <Form.Item name="accept" valuePropName="checked" initialValue={false}>
          <Checkbox disabled={isSubmitting}>
            {t('have-read')}{' '}
            <a
              target="_blank"
              href={renderTermsPolicyHref(maintenanceData, 'terms')}
              style={{ color: '#ff8d4f' }}
              rel="noopener noreferrer"
            >
              {t('terms-and-conditions')}
            </a>
          </Checkbox>
        </Form.Item>

        <Form.Item name="policyandterms" valuePropName="checked" initialValue={false}>
          <Checkbox disabled={isSubmitting}>
            {t('have-read')}{' '}
            <a
              target="_blank"
              href={renderTermsPolicyHref(maintenanceData, 'policy')}
              style={{ color: '#ff8d4f' }}
              rel="noopener noreferrer"
            >
              {t('privacy-policy')}
            </a>
          </Checkbox>
        </Form.Item>

        <Button
          size="large"
          block
          type="primary"
          htmlType="submit"
          style={{ marginTop: 16 }}
          loading={isSubmitting} // Show loading state
          disabled={isSubmitting}
        >
          {t('register')}
        </Button>

        <NaviLink margin="10px 0 0">
          {t('already-have-an-account')}{' '}
          <Link to="/auth/login" style={{ color: '#ff8d4f' }}>
            {t('back-to-login-page')}
          </Link>
        </NaviLink>
      </Form>
    );
  };

  return <RegisterFormComponent />;
};

export default withRouter(
  inject('authStore', 'adminStore', 'organizationStore')(observer(RegisterForm))
);