import { Button, Form, Input, message, Modal, Select, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import axios from 'axios';
import { CameraEventType } from 'cesium';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fi';
import 'moment/locale/sv';
import 'moment/locale/vi';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { withRouter } from 'react-router-dom';
import { apiUrl } from '@/config';
import { is6DPLANNER } from '@/lib/projectLib';
import validator from '@/validator';
import { LoadingSpinner, UploadButton } from '@/components/elements';
import { ButtonGroup, NavigationStyled } from './UserSettingsPageStyled';

const { Option } = Select;
const { confirm } = Modal;

// Language configuration
const LANGUAGE_CONFIG = {
  emailDomainLanguageMap: {
    fi: 'fi',
    fr: 'fr',
    de: 'de',
    it: 'it',
    es: 'es',
    sv: 'sv',
    vn: 'vi',
    com: 'en', // Default for .com domains
  },
};

const UserSettingsForm = ({
  usersStore,
  commonStore,
  currentUser,
  userAvatar,
  history,
  projectStore,
  setShowModalChangePassword,
  paddingForm,
}) => {
  const { t } = useTranslation();
  const [uploadFile, setUploadFile] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  moment.locale(`${commonStore.language}`);

  const CustomForm = () => {
    const [form] = Form.useForm();
    const [showCustom, setShowCustom] = useState(false);
    const [allowUnderground, setAllowUnderground] = useState(
      projectStore.navigationStyles?.allowUnderground ?? true
    );
    const [isMounted, setIsMounted] = useState(false); // Track mount status

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-device-width: 1224px)' });
    const isMobile = useMediaQuery({ query: '(max-width: 640px)' });

    // Set mounted status
    useEffect(() => {
      setIsMounted(true);
      return () => setIsMounted(false);
    }, []);

    const showDeleteConfirm = () => {
      confirm({
        title: t('delete-my-account'),
        content: t('are-you-sure-delete-account'),
        onOk() {
          usersStore.confirmDeleteMyAccount({
            email: usersStore.currentUser.email,
            username: usersStore.currentUser.username,
          });
        },
        onCancel() {},
        okText: t('commons.ok'),
        cancelText: t('commons.cancel'),
        zIndex: '9999',
      });
    };

    const handleEmailChange = (e) => {
      const email = e.target.value;
      if (!email || !isMounted) return;

      const domain = email.split('.').pop();
      const langFromDomain = LANGUAGE_CONFIG.emailDomainLanguageMap[domain] || 'en';
      form.setFieldsValue({ language: langFromDomain });
    };

    const handleSubmit = async (values) => {
      const newDataMe = {
        username: values.username,
        email: values.email,
        language: values.language,
        firstName: values.firstName,
        lastName: values.lastName,
      };

      if (paddingForm) setIsLoading(true);
      usersStore.setLoadingProgress(true);

      try {
        const res = await usersStore.updateUserMe(newDataMe);
        commonStore.setLanguage(res.language || 'en');
        moment.locale(`${commonStore.language}`);

        if (paddingForm && !uploadFile) {
          await usersStore.setCurrentUser();
          setIsLoading(false);
        }

        // Upload avatar if present
        if (uploadFile) {
          const formData = new FormData();
          formData.append('files', uploadFile);
          formData.append('ref', 'user');
          formData.append('source', 'users-permissions');
          formData.append('refId', res.id);
          formData.append('field', 'avatar');

          await axios({
            method: 'POST',
            url: `${apiUrl}/upload`,
            headers: {
              Authorization: `Bearer ${commonStore.token}`,
            },
            data: formData,
          });
          if (paddingForm) {
            await usersStore.setCurrentUser();
            setIsLoading(false);
          }
        }

        // Handle navigation styles
        if (projectStore.projectDetail?.id) {
          let control = {};
          if (values.navigationstyle === 'custom') {
            control = {
              pan: values.pan,
              zoom: values.zoom,
              tilt: values.tilt,
              rotate: values.rotate,
            };
          }

          const navigationInstructions = projectStore?.projectDetail?.metadata?.navigationInstructions || [];
          const resultNavigationInstructions = projectStore.navigationStyles || navigationInstructions[0];
          values.distanceLimit = resultNavigationInstructions?.distanceLimit || -1;
          values.allowUnderground = resultNavigationInstructions?.allowUnderground ?? true;

          const navStyle = {
            userId: usersStore.currentUser.id,
            type: values.navigationstyle,
            control,
            distanceLimit: values.distanceLimit,
            allowUnderground: values.allowUnderground,
          };

          let projectMetadata = projectStore.projectDetail.metadata;
          if (values.navigationstyle) {
            if (projectMetadata.navigationInstructions?.length > 0) {
              if (projectMetadata.navigationInstructions.length > 1) {
                projectMetadata.navigationInstructions[0] = {
                  ...projectMetadata.navigationInstructions[0],
                  type: values.navigationstyle,
                  control,
                  distanceLimit: values.distanceLimit,
                  allowUnderground: values.allowUnderground,
                };
              }
              const objIndex = projectMetadata.navigationInstructions.findIndex(
                (obj) => obj.userId === usersStore.currentUser.id
              );
              if (objIndex > -1) {
                projectMetadata.navigationInstructions[objIndex] = navStyle;
              } else {
                projectMetadata.navigationInstructions.push(navStyle);
              }
            } else {
              projectMetadata.navigationInstructions = [navStyle];
            }
          } else {
            projectMetadata.navigationInstructions = projectMetadata?.navigationInstructions?.filter(
              (x) => x.userId !== usersStore.currentUser.id
            );
          }

          const newProjectMetadataNavigation = { metadata: projectMetadata };

          if (
            projectStore.projectDetail.projectuserroles?.find(
              (pur) => pur.email === usersStore.currentUser.email && pur.inviteStatus === 'accepted'
            )
          ) {
            const res = await projectStore.updateProjectData(newProjectMetadataNavigation);
            projectStore.projectDetail.metadata = res.metadata;
            const prjNavigationInstructions = res.metadata.navigationInstructions;
            const resultNav = prjNavigationInstructions[0];
            projectStore.setNavigationStyles(
              resultNav || { type: 'xdTwin', control: {}, distanceLimit: -1, allowUnderground: true }
            );
          } else {
            projectStore.setNavigationStyles(navStyle);
          }
        }

        usersStore.setLoadingProgress(false);
        hiddenDrawer();
        message.success(t('updated-successfully'));
        usersStore.setCurrentUser(history);
      } catch (error) {
        usersStore.setLoadingProgress(false);
        if (error.data?.message && Array.isArray(error.data.message)) {
          message.error(t(error.data.message[0].messages[0].id));
        } else if (error.data?.message) {
          message.error(t(error.data.message));
        } else {
          message.error(t('username-email-taken'));
        }
        if (paddingForm) setIsLoading(false);
      }
    };

    const handleChangeNavigation = (value) => {
      setShowCustom(value === 'custom');
    };

    const handleUploadCover = (file) => {
      setUploadFile(file);
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        usersStore.updateUserAvatar(reader.result);
      });
      reader.readAsDataURL(file);
      return false;
    };

    const beforeCrop = (file) => {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        message.error(t('you-can-only-upload-jpg-png-file'));
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error(t('image-must-smaller-than-2MB'));
        return false;
      }
      return isJpgOrPng && isLt2M;
    };

    const hiddenDrawer = () => {
      commonStore.setShowUserSettingPageDrawer(false);
      if (projectStore.projectDetail?.id) {
        setAllowUnderground(projectStore.navigationStyles?.allowUnderground ?? true);
      }
    };

    useEffect(() => {
      if (commonStore.showUserSettingPageDrawer && isMounted) {
        const navigationInstructions = projectStore.projectDetail?.metadata?.navigationInstructions || [];
        const resultNavigationInstructions = projectStore.navigationStyles || navigationInstructions[0];
        if (resultNavigationInstructions) {
          form.setFieldsValue({
            navigationstyle: resultNavigationInstructions.type || 'xdTwin',
            distanceLimit: resultNavigationInstructions.distanceLimit || -1,
            allowUnderground: resultNavigationInstructions.allowUnderground ?? true,
          });
          if (resultNavigationInstructions.type === 'custom') {
            setShowCustom(true);
            form.setFieldsValue({
              pan: resultNavigationInstructions.control?.pan,
              zoom: resultNavigationInstructions.control?.zoom,
              tilt: resultNavigationInstructions.control?.tilt,
              rotate: resultNavigationInstructions.control?.rotate,
            });
          }
        } else {
          form.setFieldsValue({ navigationstyle: 'xdTwin', distanceLimit: -1, allowUnderground: true });
        }

        if (!userAvatar && currentUser?.avatar) {
          usersStore.setUserAvatar(currentUser.avatar);
        }
        if (projectStore.projectDetail?.id) {
          setAllowUnderground(projectStore.navigationStyles?.allowUnderground ?? true);
        }

        // Set initial form values
        form.setFieldsValue({
          username: currentUser.username,
          email: currentUser.email,
          firstName: currentUser.firstName || '',
          lastName: currentUser.lastName || '',
          language: currentUser.language || 'en',
        });
      }
    }, [commonStore.showUserSettingPageDrawer, currentUser, form, projectStore, usersStore, isMounted]);

    return (
      <>
        <Form
          layout="vertical"
          onFinish={handleSubmit}
          form={form}
          style={
            paddingForm
              ? isMobile
                ? { padding: paddingForm }
                : {
                    position: 'absolute',
                    minWidth: 600,
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }
              : null
          }
        >
          <p>{t('profile-photo')}</p>
          <Form.Item name="avatar" valuePropName="fileList">
            <ImgCrop
              modalTitle={t('edit-image')}
              modalOk={t('commons.ok')}
              modalCancel={t('commons.cancel')}
              grid
              beforeCrop={beforeCrop}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader input-upload-image-square"
                accept="image/png, image/jpeg"
                showUploadList={false}
                beforeUpload={handleUploadCover}
              >
                {userAvatar ? (
                  userAvatar.base64 ? (
                    <img src={userAvatar.base64} alt="" />
                  ) : (
                    <img src={userAvatar.url} alt="" />
                  )
                ) : (
                  <UploadButton />
                )}
              </Upload>
            </ImgCrop>
          </Form.Item>

          <Form.Item
            label={t('username')}
            name="username"
            rules={[
              { required: true, message: t('please-input-your-username') },
              { validator: validator.validateEmptyString },
            ]}
          >
            <Input placeholder={t('your-username')} />
          </Form.Item>

          <Form.Item
            label={t('email')}
            name="email"
            rules={[
              { required: true, message: t('please-input-your-email') },
              { validator: validator.validateEmail },
            ]}
          >
            <Input placeholder={t('your-email')} onChange={handleEmailChange} />
          </Form.Item>

          <Form.Item
            label={t('first-name')}
            name="firstName"
            rules={[{ validator: validator.validateEmptyString }]}
          >
            <Input placeholder={t('input-first-name')} />
          </Form.Item>

          <Form.Item
            label={t('last-name')}
            name="lastName"
            rules={[{ validator: validator.validateEmptyString }]}
          >
            <Input placeholder={t('input-last-name')} />
          </Form.Item>

          <Form.Item
            label={t('language')}
            name="language"
            rules={[{ required: true, message: t('please-select-language') }]}
          >
            <Select className="selectdrp">
              <Option value="en">English</Option>
              <Option value="fi">Finnish</Option>
              <Option value="fr">French</Option>
              <Option value="de">German</Option>
              <Option value="it">Italian</Option>
              <Option value="es">Spanish</Option>
              <Option value="sv">Swedish</Option>
              <Option value="vi">Vietnamese</Option>
            </Select>
          </Form.Item>

          {projectStore.projectDetail?.id && (
            <>
              <Form.Item
                label={t('navigation-style')}
                name="navigationstyle"
                rules={[{ validator: validator.validateEmptyString }]}
              >
                <Select className="selectdrp" onChange={handleChangeNavigation}>
                  <Option value="xdTwin">{is6DPLANNER(window?.location?.origin) ? '6DPlanner' : 'xD Twin'}</Option>
                  <Option value="cesium">Cesium</Option>
                  <Option value="connect">Connect</Option>
                  <Option value="custom">Custom</Option>
                </Select>
              </Form.Item>
            </>
          )}

          {showCustom && (
            <NavigationStyled>
              <div className="code-box-title">{t('custom-navigation-style')}</div>
              <div className="code-box-description">
                <Form.Item
                  label={t('pan')}
                  name="pan"
                  rules={[{ required: true, message: t('please-select-pan-events') }]}
                >
                  <Select mode="multiple" style={{ width: '100%' }} placeholder={t('please-select')}>
                    {Object.keys(CameraEventType).map((key) => (
                      <Option key={key} value={CameraEventType[key]}>
                        {key}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t('zoom')}
                  name="zoom"
                  rules={[{ required: true, message: t('please-select-zoom-events') }]}
                >
                  <Select mode="multiple" style={{ width: '100%' }} placeholder={t('please-select')}>
                    {Object.keys(CameraEventType).map((key) => (
                      <Option key={key} value={CameraEventType[key]}>
                        {key}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t('tilt')}
                  name="tilt"
                  rules={[{ required: true, message: t('please-select-tilt-events') }]}
                >
                  <Select mode="multiple" style={{ width: '100%' }} placeholder={t('please-select')}>
                    {Object.keys(CameraEventType).map((key) => (
                      <Option key={key} value={CameraEventType[key]}>
                        {key}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t('rotate')}
                  name="rotate"
                  rules={[{ required: true, message: t('please-select-rotate-events') }]}
                >
                  <Select mode="multiple" style={{ width: '100%' }} placeholder={t('please-select')}>
                    {Object.keys(CameraEventType).map((key) => (
                      <Option key={key} value={CameraEventType[key]}>
                        {key}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </NavigationStyled>
          )}

          <ButtonGroup>
            <Button
              style={{ marginBottom: '5px', width: isDesktopOrLaptop ? 'auto' : '100%' }}
              danger
              ghost
              onClick={showDeleteConfirm}
            >
              {t('delete-my-account')}
            </Button>
            <Button
              style={{
                float: 'right',
                marginBottom: '5px',
                width: isDesktopOrLaptop ? 'auto' : '100%',
              }}
              type="primary"
              htmlType="submit"
              className="float-right"
            >
              {t('commons.save')}
            </Button>
            <Button
              style={{
                float: 'right',
                marginBottom: '5px',
                marginRight: '5px',
                width: isDesktopOrLaptop ? 'auto' : '100%',
              }}
              onClick={() => setShowModalChangePassword(true)}
              type="primary"
            >
              {t('change-password')}
            </Button>
            <Button
              style={{
                float: 'right',
                marginBottom: '5px',
                marginRight: '5px',
                width: isDesktopOrLaptop ? 'auto' : '100%',
              }}
              type="default"
              onClick={hiddenDrawer}
              className="float-right"
            >
              {t('commons.cancel')}
            </Button>
          </ButtonGroup>
        </Form>
        {isLoading ? <LoadingSpinner theme={commonStore.appTheme} type={'page'} /> : <div></div>}
      </>
    );
  };

  return (
    <Fragment>
      <CustomForm />
    </Fragment>
  );
};

export default withRouter(
  inject('usersStore', 'commonStore', 'projectStore')(observer(UserSettingsForm))
);