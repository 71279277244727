import LogInfoWrapper, { useLogInfo } from '@/components/organisms/LogInfoWrapper'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/es'
import 'moment/locale/fi'
import 'moment/locale/sv'
import 'moment/locale/vi'
import { useEffect, useMemo } from 'react'
import { withRouter } from 'react-router-dom'

const OrganizationLogTab = ({
  adminStore,
  organizationStore,
  commonStore,
  projectStore,
  usersBelongOrganizationData,
}) => {
  moment.locale(`${commonStore.language}`)

  const organizationId = useMemo(
    () => organizationStore.paramsOrganizationDetail,
    [organizationStore.paramsOrganizationDetail]
  )

  const userList = useMemo(() => {
    if (!usersBelongOrganizationData?.length) return []
    return usersBelongOrganizationData.map(({ user }) => ({
      value: user?.id,
      label: user?.username,
    }))
  }, [usersBelongOrganizationData])

  const logInfoHookProps = {
    initialTimeRange: '15m',
    organizationId,
    projectStore,
    mode: 'organization',
    userList,
  }

  const {
    loadLogInfo,
  } = useLogInfo(logInfoHookProps)

  useEffect(() => {
    if (adminStore.organizationActiveTab === 'log') {
      loadLogInfo()
    }
  }, [adminStore.organizationActiveTab])

  return (
    <LogInfoWrapper
      logData={projectStore.logInfo?.data?.result || []}
      loading={projectStore.isLogInfoLoading}
      logInfoHookProps={logInfoHookProps}
    />
  )
}

export default withRouter(
  inject(
    'organizationStore',
    'adminStore',
    'commonStore',
    'projectStore'
  )(observer(OrganizationLogTab))
)
