import { Avatar, Tooltip, Button } from 'antd';
import { inject, observer } from 'mobx-react';
import React, { Fragment, useEffect, useState } from 'react';
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { withRouter } from 'react-router-dom';
import { ReactComponent as FeedbackGeneric } from '../../../../assets/svgs/feedback-generic-s0.svg';
import { ReactComponent as ProjectDashboard } from '../../../../assets/svgs/project-dashboard-s0.svg';
import { ReactComponent as TopicAdd } from '../../../../assets/svgs/topic-add-s0.svg';
import { ReactComponent as TopicList } from '../../../../assets/svgs/topic-list-s0.svg';
import { ReactComponent as TopicReport } from '../../../../assets/svgs/topic-report-s0.svg';
import { ReactComponent as CalculationTool } from '../../../../assets/svgs/Calculations.svg';
import { ReactComponent as QueryTool } from '../../../../assets/svgs/Query.svg';
import { ReactComponent as FeedbackReport } from '../../../../assets/svgs/feedback_report.svg';
import { SVGIcon } from '@/components/elements';
import { ToolbarRightContainer, ToolbarRightItem } from './style';
import { useTranslation } from 'react-i18next';

const ToolbarRight = props => {
    const { t } = useTranslation();
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isHeightSmall = useMediaQuery({ query: '(max-device-height: 570px)' })

    const {
        commonStore,
        projectStore,
        topicStore,
        feedbackStore,
        currentPage,
        adminStore,
        sketchingStore,
        uiStore,
        objectQueryStore
    } = props
    const [existGenericFeedback, setExistGenericFeedback] = useState(false)

    useEffect(() => {
        if (props.match.params.projectId) {
            feedbackStore.checkExistGenericFeedback(props.match.params.projectId).then(res => {
                if (res && res.data.id && res.data?.isPublic) {
                    // exist generic feedback 
                    if (commonStore.checkToken()) {
                        setExistGenericFeedback(true)
                    } else { //if user not login Check generic feedback isPublic?
                        if (res.data.isPublic) {
                            setExistGenericFeedback(true)
                        } else {
                            setExistGenericFeedback(false)
                        }
                    }
                } else {
                    setExistGenericFeedback(false)
                }
            }).catch(err => {
            })
        }
        return () => {
            setExistGenericFeedback(false)
        }
    }, [feedbackStore.feedbackEditorList])

    const handleClickShowListTopic = () => {
        topicStore.setShownListTopic(true);
        sketchingStore.setSketchToolVisible(false)
        uiStore.setClippingPlaneToolVisible(false);
        objectQueryStore.setIsShowListQuery(false)
    }

    const handleClickShowNewTopicEditor = () => {
        let defaulTopic = projectStore.projectDetail?.metadata?.topicDefault?.find(item => item.userId === projectStore.projectDetail.currentUser._id);
        if (defaulTopic) {
            topicStore.setShowTopicEditor(true, false, {...topicStore.viewingTopicForm, topictype: defaulTopic.topictype, topicpriority: defaulTopic.topicpriority, topicstatus: defaulTopic.topicstatus, workflow: defaulTopic.workflow });
            topicStore.setSelectedWorkflowValue(defaulTopic?.workflow)
        } else {
            topicStore.setShowTopicEditor(true, false)
        }
        sketchingStore.setSketchToolVisible(false)
        uiStore.setClippingPlaneToolVisible(false);
        objectQueryStore.setIsShowListQuery(false)
    }

    const handleClickShowGeniricFeedbackAnswer = () => {
        uiStore.setClippingPlaneToolVisible(false);
        sketchingStore.setSketchToolVisible(false)
        feedbackStore.setShowFeedbackAnswer(true, true, 'add', undefined)
        objectQueryStore.setIsShowListQuery(false)
    }

    const clickShowProjectDashBoard = () => {
        projectStore.setShowProjectDashBoard(true)
        sketchingStore.setSketchToolVisible(false)
        uiStore.setClippingPlaneToolVisible(false);
        objectQueryStore.setIsShowListQuery(false)
    }

    const clickShowGenericReport = () => {
        projectStore.setShowGenericReport(true)
        sketchingStore.setSketchToolVisible(false)
        uiStore.setClippingPlaneToolVisible(false);
        objectQueryStore.setIsShowListQuery(false)
    }

    const clickShowDrawerCalculation = () => {
        projectStore.setShowDrawerCalculation(true)
        sketchingStore.setSketchToolVisible(false)
        uiStore.setClippingPlaneToolVisible(false);
        objectQueryStore.setIsShowListQuery(false)
    }

    const handleClickShowListQuery = () => {
        objectQueryStore.setIsShowListQuery(true)
        topicStore.setShownListTopic(false);
        sketchingStore.setSketchToolVisible(false)
        uiStore.setClippingPlaneToolVisible(false);
    }

    const checkingFeatureRole = (type) => {
        if (!type) return false
        return adminStore.checkingFeatureRole(projectStore, type)
    }

    const viewByPage = () => {
        switch (currentPage) {
            case 'projectDetail':
                return projectStore.projectDetail?.id ? (
                    <Fragment>
                        <Avatar.Group
                            className="avatar-rightGroup"
                            maxCount={(isMobileOnly && isHeightSmall) ? (isPortrait ? 2 : undefined) : undefined}
                        >
                            <ToolbarRightItem>
                                {existGenericFeedback && (checkingFeatureRole("feature_feedback")) && projectStore.isExistLicenses && (
                                    <Tooltip title={t('feedback')} placement="right" zIndex={9998} trigger={['click', 'hover']} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                        <Button
                                            style={{ verticalAlign: 0 }}
                                            icon={<SVGIcon content={<FeedbackGeneric />} width={32} height={32} />}
                                            onClick={handleClickShowGeniricFeedbackAnswer}
                                        />
                                    </Tooltip>
                                )
                                }
                            </ToolbarRightItem>

                            {!projectStore.visitedMode &&
                                <>
                                    {
                                        feedbackStore.feedbackVisualization?.length > 0 && checkingFeatureRole("feature_feedback_report") && (
                                        <ToolbarRightItem>
                                            <Tooltip title={t('feedback-report')} placement="right" zIndex={9998} trigger={['click', 'hover']} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                <Button
                                                    style={{ verticalAlign: 0 }}
                                                    icon={<SVGIcon content={<FeedbackReport />} width={25} height={25} />}
                                                    onClick={() => { feedbackStore.setShowDrawerFeedbackReport(true); sketchingStore.setSketchToolVisible(false); uiStore.setClippingPlaneToolVisible(false); }}
                                                />
                                            </Tooltip>
                                        </ToolbarRightItem>
                                        )
                                    }

                                    <ToolbarRightItem>
                                        {
                                            (checkingFeatureRole("feature_topic_list_edit") && checkingFeatureRole("feature_add_topic")) && projectStore.isExistLicenses && (
                                                <Tooltip title={t('new-topic')} placement="right" zIndex={9998} trigger={['click', 'hover']} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                    <Button
                                                        style={{ verticalAlign: 0 }}
                                                        icon={<SVGIcon content={<TopicAdd />} width={32} height={32} />}
                                                        onClick={handleClickShowNewTopicEditor}
                                                    />
                                                </Tooltip>
                                            )
                                        }
                                    </ToolbarRightItem>

                                    <ToolbarRightItem>
                                        {
                                            (checkingFeatureRole("feature_topic_list_edit") || checkingFeatureRole("feature_topic_list_view")) && (
                                                <Tooltip title={t('topic-list')} placement="right" zIndex={9998} trigger={['click', 'hover']} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                    <Button
                                                        style={{ verticalAlign: 0 }}
                                                        icon={<SVGIcon content={<TopicList />} width={32} height={32} />}
                                                        onClick={handleClickShowListTopic}
                                                    />
                                                </Tooltip>
                                            )
                                        }
                                    </ToolbarRightItem>

                                    <ToolbarRightItem>
                                        {
                                            (checkingFeatureRole("feature_topic_list_edit") || checkingFeatureRole("feature_topic_list_view")) && checkingFeatureRole("feature_topic_report") && (
                                                <Tooltip title={t('topic-report')} placement="right" zIndex={9998} trigger={['click', 'hover']} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                    <Button
                                                        style={{ verticalAlign: 0 }}
                                                        icon={<SVGIcon content={<TopicReport />} width={32} height={32} />}
                                                        onClick={() => { topicStore.setIsShowDrawerTopicReport(true); sketchingStore.setSketchToolVisible(false); uiStore.setClippingPlaneToolVisible(false); }}
                                                    />
                                                </Tooltip>
                                            )
                                        }
                                    </ToolbarRightItem>

                                    <ToolbarRightItem>
                                        {
                                            checkingFeatureRole("feature_project_dashboard") && (
                                                <Tooltip title={t('project-dashboard')} placement="right" zIndex={9998} trigger={['click', 'hover']} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                    <Button
                                                        style={{ verticalAlign: 0 }}
                                                        icon={<SVGIcon content={<ProjectDashboard />} width={32} height={32} />}
                                                        onClick={clickShowProjectDashBoard}
                                                    />
                                                </Tooltip>
                                            )
                                        }
                                    </ToolbarRightItem>
                                    {/* <ToolbarRightItem>
                                        {
                                            checkingFeatureRole("feature_calculation_tool") && (
                                                <Tooltip title={t('calculations')} placement="right" zIndex={9998} trigger={['click', 'hover']} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                    <Button
                                                        style={{ verticalAlign: 0 }}
                                                        icon={<SVGIcon content={<CalculationTool />} width={32} height={32} />}
                                                        onClick={clickShowGenericReport}
                                                    />
                                                </Tooltip>
                                            )
                                        }
                                    </ToolbarRightItem> */}
                                    <ToolbarRightItem>
                                        {
                                            checkingFeatureRole("feature_query_tool") && (
                                                <Tooltip title={t('query')} placement="right" zIndex={9998} trigger={['click', 'hover']} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                    <Button
                                                        style={{ verticalAlign: 0 }}
                                                        icon={<SVGIcon content={<QueryTool />} width={32} height={32} />}
                                                        onClick={handleClickShowListQuery}
                                                    />
                                                </Tooltip>
                                            )
                                        }
                                    </ToolbarRightItem>
                                    <ToolbarRightItem>
                                        {
                                            checkingFeatureRole("feature_calculation_tool")  && (
                                                <Tooltip title={t('calculations-v2')} placement="right" zIndex={9998} trigger={['click', 'hover']} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                                                    <Button
                                                        style={{ verticalAlign: 0 }}
                                                        icon={<SVGIcon content={<CalculationTool />} width={32} height={32} />}
                                                        onClick={clickShowDrawerCalculation}
                                                    />
                                                </Tooltip>
                                            )
                                        }
                                    </ToolbarRightItem>
                                </>
                            }
                        </Avatar.Group>
                    </Fragment>
                ) : ''
            default:
                break
        }
    }

    return (
        <Fragment>
            <ToolbarRightContainer
                style={{
                    display: projectStore.cleanMode ? 'none' : 'block',
                }}>
                {currentPage ? viewByPage() : ''}
            </ToolbarRightContainer>
        </Fragment>
    )
}

export default withRouter(
    inject('commonStore', 'projectStore', 'topicStore', 'feedbackStore', 'adminStore','sketchingStore', 'uiStore', 'objectQueryStore')(observer(ToolbarRight))
)
