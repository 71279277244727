import { observable, action, decorate, toJS } from 'mobx'
import { message } from 'antd'
// Stores
import commonStore from './commonStore'
import usersStore from './usersStore'
import projectStore from './projectStore'
// Requests
import { AuthRequest, OrganizationRequest } from '@/requests'
import uuid from 'uuid'
import settings from '@/siteConfig'
import { assetUrl } from '@/config'
import moment from 'moment'
import { t } from 'i18next';

class AuthStore {
  isLoading = false
  errors = undefined
  sesstionId = undefined
  setSessionId = id => {
    this.sesstionId = id
  }
  setLoadingProgress = state => {
    this.isLoading = state
  }
  hanldeRegisterUser = async (valueSubmit, userData) => {
    let key = uuid()
    let orgParam = {
      name: valueSubmit.organizationName,
      user: userData.user.id,
      email: valueSubmit.email,
      address: valueSubmit.address,
      city: valueSubmit.city,
      country: valueSubmit.country,
      zipCode: valueSubmit.zipCode,
      phone: valueSubmit.phone,
    }
    let licenseParam = {
      licensesStatus: settings.licenseConfig.licensesStatus,
      licensesType: settings.licenseConfig.licensesType,
      licenceKey: key,
      numberProject: settings.licenseConfig.numberProject,
      storageQuota: settings.licenseConfig.storageQuota,
      timeLimit: settings.licenseConfig.timeLimit,
      description: settings.licenseConfig.description,
      numberUser: settings.licenseConfig.numberUser

    }
    let _param = {
      orgParam,
      licenseParam,
      invitationId: valueSubmit.invitationId
    }
    OrganizationRequest.createAtRegisterUser(_param).then(resp => {
      // commonStore.setShowOrganizationPageDrawer(true)
    })
      .catch(error => console.log(error))
  }

  userRegister = (info, history) => {
    this.setLoadingProgress(true)
    AuthRequest.register(info)
      .then(response => {
        localStorage.setItem('jwt', response.data.jwt)
        commonStore.setToken(response.data.jwt)
        // usersStore.setCurrentUser(history)
        return response.data
      })
      .then((res) => {
        this.hanldeRegisterUser(info, res).then(resp => {
          this.setLoadingProgress(false);
          message.warning(t("confirm-your-email-before-start-using", { email: res?.user?.email || info.email }), 5);

          setTimeout(() => {
            history.push('/auth/login');
          }, 5000);
        })
      })
      .catch(error => {
        if (Array.isArray(error?.data?.data[0]?.messages)) {
          let v = error?.data?.data[0]?.messages[0]?.message || t('something-went-wrong')
          if (v) message.error(t(v))
        } else {
          message.error(t('something-went-wrong'))
        }
        this.setLoadingProgress(false)
      })
  }

  userLogin(username, password, remember, history, state) {
    const startTime = Date.now();
    const pathname = state?.from?.pathname;
    this.setLoadingProgress(true)
    AuthRequest.login(username?.trim().toLowerCase(), password)
      .then(response => {
        if (response.data?.user?.isActive) {
          commonStore.setRemember(remember)
          commonStore.setToken(response.data.jwt)
          commonStore.setLanguage(response.data.user.language ? response.data.user.language : 'en')
          projectStore.setVisitedMode(false)
          if (commonStore.isRemember) {
            localStorage.setItem('jwt', response.data.jwt)
          } else {
            sessionStorage.setItem('jwt', response.data.jwt)
          }

          usersStore.setCurrentUser(history)
          const { user } = response.data;
          commonStore.loggingFunction('login', 'success', startTime, user)
        }
        return response.data
      })
      .then(data => {
        if (data?.user?.isActive) {
          fetch(`${assetUrl}maintananceInfo.json`
            , {
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
              }
            }
          )
            .then(function (response) {
              return response.json();
            })
            .then(function (myJson) {
              let u = data?.user?.data
              if (myJson.startDate && myJson.endDate && myJson.active && moment(Date.now) < moment(myJson.endDate)) {
                let startTime = new Date(parseInt(myJson.startDate))
                let endTime = new Date(parseInt(myJson.endDate))

                let isBetween = moment(Date.now()).isBetween(moment(startTime), moment(endTime))
                const isActive = myJson.active === true || (myJson.active && myJson.active.toString().toLowerCase() === 'true')
                if (isBetween && isActive) {
                  return history.push(`/notification`)
                }
              } else {
                // usersStore.updatelLastLoggin(data.user.id, { lastLoggin: Date.now() })
                if (data.user.role.type === "web_admin") {
                  message.success(t('welcome-username', { username: data.user.username }))
                  if (u && (u.policy !== myJson.policy || u.terms !== myJson.terms)) {
                    history.push(pathname ? pathname : '/')
                    return window.location.reload()
                  } else {
                    return history.push(pathname ? pathname : '/')
                  }
                }
                message.success(t('welcome-username', { username: data.user.username }))
                if (u && (u.policy !== myJson.policy || u.terms !== myJson.terms)) {
                  history.push(pathname ? pathname : '/')
                  return window.location.reload()
                } else {
                  return history.push(pathname ? pathname : '/')
                }
              }
            });
        } else {
          message.warning(t('sign-in-your-email-to-verify-your-account'), 5)
        }
      })
      .catch(error => {
        let errorMessage = t('something-went-wrong'); // Default message

        // Check if error.data exists and has the expected structure
        if (error?.data?.data && Array.isArray(error.data.data) && error.data.data[0]?.messages) {
          const messages = error.data.data[0].messages;
          if (Array.isArray(messages) && messages.length > 0) {
            errorMessage = messages[0]?.message || errorMessage;
          }
        }

        message.error(t(errorMessage));
        commonStore.loggingFunction('login', 'failed', startTime, { username });
      })
      .finally(() => {
        this.setLoadingProgress(false)
      })
  }
  externerUserLogin(username, token, remember, history) {
    this.setLoadingProgress(true)
    AuthRequest.exLogin(username?.trim().toLowerCase(), token)
      .then(response => {
        if (response.data?.user?.isActive) {
          commonStore.setRemember(remember)
          commonStore.setToken(response.data.jwt)
          commonStore.setLanguage(response.data.user.language ? response.data.user.language : 'en')
          projectStore.setVisitedMode(false)
          if (commonStore.isRemember) {
            localStorage.setItem('jwt', response.data.jwt)
          } else {
            sessionStorage.setItem('jwt', response.data.jwt)
          }

          usersStore.setCurrentUser(history)
        }
        return response.data
      })
      .then(data => {
        if (data?.user?.isActive) {
          fetch(`${assetUrl}maintananceInfo.json`
            , {
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
              }
            }
          )
            .then(function (response) {
              return response.json();
            })
            .then(function (myJson) {
              let u = data?.user?.data
              if (myJson.startDate && myJson.endDate && myJson.active) {
                let startTime = new Date(parseInt(myJson.startDate))
                let endTime = new Date(parseInt(myJson.endDate))

                let isBetween = moment(Date.now()).isBetween(moment(startTime), moment(endTime))
                const isActive = myJson.active === true || (myJson.active && myJson.active.toString().toLowerCase() === 'true')
                if (isBetween && isActive) {
                  return history.push(`/notification`)
                }
              } else {
                usersStore.updatelLastLoggin(data.user.id, { lastLoggin: Date.now() })
                if (data.user.role.type === "web_admin") {
                  message.success(t('welcome-username', { username: data.user.username }))
                  if (u && (u.policy !== myJson.policy || u.terms !== myJson.terms)) {
                    history.push('/')
                    return window.location.reload()
                  } else {
                    return history.push('/')
                  }
                }
                message.success(t('welcome-username', { username: data.user.username }))
                if (u && (u.policy !== myJson.policy || u.terms !== myJson.terms)) {
                  history.push('/')
                  return window.location.reload()
                } else {
                  return history.push('/')
                }
              }
            });
        } else {
          message.warning(t('sign-in-your-email-to-verify-your-account'))
        }
      })
      .catch(error => {
        if (Array.isArray(error?.data?.data[0]?.messages)) {
          let v = error?.data?.data[0]?.messages[0]?.message || t('something-went-wrong')
          if (v) message.error(t(v))
        } else {
          message.error(t('something-went-wrong'))
        }
      })
      .finally(() => {
        this.setLoadingProgress(false)
      })
  }
  userForgotPassword(email, history) {
    commonStore.setAppLoading(true)
    AuthRequest.forgetPassword(email)
      .then(() => {
        history.push('/forgot-password/success')
      })
      .catch(error => {
        message.error(t(error.data.message))
      })
      .finally(() => commonStore.setAppLoading(false))
  }

  checkResetPasswordToken(token, history) {
    commonStore.setAppLoading(true)
    AuthRequest.checkResetPasswordToken(token)
      .catch(error => {
        message.error(t(error.data.message))
        history.push('/forgot-password')
      })
      .finally(() => commonStore.setAppLoading(false))
  }

  resetPassword(token, newPassword, history) {
    commonStore.setAppLoading(true)
    AuthRequest.changePassword(token, newPassword)
      .then(() => {
        message.success(t('your-password-has-been-changed-successfully'), 5)
        commonStore.clearToken()
      })
      .then(() => history.push('/login'))
      .catch(error => {
        message.error(t(error.data.message))
      })
      .finally(() => commonStore.setAppLoading(false))
  }
}

decorate(AuthStore, {
  isLoading: observable,
  errors: observable,
  setLoadingProgress: action,
  userRegister: action,
  userLogin: action,
  externerUserLogin: action,
  userForgotPassword: action,
  checkResetPasswordToken: action,
  resetPassword: action,
  setSessionId: action,
  sesstionId: observable
})

export default new AuthStore()
