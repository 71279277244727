import styled from "styled-components";

export const LogTimelineHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: white;
  backdrop-filter: blur(10px);
  z-index: 10;

  .selection-item{
    display: flex;
    gap: 5px;
  }
`;

export const LogContainer = styled.div`
  .datawrapper{
    ${props => props.timelineHeight && `
      height: ${props.timelineHeight} !important;
    `}
  };
`