import { ColumnWidthOutlined, InfoCircleOutlined, PlusOutlined, QuestionCircleOutlined ,ReloadOutlined } from '@ant-design/icons'
import { Avatar, Button, Col, Drawer, Form, Input, InputNumber, message, notification, Row, Select, Skeleton, Switch, Upload, Popconfirm, Tooltip } from 'antd'
import axios from 'axios'
import { inject, observer } from 'mobx-react'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { apiUrl } from '@/config'
import validator from '@/validator'
import { ProjectSettingButton, ProjectSettingContent, ProjectSettingWrapper } from './style'
import { ReactComponent as ProjectSettingsDelete } from '@/assets/svgs/viewpoint-delete.svg'
import { HelpButton, SVGIcon, UploadButton } from '@/components/elements'
import { toJS } from 'mobx'
import { renderHTML } from '@/helper'
import { waitUntilIonReadyData } from '@/lib/projectLib'
import CameraTool from '../ProjectDetailPage/Camera/Camera'
import Utils from '@/utils'
const { TextArea } = Input
const { Option } = Select;


const DrawerProjectSetting = ({ projectSettingStore, sketchingStore, projectStore, commonStore, feedbackStore, workflowStore, projectTeamsStore, usersStore, adminStore, history }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const isTabletOrMobile = isMobile || isTablet
    const [drawerWidth, setDrawerWidth] = useState(0);
    const [loading, setLoading] = useState(false);
    const [projectDetail, setProjectDetail] = useState(false);
    const [uploadFile, setUploadFile] = useState(undefined)
    const [uploadFile2, setUploadFile2] = useState(undefined)
    const [screenWidth, setScreenWidth] = useState(0)
    const [defaultUploadThumbnail, setDefaultUploadThumbnail] = useState(false)
    const [defaultUploadStartUp, setDefaultUploadStartUp] = useState(false)
    const options = projectStore.epsgCodeList.map(d => <Option key={d.code}>{'EPSG:' + d.code + ' ' + d.name}</Option>);

    //#region event for mouse and touch to resize drawer panel
    const handleTouchStart = e => {
        e.preventDefault();
        if (e.touches.length !== 1) return null;
        document.addEventListener("touchmove", handleTouchMove, { passive: false });
        document.addEventListener("touchend", handleTouchEnd, { passive: false });
        document.addEventListener("touchcancel", handleTouchEnd, { passive: false });
    };

    const handleTouchMove = useCallback(e => {
        let touch = e.touches[0] || e.changedTouches[0];
        let target = document.elementFromPoint(touch.clientX, touch.clientY);
        let newWidth = (touch.clientX - (target && target.offsetRight ? target.offsetRight : 0));
        let minDrawerWidth = 360;
        if (newWidth < 0) {
            setDrawerWidth(10);
        }
        if (newWidth > minDrawerWidth) {
            setDrawerWidth(newWidth);
        }
        if (newWidth > window.innerWidth) {
            setDrawerWidth(window.innerWidth);
        }
    }, []);

    const handleTouchEnd = () => {
        document.removeEventListener("touchend", handleTouchEnd, { passive: false });
        document.removeEventListener("touchcancel", handleTouchEnd, { passive: false });
        document.removeEventListener("touchmove", handleTouchMove, { passive: false });
    };

    const handleMouseDown = e => {
        e.preventDefault();
        document.addEventListener("mouseup", handleMouseUp, true);
        document.addEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mouseup", handleMouseUp, true);
        document.removeEventListener("mousemove", handleMouseMove, true);
    };

    const handleMouseMove = useCallback(e => {
        let offsetRight = e.clientX - document.body.offsetLeft;
        let minDrawerWidth = 360;
        if (offsetRight > minDrawerWidth) {
            setDrawerWidth(offsetRight);
        }
        if (offsetRight > window.innerWidth) {
            setDrawerWidth(window.innerWidth);
        }
    }, []);
    //#endregion

    // (changeSize) change drawerWidth when drawerWidth > screenWidth
    const handleChangeScreenSize = () => {
        setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleChangeScreenSize) // force reload when screenWidth change

    useEffect(() => {
        if (drawerWidth > screenWidth) {
            setDrawerWidth(screenWidth)
        }
    }, [screenWidth])
    // end changeSize

    let timeout;
    let currentValue;
    const fetchSearchProjection = (value, callback) => {
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        currentValue = value;

        const searchProjection = () => {
            projectStore.getSearchProjection(value)
                .then(() => {
                    if (currentValue === value) {
                        const  results = projectStore.projectionList;
                        const data = [];
                        if (typeof results !== 'undefined') {
                            results.forEach(r => {
                                data.push(r);
                            });
                        }
                        callback(data);
                    }
                });
        }
        timeout = setTimeout(searchProjection, 300);
    }

    const handleSearch = value => {
        if (value) {
            fetchSearchProjection(value, data => {
                projectStore.setEpsgCodeList(data)
            });
        } else {
            projectStore.setEpsgCodeList([]);
        }
    }

    const handleChange = (value) => {
        const pr = projectStore.epsgCodeList.filter(d => d.code === value);
        projectStore.setProjectionDetail(pr)
        form.setFieldsValue({ coordinateSystem: value })
    }

    useEffect(() => {
        if (projectSettingStore.showProjectSettingDrawer && projectStore.projectDetail) {
            setProjectDetail(projectStore.projectDetail)
        }
    }, [projectStore.projectDetail])

    useEffect(() => {
        if (projectSettingStore.showProjectSettingDrawer) {
            setDrawerWidth(isTabletOrMobile ? 360 : 700)
            const loadProjectInfo = async () => {
                projectStore.setLoadingProgress(true)
                projectStore.getProjectSetting(projectStore.projectDetail._id).then(res => {
                    form.setFieldsValue({
                        name: res.data?.name,
                        coordinateSystem: (res.data?.tilesetData && res.data.tilesetData?.coordinateSystem) ? res.data.tilesetData.coordinateSystem.code : '4326',
                        elevationSystem: res.data?.elevationSystem,
                        desc: res.data?.desc,
                        isPublic: res.data?.isPublic ? res.data.isPublic : false,
                        headingRotation: res.data?.headingRotation ? res.data.headingRotation : 0,
                        // defaultStartupMode: res.data?.defaultStartupMode ? res.data.defaultStartupMode : 'normal',
                        isOrganizationTemplate: res.data?.isOrganizationTemplate,
                        storageUsage: projectStore.projectStorage
                    })
                    setProjectDetail(res.data)
                    // if (res.data.organization) {
                    //     adminStore.getRoleCurrentUserInOrganization(res.data.organization)
                    // }
                    projectStore.setLoadingProgress(false)
                }).catch(error => {
                    console.log(error)
                    projectStore.setLoadingProgress(false)
                    if (error?.status === 401) {
                        return history.push("/auth/login")
                    }
                })
            }
            // check if projectDetail if exist not load used for case snooze 
            if (!projectDetail) {
                loadProjectInfo()
            } else {
                form.setFieldsValue({
                    coordinateSystem: (projectDetail?.tilesetData && projectDetail.tilesetData?.coordinateSystem) ? projectDetail.tilesetData.coordinateSystem.code : '',
                    elevationSystem: projectDetail.elevationSystem,
                    name: projectDetail.name,
                    isPublic: projectDetail.isPublic,
                    // defaultStartupMode: projectDetail.defaultStartupMode,
                    headingRotation: projectDetail.headingRotation,
                    desc: projectDetail.desc,
                    isOrganizationTemplate: projectDetail.isOrganizationTemplate,
                    storageUsage: Utils.kbytesToSize(projectStore.projectStorage)
                })
            }
        }
        return () => {
            setDefaultUploadThumbnail(false)
            setDefaultUploadStartUp(false)
        }
    }, [projectSettingStore.showProjectSettingDrawer])

    useEffect(() => {
        form.setFieldsValue({ storageUsage: Utils.kbytesToSize(projectStore.projectStorage) })
    }, [projectStore.projectStorage])

    useEffect(() => {
        if (projectDetail) {
            if (projectDetail.tilesetData && projectDetail.tilesetData.coordinateSystem) {
                fetchSearchProjection(projectDetail.tilesetData.coordinateSystem.code, data => {
                    projectStore.setEpsgCodeList(data)
                    handleChange(projectDetail.tilesetData.coordinateSystem.code)
                });
            } else {
                projectStore.setEpsgCodeList([]);
            }
            form.setFieldsValue({ elevationSystem: projectDetail.elevationSystem })
            form.setFieldsValue({ isPublic: projectDetail.isPublic })
            // form.setFieldsValue({ defaultStartupMode: projectDetail.defaultStartupMode })
            form.setFieldsValue({ headingRotation: projectDetail.headingRotation })
            form.setFieldsValue({ storageUsage: Utils.kbytesToSize(projectStore.projectStorage) })

        }
    }, [projectDetail])

    const onCloseProjectSetting = () => {
        form.resetFields()
        setLoading(false)
        setDrawerWidth(0)
        setProjectDetail(false)
        projectStore.setEpsgCodeList([]);
        setUploadFile(undefined)
        setUploadFile2(undefined)
        projectSettingStore.toggleProjectSettingDrawer(false);
    };

    const handleUploadCover = file => {
        setUploadFile(file)
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            if (!projectDetail['thumbnail']) {
                setProjectDetail(exampleState => ({ ...exampleState, thumbnail: { base64: reader.result } }));
                setDefaultUploadThumbnail(false)
                return
            }
            if (projectDetail.thumbnail.base64) {
                setProjectDetail(exampleState => ({ ...exampleState, thumbnail: { base64: reader.result } }));
                setDefaultUploadThumbnail(false)
                return
            }
            else {
                setProjectDetail(exampleState => ({ ...exampleState, thumbnail: { base64: reader.result } }));
                setDefaultUploadThumbnail(true);
                return
            }
        });
        reader.readAsDataURL(file);
        return false
    }

    const handleUploadStartupImage = file => {
        setUploadFile2(file)
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            if (!projectDetail['startup_image']) {
                setProjectDetail(e => ({ ...e, startup_image: { base64: reader.result } }));
                setDefaultUploadStartUp(false)
                return
            }
            if (projectDetail.startup_image.base64) {
                setProjectDetail(e => ({ ...e, startup_image: { base64: reader.result } }));
                setDefaultUploadStartUp(false)
                return
            }
            else {
                setProjectDetail(e => ({ ...e, startup_image: { base64: reader.result } }));
                setDefaultUploadStartUp(true);
                return
            }
        });
        reader.readAsDataURL(file);
        return false
    }

    const onSaveChanges = () => {
        form.validateFields().then(async values => {
            projectStore.setUploadNewStartImage(true)
            const submitValues = {
                name: values.name,
                desc: values.desc,
                elevationSystem: values.elevationSystem,
                isPublic: values.isPublic,
                defaultStartupMode: "normal" || values.defaultStartupMode,
                headingRotation: values.headingRotation ? values.headingRotation : 0,
                isOrganizationTemplate: values.isOrganizationTemplate,
                store : "update-project-setting"
            }
            if (projectStore.projectionDetail.length > 0) {
                submitValues.coordinateSystem = values.coordinateSystem || '4326'

                let _tilesetData = projectDetail.tilesetData
                let _coordinateSystem = {
                    code: projectStore.projectionDetail[0].code,
                    name: projectStore.projectionDetail[0].name,
                    proj4: projectStore.projectionDetail[0].proj4,
                    wkt: projectStore.projectionDetail[0].wkt,
                    unit: projectStore.projectionDetail[0].unit.split(' ')[0]
                }
                submitValues.tilesetData = { ..._tilesetData, coordinateSystem: _coordinateSystem }
            }
            projectStore.setLoadingProgress(true)
            if (!projectDetail.thumbnail) {
                submitValues.thumbnail = null
            }
            if (!projectDetail.startup_image) {
                submitValues.startup_image = null
            }
            projectStore.updateProjectSetting(submitValues).then(async (res) => {
                const {message, ...rest } = res ;
                projectStore.setProjectDetail({
                    ...projectStore.projectDetail,
                    ...rest
                })
                let uploadCount = 0
                let uploadFileFunc = (file, field, callback) => {
                    const formData = new FormData()
                    formData.append('files', file)
                    formData.append('ref', 'project')
                    formData.append('refId', projectStore.projectDetail._id)
                    formData.append('field', field)
                    axios({
                        method: 'POST',
                        url: `${apiUrl}/upload`,
                        headers: {
                            Authorization: `Bearer ${commonStore.token}`,
                        },
                        data: formData,
                    }).then((responve) => {
                        if (uploadCount === 1) {
                            //turn off update thumnail_from_viewpoint when user upload project thumnail
                            const payload = { thumbnailFromViewpoint: false };
                            message.success(t('project-settings-updated-successfully'))
                            projectStore.setLoadingProgress(false)
                            axios({
                                method: 'PUT',
                                url: `${apiUrl}/projects/${projectStore.projectDetail._id}`,
                                headers: {
                                    Authorization: `Bearer ${commonStore.token}`,
                                },
                                data: payload,
                            })
                            projectStore.setProjectDetail({ ...projectStore.projectDetail, thumbnailFromViewpoint: false })
                            onCloseProjectSetting()
                        }
                        if (uploadCount > 1 && callback) {
                            callback(responve)
                        }

                    }).catch(error => {
                        message.error(t('upload-file-error'));
                        console.log(error)
                    })
                }
                if (uploadFile) {
                    uploadCount++
                }
                if (uploadFile2) {
                    uploadCount++
                }
                let upLoad2Callback = () => {
                    if (uploadFile2) {
                        uploadFileFunc(uploadFile2, 'startup_image', (responve) => {
                            let newImage = responve.data[0]
                            projectStore.setProjectDetail({ ...projectStore.projectDetail, startup_image: newImage })
                            message.success(t('project-settings-updated-successfully'));
                            onCloseProjectSetting()
                            projectStore.setLoadingProgress(false)
                        })
                    }
                    else {
                        message.success(t('project-settings-updated-successfully'))
                        onCloseProjectSetting()
                        projectStore.setLoadingProgress(false)
                    }
                }

                if (uploadFile) {
                    uploadFileFunc(uploadFile, 'thumbnail', upLoad2Callback)
                }
                else if (uploadFile2) {
                    upLoad2Callback()
                }
                if (!uploadCount) {
                    onCloseProjectSetting()
                    projectStore.setLoadingProgress(false)
                }
                projectStore.setChangeHeadingRotation(true) // reload heading rotation
                projectStore.setReloadAlignment(true) // reload alignment
            }).catch(error => {
                projectStore.setLoadingProgress(false)
                if (error.data && error.data.message) {
                    notification.open({
                        message: t('an-error-occurred-update-project'),
                        description: t(error.data.message),
                        icon: <InfoCircleOutlined style={{ color: '#ff0000' }} />,
                    })
                } else {
                    message.error(t('something-went-wrong'))
                }
                fetchSearchProjection(projectStore.projectionDetail[0].code, data => {
                    projectStore.setEpsgCodeList(data)
                    handleChange(projectStore.projectionDetail[0].code)
                });
                form.setFieldsValue({ elevationSystem: projectDetail.elevationSystem })
            })
        })
    }

    const onClickHandlerTeams = () => {
        projectSettingStore.toggleProjectSettingDrawer(false); // snooze project setting drawer
        projectTeamsStore.setShowProjectTeamsDrawer(true)
    }

    const onClickHandlerSketch = () => {
        projectSettingStore.toggleProjectSettingDrawer(false); // snooze project setting drawer
        sketchingStore.setShowProjectSketchsDrawer(true)
    }

    const onClickHandlerWorkflow = () => {
        projectSettingStore.toggleProjectSettingDrawer(false); // snooze project setting drawer
        workflowStore.setShowWorkflowDrawer(true)
    }

    const onClickProjectTemplate = () => {
        projectStore.setShowAddProjectTemplateModal(true)
    }

    const onClickHandlerShowFeedbackEditor = () => {
        projectSettingStore.toggleProjectSettingDrawer(false); // snooze project setting drawer
        feedbackStore.setShowFeedbackEditorDrawer(true)
    }
    const onClickHandlerShowLogInfo = () => {
        projectSettingStore.toggleProjectSettingDrawer(false); // snooze project setting drawer
        projectStore.setShowLogInfoProjectDrawer({show: true, mode: 'project'})
    }

    const onClickHandlerFeatureSetting = () => {
        projectSettingStore.toggleProjectSettingDrawer(false); // snooze project setting drawer
        projectStore.setShowFeatureSettingDrawer(true)
    }

    const onClickHandlerCustomAttribute = () => {
        projectSettingStore.toggleProjectSettingDrawer(false); // snooze project setting drawer
        projectStore.setShowCustomAttributeDrawer(true)
    }

    const checkingFeatureRole = (feature) => {
        if (!feature) return false
        return adminStore.checkingFeatureRole(projectStore, feature)
    }

    const clearProjectImage = () => {
        if (projectDetail.thumbnail.base64 && !defaultUploadThumbnail) {
            setProjectDetail({ ...projectDetail, thumbnail: null });
            setUploadFile(undefined);
            message.success(t('delete-project-image-success'));
        }
        if (projectDetail.thumbnail.url || defaultUploadThumbnail) {
            const data = { thumbnail: null };
            projectStore.setLoadingProgress(true);
            projectStore.updateProjectImages(data).then((res) => {
                projectStore.setProjectDetail({ ...projectStore.projectDetail, thumbnail: null });
                setProjectDetail(e => ({ ...e, thumbnail: null }));
                setUploadFile(undefined);
                projectStore.setLoadingProgress(false);
                message.success(t('delete-project-image-success'))
            }).catch(err => {
                console.log(err)
                message.error(t('something-went-wrong'))
                projectStore.setLoadingProgress(false);
            })
        }
    }

    const clearStartUpImage = () => {
        if (projectDetail.startup_image.base64 && !defaultUploadStartUp) {
            setProjectDetail({ ...projectDetail, startup_image: null });
            setUploadFile2(undefined);
            message.success(t('delete-start-up-image-success'));
        }
        if (projectDetail.startup_image.url || defaultUploadStartUp) {
            const data = { startup_image: null };
            projectStore.setLoadingProgress(true);
            projectStore.updateProjectImages(data).then((res) => {
                projectStore.setProjectDetail({ ...projectStore.projectDetail, startup_image: null });
                setProjectDetail(e => ({ ...e, startup_image: null }));
                setUploadFile2(undefined);
                projectStore.setLoadingProgress(false);
                message.success(t('delete-start-up-image-success'))
            }).catch(err => {
                console.log(err)
                message.error(t('something-went-wrong'))
                projectStore.setLoadingProgress(false);
            })
        }
    }

    const DeleteImageIcon = ({onConfirm}) => {
        return (
            <Popconfirm
                okText={t('commons.delete')}
                cancelText={t('commons.cancel')}
                title={t('are-you-sure')}
                okType='danger'
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                onConfirm={onConfirm}
            >
                <Avatar
                    className='delete-icon'
                    key="delete"
                    shape="square"
                    icon={<SVGIcon color={'#F26524'} content={<ProjectSettingsDelete />} width={18} height={18} />}
                />
            </Popconfirm>
        )
    }

  const handleRebuildModel = async () => {
    const startTime = Date.now();
      const listModelRebuild = projectStore.modelList.map(c => (c.id || c._id))
      projectStore.setLoadingProgress(true);
      await projectStore.rebuildProjectModel({ type: "model", list: listModelRebuild }).then(async res => {
        if (res) {
          commonStore.loggingFunction('Rebuild model','success',startTime,usersStore.currentUser,projectStore?.projectDetail?.name ,projectStore?.projectDetail?.organization?.name)
          const listModelIon = res?.model?.filter(x => x?.data?.ionAssetId) || []
          if (listModelIon.length > 0) {
            await Promise.all(listModelIon.map(async item => {
              if (item.data.ionAssetId) {
                await waitUntilIonReadyData(item.data.ionAssetId)
                return true
              }
            }))
          }
          const fkeys = listModelRebuild.map(item => item + '-tile')
          const fkeys_aligment = listModelRebuild.map(item => item + '-alignment')
          listModelRebuild.map(item => {
            if (projectStore.modelFeatures?.[item])
              delete projectStore.modelFeatures[item]
          })
          const oldVisibleTilesets = toJS(projectStore.visibleTilesets).find(t => fkeys.includes(t.modelId + '-tile')) // track old state before rebuild
          projectStore.setStateBeforeRebuild(oldVisibleTilesets) // save them to store and call in attributePanel
  
          projectStore.setTileViews(projectStore.tileViews.filter(t => !fkeys.includes(t.key))) // remove models from viewer when rebuild
          projectStore.setAlignment(projectStore.Alignment.filter(t => !fkeys_aligment.includes(t.key))) // remove aligment from viewer when rebuild
  
          const temp = [...projectStore.modelList.filter(t => !listModelRebuild.includes(t._id))]
          projectStore.setModelList(temp) // call useEffect projectStore.modelList in projectDetailPage reload TilesetByModel
  
          let _message = { DisplayText: res.model.length > 0 ? res.model.map(function (elm) { return `- ${t('model')}: ${elm.model} ${t('rebuild')}: ${elm.status === 'Success' ? '<span style="color:#07a907">' + t('success' || elm.status) + '</span>' : '<span style="color:#ff0000">' + t('error' || elm.status) + '</span>'}` }).join("<br />") : null }
          notification.open({
            message: t('rebuild-model'),
            description: (
              <>
                {renderHTML(_message.DisplayText)}
              </>
            ),
            icon: <InfoCircleOutlined style={{ color: '#369d04' }} />,
            duration: 0,
            placement: 'top',
            className: 'custom-notivication'
          })
  
          /**Update FE projectStore.ProjectDetail */
          await projectStore.updateProjectRefPoint(res.project)
          projectStore.setRebuildModel(true) //effect in project detail reload tileset
          projectStore.setReloadAlignment(true); // effect in project detail reload Alignment
        } else {
          message.error(t('rebuild-failed-please-try-again'))
          commonStore.loggingFunction('Rebuild project','failed',startTime,usersStore.currentUser,projectStore?.projectDetail?.name ,projectStore?.projectDetail?.organization?.name)
        }
        projectStore.setLoadingProgress(false);
      }).catch(error => {
        console.log(error)
        message.error(t('rebuild-failed-please-try-again'))
        projectStore.setLoadingProgress(false);
        commonStore.loggingFunction('Rebuild project','failed',startTime,usersStore.currentUser,projectStore?.projectDetail?.name ,projectStore?.projectDetail?.organization?.name)
      })
  }

    return (
        <Drawer
            getContainer={false}
            title={
                <div className="help-btn-wrapper">
                    {t('project')}
                    <Tooltip title={t('commons.help')} overlayStyle={(isMobile || isTablet) ? { display: 'none' } : undefined}>
                        <div>
                            <HelpButton helppage={'project_settings'} hovered={'black'} />
                        </div>
                    </Tooltip>
                </div>
            }
            placement="left"
            width={drawerWidth}
            closable={true}
            onClose={e => onCloseProjectSetting()}
            visible={projectSettingStore.showProjectSettingDrawer}
            maskClosable={false}
            mask={false}
            destroyOnClose={true}
            bodyStyle={{ marginRight: 10 }}
            className={projectSettingStore.showProjectSettingDrawer ? "project-setting-drawer custom-wraper-splitPanel" : ''}
            footer={
                <div style={{ textAlign: 'right', marginRight: 10 }}>
                    <Row justify='end'>
                        {
                            projectStore.currentUserRoleInProject && projectStore.currentUserRoleInProject === "project_owner" && (checkingFeatureRole('feature_custom_attribute_view') || checkingFeatureRole('feature_custom_attribute_edit')) ? (
                                <ProjectSettingButton onClick={e => onClickHandlerCustomAttribute()}>
                                    {t('attributes')}
                                </ProjectSettingButton>
                            ) : ''
                        }
                        <ProjectSettingButton onClick={e => onClickHandlerTeams()}>
                            {t('users')}
                        </ProjectSettingButton>
                        {
                            projectStore.currentUserRoleInProject && projectStore.currentUserRoleInProject === "project_owner" ? (
                                <ProjectSettingButton onClick={e => onClickHandlerSketch()}>
                                    {t('library')}
                                </ProjectSettingButton>
                            ) : ''
                        }
                        {
                            projectStore.currentUserRoleInProject && projectStore.currentUserRoleInProject === "project_owner" && (
                                <ProjectSettingButton onClick={e => onClickHandlerFeatureSetting()}>
                                    {t('roles')}
                                </ProjectSettingButton>
                            )
                        }
                        <ProjectSettingButton onClick={e => onClickHandlerShowFeedbackEditor()}>
                            {t('feedback')}
                        </ProjectSettingButton>
                        <ProjectSettingButton onClick={e => onClickHandlerWorkflow()}>
                            {t('workflows')}
                        </ProjectSettingButton>
                        {
                            projectStore.isExistLicenses && (usersStore.systemUserRole.type === 'web_admin' || adminStore.roleCurrentUserInOrganization.role === 'Admin') ? (
                                <ProjectSettingButton onClick={e => onClickProjectTemplate()}>
                                    {t('template')}
                                </ProjectSettingButton>
                            ) : ''
                        }
                        <ProjectSettingButton onClick={e => onClickHandlerShowLogInfo()}>
                            {t('log')}
                        </ProjectSettingButton>
                        <ProjectSettingButton onClick={e => onSaveChanges()} type="primary">
                            {t('commons.save')}
                        </ProjectSettingButton>
                    </Row>
                </div>
            }
        >
            <ProjectSettingWrapper>
                {isTabletOrMobile ? (
                    <div className="splitpanel-mobile-left">
                        <Button onTouchStart={e => handleTouchStart(e)} type="dashed" shape="circle" className="btnSplitPanel">
                            <ColumnWidthOutlined />
                        </Button>
                    </div>
                ) : (<div onMouseDown={e => handleMouseDown(e)} className="splitpanel-left" />)
                }
                <Skeleton loading={loading} active>
                    <ProjectSettingContent>
                        <Form form={form} layout="vertical">
                            <Row>
                                <Col span={24} style={{ display: 'flex' }}>
                                    <Col>
                                        <Form.Item label={t('project-image')}>
                                            <Form.Item
                                                name="thumbnail"
                                                valuePropName="file"
                                            >
                                                <Upload
                                                    showUploadList={false}
                                                    name="avatar"
                                                    className={`avatar-uploader ${projectDetail.thumbnail ? 'has-image' : 'no-image'} input-upload-image-project`}
                                                    beforeUpload={file => handleUploadCover(file)}
                                                    listType="picture-card"
                                                >
                                                    {
                                                        projectDetail.thumbnail
                                                            ? projectDetail.thumbnail?.base64
                                                                ? <img src={projectDetail.thumbnail?.base64} alt="" className='input-upload-image-project' />
                                                                : <img src={projectDetail.thumbnail?.url} alt="" className='input-upload-image-project' />
                                                            : <UploadButton />
                                                    }
                                                </Upload>
                                            </Form.Item>
                                            {
                                                projectDetail.thumbnail ? <DeleteImageIcon onConfirm={clearProjectImage} /> : ""
                                            }
                                        </Form.Item>
                                    </Col>
                                    <Col style={{ paddingLeft: '24px' }}>
                                        <Form.Item label={t('startup-image')}>
                                            <Form.Item
                                                name="startup_image"
                                                valuePropName="file"
                                            >
                                                <Upload
                                                    showUploadList={false}
                                                    name="startup_image"
                                                    className={`startup_image-uploader ${projectDetail.startup_image ? 'has-image' : 'no-image'} input-upload-image-project`}
                                                    beforeUpload={file => handleUploadStartupImage(file)}
                                                    listType="picture-card"
                                                >
                                                    {
                                                        projectDetail.startup_image
                                                            ? projectDetail.startup_image?.base64
                                                                ? <img src={projectDetail.startup_image?.base64} alt="" className='input-upload-image-project' />
                                                                : <img src={projectDetail.startup_image?.url} alt="" className='input-upload-image-project' />
                                                            : <UploadButton />
                                                    }
                                                </Upload>
                                            </Form.Item>
                                            {
                                                projectDetail.startup_image ? <DeleteImageIcon onConfirm={clearStartUpImage} /> : ""
                                            }
                                        </Form.Item>
                                    </Col>
                                </Col>
                            </Row>
                            <Form.Item
                                label={(projectDetail.organization?.name && (`${t('project-name')} (${projectDetail.organization.name})`)) || t('project-name')}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: t('please-input-project-name'),
                                    },
                                    {
                                        validator: validator.validateEmptyString,
                                    },
                                ]}
                            >
                                <Input placeholder={t('input-project-name')} />
                            </Form.Item>
                            <Form.Item
                                label={t('coordinate-system')}
                                name="coordinateSystem"
                                rules={[
                                    {
                                        required: true,
                                        message: t('please-choose-coordinate-system'),
                                    },
                                    { validator: validator.validateEmptyString },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder={t('enter-country-code-or-name-coordinate-system')}
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    onSearch={val => handleSearch(val)}
                                    onChange={val => handleChange(val)}
                                    notFoundContent={null}
                                >
                                    {options}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={t('elevation-system')}
                                name="elevationSystem"
                                rules={[
                                    {
                                        required: false,
                                        message: t('please-choose-elevation-system'),
                                    },
                                    { validator: validator.validateEmptyString },
                                ]}
                            >
                                <Select className='selectdrp'>
                                    <Option value="None">None</Option>
                                    <Option value="N2000">N2000</Option>
                                    <Option value="N60">N60</Option>
                                    <Option value="EGM96">EGM96</Option>
                                    <Option value="EGM2008">EGM2008</Option>
                                    <Option value="NN2000">NN2000</Option>
                                </Select>
                            </Form.Item>
                            <Row gutter={16}>
                                <Col>
                                    <Form.Item
                                        label={t('project-rotation')}
                                        name="headingRotation"
                                        rules={[
                                            {
                                                required: false
                                            }
                                        ]}
                                    >
                                        <InputNumber
                                            min={-180}
                                            max={180}
                                            step={0.1}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item
                                        label={t('storage-usage')}
                                        name="storageUsage"
                                        rules={[
                                            {
                                                required: false
                                            }
                                        ]}
                                    >
                                        <Input
                                            className='readOnly'
                                            // controls={false}
                                            style={{ width: 150 }}
                                        // min={0}
                                        />
                                    </Form.Item>
                                </Col>
                                {
                                    projectStore.currentUserRoleInProject && projectStore.currentUserRoleInProject === "project_owner" && (
                                        <Col>
                                            <Form.Item
                                                label={t('rebuild')}
                                            >
                                                <Button
                                                    style={{ border: '1px solid #d9d9d9', borderRadius: '4px' }}
                                                    icon={<SVGIcon color={'#191919'} content={<ReloadOutlined />} />}
                                                    onClick={() => handleRebuildModel()}
                                                />
                                            </Form.Item>
                                        </Col>
                                    )
                                }
                            </Row>


                            <Form.Item
                                label={t('description')}
                                name="desc"
                                rules={[
                                    { validator: validator.validateEmptyString },
                                ]}
                            >
                                <TextArea rows={3} placeholder={t('input-description')} />
                            </Form.Item>
                            <Row>
                                <span>
                                    <Form.Item
                                        label={t('public')}
                                        name="isPublic"
                                        valuePropName="checked"
                                    >
                                        <Switch />
                                    </Form.Item>
                                </span>
                                <span style={{ marginLeft: '10px' }}>
                                    <Form.Item
                                        label={t('template')}
                                        name="isOrganizationTemplate"
                                        valuePropName="checked"
                                    >
                                        <Switch />
                                    </Form.Item>
                                </span>
                            </Row>
                            {/* <Form.Item
                                label={t('default-startup-mode')}
                                name="defaultStartupMode"
                                rules={[
                                    { validator: validator.validateEmptyString },
                                ]}
                            >
                                <Select className='selectdrp'>
                                    <Option value="normal">Normal</Option>
                                    <Option value="gps">GPS</Option>
                                    <Option value="gps_compass">GPS + Compass</Option>
                                    <Option value="ar_with_3d">AR with 3D</Option>
                                    <Option value="ar_with_camera">AR with Camera</Option>
                                </Select>
                            </Form.Item> */}
                        </Form>
                        {
                            projectDetail._id ==='669e09feb485d20012bb6401' && <CameraTool />
                        }
                    </ProjectSettingContent>
                </Skeleton>
            </ProjectSettingWrapper>
        </Drawer>
    )
}

export default withRouter(
    inject(
        'projectStore',
        'projectSettingStore',
        'commonStore',
        'feedbackStore',
        'workflowStore',
        'projectTeamsStore',
        'usersStore',
        'adminStore',
        'sketchingStore'
    )(observer(DrawerProjectSetting))
);
