import { Col, message, Row, Tabs } from 'antd'
import { sortBy } from 'lodash'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import "moment/locale/en-gb"
import "moment/locale/es"
import "moment/locale/fi"
import "moment/locale/sv"
import "moment/locale/vi"
import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { withRouter } from 'react-router-dom'
import { OrganizationWraper } from './../style'
import OrganizationLicensesTab from './OrganizationLicensesTab/OrganizationLicensesTab'
import OrganizationProjectsTab from './OrganizationProjectsTab'
import OrganizationQuotaTab from './OrganizationQuotaTab'
import OrganizationUsersTab from './OrganizationUsersTab/OrganizationUsersTab'
import OrganizationEdit from './OrganizationViewEdit/OrganizationEdit'
import OrganizationView from './OrganizationViewEdit/OrganizationView'
import OrganizationSketchTab from './OrganizationSketchTab/OrganizationSketchTab'
import {  useTranslation } from 'react-i18next';
import i18next from 'i18next'
import { checkAvaiableLicenses } from '@/lib/projectLib'
import OrganizationLogTab from './OrganizationLogTab'
const { TabPane } = Tabs

const ManagerOrganization = (props) => {
    const { t } = useTranslation();
    const { adminStore,history, organizationStore, drawerWidth, commonStore } = props;
    const organizationId = organizationStore.paramsOrganizationDetail
    const [licensesProject, setLicensesProject] = useState({});
    const [usersBelongOrganizationData, setUsersBelongOrganizationData] = useState([]);
    const [listLicenseTab, setListLicenseTab] = useState([]);
    moment.locale(`${commonStore.language}`)

    function updateKey(data) {
        var x = Object.assign(licensesProject, data)
        setLicensesProject(x)
    }

    useEffect(() => {
        adminStore.clearCurrentOrganizationDetail()
    }, [])

    useEffect(() => {
        adminStore.setEditMode(false)
        adminStore.getRoleCurrentUserInOrganization(organizationId)
        getCurrentOrganizationDetail(organizationId)
        return () => {
            adminStore.clearAvailableLicenses()
            adminStore.clearLicenseQuota()
            setLicensesProject([])
        }
    }, [organizationStore.forceReload])

    const calculateStorage = data => {
        let ptojects = data.filter(c => !c.isDeleted) || []
        let quotas = 0

        while (ptojects.length > 0) {
            const prj = ptojects.shift()
            quotas = quotas + Number(prj.storage || 0)
        }
        return quotas
    }

    const getCurrentOrganizationDetail = (id) => {
        adminStore.setLoadingProgress(true)
        adminStore.getCurrentOrganizationDetail(id).then((res) => {
            let _totalLicenses = res?.data?.licenses
            let r = checkAvaiableLicenses(_totalLicenses)
            const addTimeStap = (day) => {
                if (day) return 60000 * 60 * 24 * 4 * day
            }
            const getTimeStap = (date) => {
                if (date) return new Date(date).getTime()
            }
            if (r.length > 0) {
                // get date when first license will expire and needs re-newing
                let _time = r[0].timeLimit || 0
                let _expireTime = r[0].expiration ? moment(r[0].expiration).toString() : moment(r[0].createdAt).add(_time, 'days').toString()
                let expireTimeZone = r[0].expiration ? getTimeStap(r[0].expiration) : (getTimeStap(r[0].activated ? r[0].activated : r[0].createdAt) + addTimeStap(_time))

                let licensePrj = 0
                let licenseUser = 0
                let licenseQuota = 0

                for (let i = 0; i < r.length; i++) {
                    if (r[i].storageQuota < 9999 && typeof licenseQuota === 'number') {
                        licenseQuota += r[i].storageQuota || 0
                    } else {
                        licenseQuota = 'UNLIMITTED'
                    }
                    if (r[i].numberProject < 9999 && typeof licensePrj === 'number') {
                        licensePrj += r[i].numberProject || 0
                    } else {
                        licensePrj = 'UNLIMITTED'
                    }
                    if (r[i].numberUser < 9999 && typeof licenseUser === 'number') {
                        licenseUser += r[i].numberUser || 0
                    } else {
                        licenseUser = 'UNLIMITTED'
                    }
                    if (Date.parse(moment(r[i].createdAt).add(r[i].timeLimit, 'days').toString()) < Date.parse(_expireTime)) {
                        _expireTime = r[i].expiration ? moment(r[i].expiration).toString() : moment(r[i].createdAt).add(r[i].timeLimit, 'days').toString()
                    }
                    if ((r[i].expiration ? getTimeStap(r[i].expiration) : getTimeStap(r[i].activated ? r[i].activated : r[i].createdAt) + addTimeStap(r[i].timeLimit)) < expireTimeZone) {
                        expireTimeZone = r[i].expiration ? getTimeStap(r[i].expiration) : getTimeStap(r[i].activated ? r[i].activated : r[i].createdAt) + addTimeStap(r[i].timeLimit)
                    }
                }
                // updateKey({ expireTime: moment(_expireTime).format('MMMM Do YYYY, h:mm:ss a') })
                // adminStore.setexpireTime(moment(_expireTime).format('MMMM Do YYYY, h:mm:ss a'))
                updateKey({ expireTime: moment(expireTimeZone).format('MMMM Do YYYY, h:mm:ss a') })
                adminStore.setexpireTime(moment(expireTimeZone).format('MMMM Do YYYY, h:mm:ss a'))
                adminStore.setLicenseMaxUser(licenseUser)
                adminStore.setLicenseMaxProject(licensePrj)
                licenseQuota = licenseQuota !== 'UNLIMITTED' ? parseFloat(licenseQuota.toFixed(3)) : licenseQuota
                adminStore.setLicenseMaxQuota(licenseQuota)
            }

            // get license data orglicensetab
            let _licenseActives = r.filter(elm => elm.isActive)
            if (_licenseActives.length > 0) {
                let _listLicenseTab = []
                _licenseActives.map(elm => {
                    let licensePrjTab =  typeof elm.numberProject === 'number' ? (
                        elm.numberProject < 9999 ? elm.numberProject : 'UNLIMITTED'
                    ) : 0
                    let licenseUserTab = typeof elm.numberUser === 'number' ? (
                        elm.numberUser < 9999 ? elm.numberUser : 'UNLIMITTED'
                    ) : 0
                    let licenseQuotaTab = typeof elm.storageQuota === 'number' ? (
                        elm.storageQuota < 9999 ? elm.storageQuota : 'UNLIMITTED'
                    ) : 0
                    let _timeTab = elm.timeLimit || 0
                    let expireTimeTab = elm.expiration ? moment(elm.expiration).toString() : moment(elm.createdAt).add(_timeTab, 'days').toString()
                    let activatedTab = elm.activated || elm.createdAt
                    licenseQuotaTab = licenseQuotaTab !== 'UNLIMITTED' ? parseFloat(licenseQuotaTab.toFixed(3)) : licenseQuotaTab

                    _listLicenseTab.push({
                        typeName: elm?.licensetype?.name || 'NO TYPE'                        ,
                        licensePrjTab,
                        licenseUserTab,
                        licenseQuotaTab,
                        expireTimeTab,
                        activatedTab
                    })
                })
                setListLicenseTab(_listLicenseTab)
            }

            // count project in org
            let _projects = res.data.projects.filter(v => !v.isDeleted)
            adminStore.setListProjectUsageLicense(_projects.length)
            adminStore.setstorageQuotaUsage(calculateStorage(res.data.projects))

            //count user in org
            let countUsers = {}
            for (let i = 0; i < _projects.length; i++) {
                const p = _projects[i];
                const pUsers = p.projectuserroles.filter(c => c.email !== "maintenance@xd-visuals.fi")
                for (let j = 0; j < pUsers.length; j++) {
                    const projectUserRole = pUsers[j];
                    if (projectUserRole.inviteStatus === 'rejected') continue
                    if (projectUserRole.email) {
                        if (!countUsers[projectUserRole.email]) {
                            countUsers[projectUserRole.email] = 0
                        }
                        countUsers[projectUserRole.email]++
                    }
                }
            }
            const count = Object.keys(countUsers).length
            adminStore.setListUserProjectUsageLicense(count)


            let _users = []
            let _organizationuserroles = res.data.organizationuserroles.filter(elm => elm.email && elm.organizationrole && elm.organization && elm.inviteStatus !== 'rejected')
            _organizationuserroles.map(elm => {
                let isExist = _users.find(element => element.email === elm.email)
                if (isExist) return
                _users.push(elm)
            })

            setUsersBelongOrganizationData(_users)
            adminStore.setLoadingProgress(false)
        }).catch(error => {
            adminStore.setLoadingProgress(false)
            if (error.status === 401) {
                return history.push("/auth/login")
            }
            if (error.data && error.data.statusCode === 403) {
                message.error(t('you-do-not-have-permission'))
                history.push(`/`)
            } else {
                message.error(t('an-error-occurred'))
            }
        })
    }

    const toggleEditMode = () => {
        adminStore.setEditMode(true)
    }

    const updateOrganizationInfo = async (organizationId, values) => {
        await adminStore.updateOrganizationInfo(organizationId, values)
        // await adminStore.getCurrentOrganizationDetail(organizationId)
    }

    const handleChangeTab = key => {
        adminStore.setActiveTab(key)
        adminStore.setEditMode(false)
    }

    return (
        <HelmetProvider>
            <Helmet>
                <title>{t('manager-organization-page')}</title>
            </Helmet>
            <OrganizationWraper>
                <div className='organization-body'>
                    <Row gutter={[16, 16]} >
                        <Col className="gutter-row" sm={24} md={24}>

                            {
                                adminStore.editMode
                                    ? <OrganizationEdit
                                        organizationData={adminStore.currentOrganizationView}
                                        onSubmit={(organizationId, values) => updateOrganizationInfo(organizationId, values)}
                                        onCancel={() => adminStore.setEditMode(false)}
                                    />
                                    : <OrganizationView
                                        drawerWidth={drawerWidth}
                                        onToggleEditMode={toggleEditMode}
                                    />
                            }
                        </Col>
                        {
                            !adminStore.editMode && (
                                <Col className="gutter-row" sm={24} md={24} >
                                    <Tabs
                                        defaultActiveKey={adminStore.organizationActiveTab}
                                        animated={false}
                                        style={{ padding: '0 15px' }}
                                        onChange={handleChangeTab}>
                                        <TabPane tab={i18next.format(t('projects'), 'uppercase')} key="1">
                                            <OrganizationProjectsTab
                                                projectsBelongOrganizationData={adminStore.currentOrganizationView.projects}
                                                licensesBelongOrganizationData={licensesProject}
                                            />
                                        </TabPane>
                                        <TabPane tab={i18next.format(t('users'), 'uppercase')} key="2">
                                            <OrganizationUsersTab
                                                usersBelongOrganizationData={usersBelongOrganizationData}
                                            />
                                        </TabPane>
                                        <TabPane tab={i18next.format(t('licenses'), 'uppercase')} key="3">
                                            <OrganizationLicensesTab listLicenseTab={listLicenseTab} />
                                        </TabPane>
                                        <TabPane tab={i18next.format(t('quotas'), 'uppercase')} key="4">
                                            <OrganizationQuotaTab licensesBelongOrganizationData={licensesProject} />
                                        </TabPane>
                                        <TabPane tab={i18next.format(t('library'), 'uppercase')} key="library">
                                            <OrganizationSketchTab
                                            />
                                        </TabPane>
                                        <TabPane tab={i18next.format(t('log'), 'uppercase')} key="log">
                                            <OrganizationLogTab
                                                usersBelongOrganizationData={usersBelongOrganizationData}
                                            />
                                        </TabPane>
                                    </Tabs>
                                </Col>
                            )
                        }
                    </Row>
                </div>
            </OrganizationWraper>
        </HelmetProvider>
    );
}

export default withRouter(
    inject(
        'adminStore',
        'organizationStore',
        'commonStore'
    )(observer(ManagerOrganization))
);
