const timeRangeList = [
  {
    value: '1m',
    label: '1m',
  },
  {
    value: '15m',
    label: '15m',
  },
  {
    value: '30m',
    label: '30m',
  },
  {
    value: '1h',
    label: '1h',
  },
  {
    value: '4h',
    label: '4h',
  },
  {
    value: '1d',
    label: '1d',
  },
  {
    value: '7d',
    label: '7d',
  },
  {
    value: '30d',
    label: '30d',
  },
];

export { timeRangeList };