import { HelpButton } from '@/components/elements'
import LogInfoWrapper, {
  useLogInfo,
} from '@/components/organisms/LogInfoWrapper'
import { ColumnWidthOutlined } from '@ant-design/icons'
import { Button, Drawer, Tooltip } from 'antd'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/es'
import 'moment/locale/fi'
import 'moment/locale/sv'
import 'moment/locale/vi'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { LogInfoPageWrapper } from './style'

const LogInfoPage = ({
  usersStore,
  commonStore,
  projectStore,
  projectSettingStore,
  projectTeamsStore,
}) => {
  const { t } = useTranslation()
  moment.locale(`${commonStore.language}`)
  const isTabletOrMobile = isMobile || isTablet
  const [drawerWidth, setDrawerWidth] = useState(0)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const drawerBodyRef = useRef(null)

  //#region event for mouse and touch to resize drawer panel
  const handleTouchStart = e => {
    e.preventDefault()
    if (e.touches.length !== 1) return null
    document.addEventListener('touchmove', handleTouchMove, { passive: false })
    document.addEventListener('touchend', handleTouchEnd, { passive: false })
    document.addEventListener('touchcancel', handleTouchEnd, { passive: false })
  }

  const handleTouchMove = useCallback(e => {
    let touch = e.touches[0] || e.changedTouches[0]
    let target = document.elementFromPoint(touch.clientX, touch.clientY)
    let newWidth =
      touch.clientX - (target && target.offsetRight ? target.offsetRight : 0)
    let minDrawerWidth = 360
    if (newWidth < 0) {
      setDrawerWidth(10)
    }
    if (newWidth > minDrawerWidth) {
      setDrawerWidth(newWidth)
    }
    if (newWidth > window.innerWidth) {
      setDrawerWidth(window.innerWidth)
    }
  }, [])

  const handleTouchEnd = () => {
    document.removeEventListener('touchend', handleTouchEnd, { passive: false })
    document.removeEventListener('touchcancel', handleTouchEnd, {
      passive: false,
    })
    document.removeEventListener('touchmove', handleTouchMove, {
      passive: false,
    })
  }

  const handleMouseDown = e => {
    e.preventDefault()
    document.addEventListener('mouseup', handleMouseUp, true)
    document.addEventListener('mousemove', handleMouseMove, true)
  }

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true)
    document.removeEventListener('mousemove', handleMouseMove, true)
  }

  const handleMouseMove = useCallback(e => {
    let offsetRight = e.clientX - document.body.offsetLeft
    let minDrawerWidth = 360
    if (offsetRight > minDrawerWidth) {
      setDrawerWidth(offsetRight)
    }
    if (offsetRight > window.innerWidth) {
      setDrawerWidth(window.innerWidth)
    }
  }, [])
  //#endregion

  useEffect(() => {
    if (drawerWidth > screenWidth) {
      setDrawerWidth(screenWidth)
    }
  }, [screenWidth])

  const calculateTimelineHeight = () => {
    if (drawerBodyRef.current) {
      const drawerHeight = drawerBodyRef.current.offsetHeight
      return `${drawerHeight - (32 + 46 + 35 - 10)}px`
    }
    return '400px' // Default height
  }

  const handleChangeScreenSize = () => {
    setScreenWidth(window.innerWidth)
  }

  window.addEventListener('resize', handleChangeScreenSize)

  const mode = useMemo(
    () => projectStore.showLogInfoProjectDrawer?.mode,
    [projectStore.showLogInfoProjectDrawer?.mode]
  )

  const projectId = useMemo(
    () => projectStore.projectDetail._id,
    [projectStore.projectDetail._id]
  )

  const currentUserId = useMemo(
    () => usersStore.currentUser.id,
    [usersStore.currentUser.id]
  )

  const userList = useMemo(() => {
    if (!projectTeamsStore?.teamList?.length) return []
    return projectTeamsStore?.teamList.map(({ user }) => ({
      value: user?._id || user?.id,
      label:
        user?.username ?? `${user?.firstName || ''} ${user?.lastName || ''}`,
    }))
  }, [projectTeamsStore?.teamList, projectTeamsStore.isFetchingPrjTeam])

  const logInfoHookProps = {
    initialTimeRange: '15m',
    projectId,
    currentUserId,
    projectStore,
    mode,
    userList,
  }

  const { loadLogInfo } = useLogInfo(logInfoHookProps)

  useEffect(() => {
    if (projectStore.showLogInfoProjectDrawer?.show) {
      setDrawerWidth(
        isTabletOrMobile
          ? 360
          : window.innerWidth < 360
          ? window.innerWidth
          : window.innerWidth / 2 < 360
          ? 360
          : window.innerWidth / 2
      )
      loadLogInfo()
    }
  }, [projectStore.showLogInfoProjectDrawer?.show])

  const onCloseLogInfoDrawer = () => {
    setDrawerWidth(0)
    projectStore.showLogInfoProjectDrawer?.mode === 'project'
      ? projectSettingStore.toggleProjectSettingDrawer(true)
      : projectTeamsStore.setShowProjectTeamsDrawer(true)

    projectStore.setShowLogInfoProjectDrawer(false)
    projectStore.setLogInfo(false)
  }

  return (
    <Drawer
      title={
        <div className="help-btn-wrapper">
          {t('log')}
          <Tooltip
            title={t('commons.help')}
            overlayStyle={
              isMobile || isTablet ? { display: 'none' } : undefined
            }>
            <div>
              <HelpButton helppage={'log'} />
            </div>
          </Tooltip>
        </div>
      }
      placement="left"
      width={drawerWidth}
      closable={true}
      onClose={onCloseLogInfoDrawer}
      visible={projectStore.showLogInfoProjectDrawer?.show}
      maskClosable={false}
      mask={false}
      destroyOnClose={true}
      bodyStyle={{ marginRight: 10 }}
      className={
        projectStore.showLogInfoProjectDrawer?.show
          ? ' workflow-drawer custom-wraper-splitPanel '
          : ' workflow-drawer '
      }>
      <LogInfoPageWrapper ref={drawerBodyRef} style={{ height: '100%' }}>
        {isTabletOrMobile ? (
          <div className="splitpanel-mobile-left">
            <Button
              onTouchStart={e => handleTouchStart(e)}
              type="dashed"
              shape="circle"
              className="btnSplitPanel">
              <ColumnWidthOutlined />
            </Button>
          </div>
        ) : (
          <div
            onMouseDown={e => handleMouseDown(e)}
            className="splitpanel-left"
          />
        )}
        <LogInfoWrapper
          logData={projectStore.logInfo?.data?.result || []}
          loading={projectStore.isLogInfoLoading}
          logInfoHookProps={logInfoHookProps}
          timelineHeight={calculateTimelineHeight()}
        />
      </LogInfoPageWrapper>
    </Drawer>
  )
}

export default withRouter(
  inject(
    'usersStore',
    'commonStore',
    'projectStore',
    'projectSettingStore',
    'projectTeamsStore'
  )(observer(LogInfoPage))
)
