import LogTimeline from '@/components/elements/LogTimeline'
import { ReloadOutlined } from '@ant-design/icons'
import { Button, Select } from 'antd'
import { timeRangeList } from './constant'
import { LogContainer, LogTimelineHeaderWrapper } from './style'
import { useTranslation } from 'react-i18next'
import { useState, useCallback, useEffect } from 'react'

export const useLogInfo = ({
  initialTimeRange = '15m',
  organizationId,
  projectId,
  currentUserId,
  projectStore,
  mode = 'project',
  userList = [],
}) => {
  const [timeRange, setTimeRange] = useState(initialTimeRange)
  const [user, setUser] = useState({})

  const loadLogInfo = useCallback(
    (timeR, additionals) => {
      projectStore.getLogInfo({
        timeRange: timeR ?? timeRange,
        label: {
          [mode]: mode === 'project' ? projectId : mode === 'organization' ? organizationId : currentUserId,
          ...(additionals ?? user),
        },
      })
    },
    [organizationId, projectId, currentUserId, timeRange, user, mode, projectStore]
  )

  useEffect(() => {
    loadLogInfo()
  }, [mode, projectId, currentUserId, organizationId])

  const handleChangeTimeRange = (value) => {
    setTimeRange(value)
    loadLogInfo(value)
  }

  const handleChangeUser = (value) => {
    setUser({ user: value })
    loadLogInfo(null, { user: value })
  }

  return {
    timeRange,
    user,
    handleChangeTimeRange,
    handleChangeUser,
    loadLogInfo,
    userList,
  }
}

const LogInfoWrapper = ({
  logData,
  loading,
  logInfoHookProps, // Pass hook props here
  timelineHeight = '400px',
}) => {
  const { t } = useTranslation()
  const {
    handleChangeTimeRange,
    handleChangeUser,
    loadLogInfo,
    userList,
  } = useLogInfo(logInfoHookProps)

  return (
    <LogContainer timelineHeight={timelineHeight}>
      <LogTimelineHeaderWrapper>
        <div className="selection-item">
          <Select
            defaultValue="15m"
            style={{
              width: 80,
            }}
            onChange={handleChangeTimeRange}
            options={timeRangeList}
          />
          <Select
            style={{
              width: 120,
            }}
            allowClear
            placeholder={t('user')}
            onChange={handleChangeUser}
            options={userList}
          />
        </div>
        <Button type={'primary'} onClick={() => loadLogInfo()}>
          <ReloadOutlined />
          {t('reload')}
        </Button>
      </LogTimelineHeaderWrapper>
      <LogTimeline timelineData={logData || []} loading={loading} />
    </LogContainer>
  )
}

export default LogInfoWrapper
